@import 'theme.scss';

.dialog-ui-modal {
  .p-dialog-header {
    padding: 12px 24px 12px 24px;
    display: flex;
    align-items: center;
    color: $primary-color;
    text-align: center;
    .p-dialog-header-icons {
      align-self: normal;
      button {
        background-color: $White;
        border: 3px solid $gray;
        border-radius: 50% !important;
        color: $gray;
        height: 27px;
        width: 27px;
      }
    }
  }
  .p-dialog-content {
    padding: 16px 24px 16px 24px;
  }
  .p-dialog-title {
    display: flex;
  }
  .p-dialog-footer {
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 12px 24px 12px 24px;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
    gap: 8px;
  }
  .custom_header_logo_wrapper {
    background-color: $primary-color;
    border-top-left-radius: 3.8px;
    border-top-right-radius: 3.8px;
    padding: 6px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .coffeeWebProdLogoForModal {
      padding: 10px;
      width: 180px;
    }

    .coffeeWebDevLogoForModal {
      width: 172px;
      margin: 6px 0;
    }
  }
  .close-button {
    margin-left: auto;
    cursor: pointer;
    position: absolute;
    right: 24px;

    .times-circle-icon {
      font-size: $xx-large;
      color: $gray;
    }
  }
}

.custom-header-styling-for-modal {
  .p-dialog-header {
    padding: 0px;
  }
}
.dialog-ui-modal {
  &.center {
    .p-dialog-title {
      justify-content: center;
    }
  }
}

@media (max-width: $device-small) {
  .dialog-ui-modal {
    height: auto !important;
    width: 94% !important;
    background: $White;

    .share-news-title {
      width: -webkit-fill-available;
    }
  }
  .close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative !important;
    top: -24px;
    right: -16px !important;
    background: $White;
    border-radius: 50%;
  }
  .times-circle-icon {
    font-size: $xxx-large !important;
    background: $White;
    position: fixed;
    border-radius: 50%;
  }
}
