@import 'theme.scss';

@mixin dropdown-styles {
  .p-dropdown {
    border-color: $primary-color;
    border-radius: 5px;
  }

  .p-inputtext:enabled:hover {
    border-color: $primary-color;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
  }
}

.p-dropdown-panel .p-dropdown-header {
  padding: 8px !important;
}

@mixin dropdown-panel-style-mixin {
  .p-dropdown-filter {
    height: 36px;
    border-radius: 3px;
  }

  .p-highlight {
    color: $primary-color;
    background-color: #f8f9fa;
  }

  .p-dropdown-items {
    margin-bottom: 0px !important;
    padding: 0px;
  }

  .p-dropdown-item {
    font-size: 14px;
    color: rgb(74, 74, 74);
    padding: 10px !important;
  }

  .p-dropdown-item:hover {
    background-color: #f3f4f6;
  }

  .p-inputtext:enabled:hover {
    border-color: $primary-color;
  }

  .p-inputtext:enabled:focus {
    border-color: $primary-color;
  }
}

.placeholder-container {
  text-align: left;

  .placeholder-text {
    position: relative;
  }
}

.placeholder-container .placeholder-text.coffee-web {
  top: 0px !important;
}

.dropdown-container {
  width: 100%;

  .p-hidden-accessible {
    display: none;
  }

  .error-message {
    font-size: 14px;
    color: $red;
  }

  @include dropdown-styles;
}

.guest-user {
  @include dropdown-styles;
}

.about-panel-style {
  top: 78px !important;
  width: 170px !important;
  display: block !important;
  z-index: 1 !important;

  @include dropdown-panel-style-mixin;
}

.global-diff-panel-style {
  position: fixed !important;
  width: 170px !important;
  display: block !important;
  z-index: 999999 !important;
  top: 146px !important;
  overflow: auto;

  .p-dropdown-items-wrapper {
    max-height: 360px !important;
    overflow-y: auto;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scroll-behavior: inherit;
  }

  @include dropdown-panel-style-mixin;
}

.auth-screen-panel-style {
  position: fixed !important;
  width: 170px !important;
  display: block !important;

  @include dropdown-panel-style-mixin;
}

.guest-user-panel-style {
  position: fixed !important;
  width: 170px !important;
  display: block !important;
  z-index: 999999 !important;
  top: 140px !important;
  overflow: auto;

  .p-dropdown-items-wrapper {
    max-height: 360px !important;
  }

  @include dropdown-panel-style-mixin;
}
.news-feed-panel-style {
  position: fixed !important;
  width: 170px !important;
  display: block !important;
  z-index: 999999 !important;
  top: 140px !important;
  overflow: auto;

  .p-dropdown-items-wrapper {
    max-height: 360px !important;
  }

  @include dropdown-panel-style-mixin;
}

.coffee-reports-panel-style {
  z-index: 13 !important;
  top: 93px !important;
  overflow: auto;
  border-radius: 2px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: $White;
  .p-highlight {
    background-color: $White !important;
  }
  .p-dropdown-items-wrapper {
    max-height: 360px !important;
  }
  .p-dropdown-item {
    background-color: $White !important;
  }
  .p-dropdown-item {
    margin: 0px 25px 0px 0px;
  }
  @include dropdown-panel-style-mixin;
}

.dropdown-panel-style {
  position: fixed !important;
  width: 170px !important;
  display: block !important;

  .p-dropdown-items-wrapper {
    max-height: 360px !important;
  }

  .p-inputtext {
    height: 37px;
    position: relative;
  }

  .p-inputtext:enabled:hover {
    border-color: $primary-color;
  }

  .p-inputtext:enabled:focus {
    border-color: $primary-color;
  }

  .p-dropdown-item {
    padding: 10px !important;
  }

  @include dropdown-panel-style-mixin;
}

.usda-coffee-report-panel-style {
  top: 95.9px !important;
  width: 179px;
}

.common-style-for-template {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  .option-country-flag {
    margin: 0;
    border-radius: 3px;
    border: 1px solid rgba(0, 17, 51, 0.15);
    width: 33px;
  }

  .selected-country-flag {
    width: 38px;
    border: 1px solid rgba(0, 17, 51, 0.15);
    border-radius: 3px;
  }

  .option-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .coffee-report-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 8px;
  }

  .panel-text {
    margin-left: 8px;
  }
}

.iSigUp-option-text {
  color: #63717f;
}

.p-dropdown {
  height: auto !important;
  padding: 7px;
  min-width: 180px;

  .p-dropdown-trigger {
    width: 1.5rem;
  }
}

.p-dropdown-label {
  padding: 0px;
  border-radius: 3px;
}

@media (max-width: 767px) {
  .dropdown-panel-style {
    top: 100px !important;
  }

  .usda-coffee-report-panel-style {
    top: 99px !important;
    width: 179px;
  }

  .global-diff-panel-style {
    top: 107px !important;
  }

  .about-panel-style {
    top: 94px !important;
  }

  .guest-user {
    .p-dropdown {
      border: 1px solid $brown-primary-color;
    }
  }

  .guest-user-panel-style {
    top: 108px !important;

    .p-dropdown-filter {
      border: 1px solid $brown-primary-color !important;
      box-shadow: none !important;
    }
  }
  .news-feed-panel-style {
    top: 108px !important;

    .p-dropdown-filter {
      border: 1px solid $primary-color !important;
      box-shadow: none !important;
    }
  }

  .option-text-width {
    max-width: 175px;
  }
}
@media (max-width: 361px) {
  .dropdown-container,
  .guest-user {
    .p-dropdown {
      min-width: 160px;
    }
  }

  .usda-coffee-report-panel-style {
    top: 105px !important;
    width: 160px;
  }
}
