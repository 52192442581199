@import 'theme.scss';
:root {
  --topProperty: 0px;
  --positionProperty: fixed;
  --displayProperty: block;
}
.workbook-component-wrapper {
  .workbook_and_forex_tab-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    user-select: none;
    margin: 1px 0px;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid rgb(206, 206, 206);
    padding: 1px;
    cursor: pointer;
    width: 100%;
    .user-guide-button {
      span {
        font-weight: $font-semibold;
      }
    }

    .workbox-and-forex-content {
      display: flex;
      align-items: center;
      width: 15%;
      margin-left: 200px;
      font-weight: $font-semibold;
      .active_tab,
      .in_active_tab {
        border: 2px solid $primary-color;
        border-radius: 5px;
        padding: 0px;
        width: 150px;
        font-size: $small;
        padding: 1px 5px;
      }
      .active_tab {
        background-color: $primary-color;
        color: $default-white;
        margin-left: 4px;
      }
      .in_active_tab {
        background-color: $default-white;
        color: $primary-color;
        margin-left: 4px;
      }
    }
    .dropdown-workbook-container {
      position: relative;

      .search_and_add {
        display: grid;
        grid-template-columns: 60px 200px 60px;
        justify-content: space-between;
        align-items: center;
        gap: 3px;
        height: 33px;

        .contract-select-drop-down {
          .p-dropdown {
            width: 100%;
            height: 33px !important;
            align-items: center;
            border: 1px solid $primary-color;
            min-width: auto;
            border-radius: 4px;
          }
          .p-dropdown:not(.p-disabled).p-focus {
            box-shadow: none;
          }
          .p-dropdown .p-dropdown-trigger {
            width: 14px;
            font-weight: $fw-bolder;
          }
          .p-dropdown-label {
            font-size: 15px;
            color: $Black;
            text-align: center;
          }
        }

        label {
          margin-top: -10px;
        }

        .add_btn {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px !important;
          &:disabled {
            opacity: 0.8;
          }
        }
        .drop_down_for_add {
          width: 100%;
          .drop_down_div {
            width: 100%;
            .drop_down {
              width: 100%;
              display: flex;
              height: 33px;
              background-color: $default-white;
              border-radius: 6px;
              .p-dropdown-label {
                font-size: $medium;
                color: $Black;
                text-align: justify;
              }

              .p-dropdown {
                width: 100%;
                align-items: center;
                border: 1px solid $primary-color;
                border-radius: 4px;
              }
              .p-dropdown:not(.p-disabled).p-focus {
                box-shadow: none;
              }
            }
          }
          .dividerDropDownDiv {
            min-width: 180px;
            border: 1px solid $primary-color;
            height: 33px;
            border-radius: 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px;
            background-color: $default-white;
          }
        }
      }
      .empty-div {
        width: 300px;
      }
    }
  }
  .workbook_forex_createmodal {
    position: relative;
    .create-account-info-card {
      position: absolute;
      top: 0px;
      width: 100%;
      margin: auto;
    }
    .workbook_forex_blur_bg {
      filter: blur(3px);
      user-select: none;
      pointer-events: none;
    }
    .worbook_table {
      width: 100%;
      border: 1px solid #dee2e6;
      overflow: hidden;
      tr {
        border-bottom: 1px solid rgb(222, 226, 230);
        border-collapse: collapse;
      }

      .workbook_body {
        .dragIcon {
          cursor: move;
        }
        #selected-workbook-row {
          background-color: rgb(238, 255, 200);
          cursor: grab;
        }
        tr {
          user-select: none;
        }
        .delete-checkbox {
          display: flex;
          vertical-align: middle;
          margin: auto;
          cursor: pointer;
        }
        .custom-lastChg,
        .custom-netPrice {
          div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 85px;
            margin: auto;
            span {
              width: 70px;
            }
            input {
              width: 65px;
              margin: auto;
            }
            input::placeholder {
              font-weight: $font-normal;
            }
          }

          .sort-alt-icon {
            width: 16px;
            height: 19px;
            color: $Black;
            cursor: pointer;
            border: 1px solid $primary-color;
            border-radius: 2px;
            font-size: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
          }
        }
        .table_head {
          background-color: $primary-color;
          color: $default-white;
        }

        .newely_added_bg_color {
          background-color: #b0ef86;
        }

        .active_focus_bg_color {
          background-color: rgb(199, 198, 198) !important;
          transition: background-color 0.2s ease;
        }

        .normal_white_bg_color {
          background-color: $default-white;
        }

        td {
          font-size: 17px;
          text-align: center;
          font-family: Arial, Helvetica, sans-serif;
          vertical-align: middle;
          text-align: center;
          padding: 0rem 0.1rem;
        }

        .table_head:hover {
          background-color: $primary-color;
        }

        tr:hover {
          background-color: rgb(224, 224, 224);
        }

        .input_field {
          height: 21px;
          width: 65px;
          font-size: 15px;
          align-items: center;
          display: block;
          margin: auto;
          padding: 0rem 0.2rem !important;
          font-weight: 400;
          line-height: 1.5;
          text-align: center;
          color: $dark_gray;
          background-color: $default-white;
          background-clip: padding-box;
          border: 1px solid $primary-color;
          border-radius: 0.25rem;
          transition:
            border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
          &:focus-visible {
            border: 1px solid $gray;
            outline: 2px solid $primary-color;
          }
        }
        .custom-input {
          font-weight: $font-semibold;
        }
        .grade {
          width: 80px;
        }

        input::placeholder {
          color: rgb(182, 180, 180) !important;
          font-size: 13px;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type='number'] {
          -moz-appearance: textfield;
        }

        .workbookDividerTd {
          background-color: $primary-color;
          height: 24px;
          font-size: $xx-small;

          .dividerInput {
            display: block;
            width: 25.9vw;
            text-align: center;
            color: $Black;
            font-weight: $font-semibold;
            border-radius: 4px;
            border: 1px solid $primary-color;
            font-size: 15px;
            text-align: center;
            left: 13.5vw;
            position: relative;
            outline: none;
            background-color: #fcfeea;
          }
        }

        .workBookIcons {
          width: 18px;
          cursor: pointer;
        }
      }
    }
  }
}
.user-guide-dialog {
  .p-dialog-header {
    padding-bottom: 0px;
  }
  .user-guide-dialog-container {
    .logo-container {
      text-align: center;
      .logo-image {
        width: 272px;
      }
    }

    .coming-soon-div {
      text-align: center;
      padding: 25px 0px 5px 0px;

      .coming-soon-text {
        background-color: #9e9e9e;
        border: 1px solid $White;
        border-radius: 4px;
        color: $White;
        display: inline-block;
        font-size: $medium;
        font-weight: $font-semibold;
        margin: auto;
        padding: 8px 15px;
        text-align: center;
        width: 210px;
      }
    }
    .description-div {
      text-align: center;
      font-size: $large;
      padding: 0px 8px;
      width: 400px;
      font-weight: $font-semibold;
      margin-top: 30px;
      .description-text {
        color: #9e9e9e;
      }
    }
  }
  .user-guide-header-content {
    display: flex;
    justify-content: flex-end;
    .pi-times-circle {
      cursor: pointer;
      color: #6c757d;
      font-size: 24px;
    }
  }

  .user-guide-dialog-footer {
    .social-buttons-container {
      .social-buttons {
        .flex {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .app-btn {
          width: 45%;
          max-width: 160px;
          color: $default-white;
          margin: 12px 10px;
          text-align: left;
          border-radius: 5px;
          text-decoration: none;
          font-family: 'Lucida Grande', sans-serif;
          font-size: 10px;
          text-transform: uppercase;
          background-color: $Black !important;
          border: 1px solid lightgrey;

          p {
            margin: 0.5rem !important;
            color: $default-white;

            .big-txt {
              font-size: 15px;
              text-transform: capitalize;
            }
          }

          .social-images {
            width: 20%;
            text-align: center;
            font-size: 28px;
            margin-right: 7px;
            color: $default-white;
          }
        }
      }
    }
  }
}

@media (min-width: 767.98px) and (max-width: 1360px) {
  .workbook-component-wrapper {
    .workbook_and_forex_tab-container {
      .workbox-and-forex-content {
        margin: 0px auto;
        width: 18%;
      }
      .user-availability-guide-button {
        width: 0px;
      }
      .dropdown-workbook-container {
        .empty-div {
          width: 0px;
        }
      }
    }
    .workbook_forex_createmodal {
      .worbook_table .workbook_body {
        .input_field {
          font-size: 11px;
          width: 40px !important;
          &::placeholder {
            font-size: 12px;
          }
        }
        .custom-netPrice,
        .custom-lastChg {
          div {
            width: 50px !important;
            span {
              width: 45px !important;
            }
          }
        }
        .fx_currency_title {
          width: 70px !important;
        }
        .fx_currency .fxcurrency_select_dropdown {
          width: 60px !important;
          font-size: 8px;
        }
        .workbookDividerTd {
          .dividerInput {
            width: 27vw;
            left: 12.9vw;
          }
        }
      }
      .workbook_and_forex_tab-container {
        .active_tab,
        .in_active_tab {
          font-size: $x-small;
        }
        .dropdown-workbook-container {
          .search_and_add {
            margin: 0px 0px;
            grid-template-columns: 60px 1fr 60px;

            .drop_down_for_add {
              .drop_down_div {
                .drop_down {
                  .p-dropdown-label {
                    font-size: $medium;
                    color: $Black;
                  }
                }
              }
            }
          }

          .empty-div {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .workbook-component-wrapper {
    .workbook_forex_createmodal {
      .worbook_table {
        display: none;
      }
      .workbook_and_forex_tab-container {
        display: none;
      }
      .create-account-info-card {
        display: none;
      }
    }
  }
}
.coffeequotes-workbook-dropdowns {
  top: var(--topProperty) !important;
  position: var(--positionProperty) !important;
  display: var(--displayProperty) !important;
  transform-origin: center top !important;
  max-width: 200px;
  .p-dropdown-items {
    margin-bottom: 0px;
    padding: 6px 0px;
    .p-dropdown-item {
      padding: 0.35rem 1rem !important;
      font-size: 15px;
      &.p-highlight {
        background-color: rgb(228, 228, 228);
        color: #4b5563;
      }
    }
  }

  .p-inputtext {
    padding: 0.35rem 0.75rem;
    &:hover {
      border-color: $primary-color;
    }
  }
}
