@import 'theme.scss';

.table-heading {
  display: grid;
  grid-template-columns: 3fr 7fr;
  background-color: $primary-color;
  padding: 4px;
  border-radius: 4px;
  text-transform: uppercase;
  .market-type-dropdown {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .market-selector {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      gap: 4px;
      color: $White;
      font-weight: $font-bold;

      .arrow-toggle {
        display: flex;
        flex-direction: column;

        .arrow-up {
          font-size: $x-small;
          font-weight: $font-bold;
        }

        .arrow-down {
          font-size: $x-small;
          font-weight: $font-bold;
        }
      }
    }
  }

  .report-name {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: $font-bold;
  }
}
