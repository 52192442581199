@import 'theme.scss';

.dialog-component {
  min-width: 400px;
  max-width: 400px;
  min-height: 18%;
  background-color: $default-white;
  word-wrap: break-word;

  .p-dialog-header {
    border-bottom: 1px solid $light-grey-border;
    padding: 8px 10px 8px 24px;

    .p-dialog-header-icon:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: $focus-shadow-color;
      border-radius: 50% !important;
    }

    .p-dialog-header-icon {
      width: 2rem;
      height: 2rem;
      color: #6c757d;
      border: none;
      background: transparent;
      transition:
        background-color 0.2s,
        color 0.2s,
        box-shadow 0.2s;
    }

    .dialog-component-error {
      color: $danger-red-color;
    }
  }

  .p-dialog-content {
    padding: 1rem 1rem 1.5rem 1.5rem;
  }

  .dialog-component-message-confirmation {
    display: flex;
    align-items: center;
    gap: 10px;

    .pi-exclamation-triangle {
      font-size: $xxx-large;
      color: $danger-red-color;
    }
  }

  .p-dialog-footer {
    padding: 0 1rem 1rem 1.5rem;

    .dialog-component-footer {
      .neutral-button {
        color: $primary-color;
        border: 1px solid $primary-color;
        border-color: $primary-color;
        background-color: $default-white;
        min-width: 98px;
        padding: $button-label-padding;
      }

      .neutral-button:focus {
        box-shadow:
          0 0 0 2px $default-white,
          0 0 0 4px $primary-color,
          0 1px 2px 0 $grey-font-color-on-hover;
      }

      .neutral-button:hover {
        color: $on-primary-hover;
        border-color: $on-primary-hover;
        background-color: $default-white;
      }

      .retry-button {
        color: $default-white;
        border: 1px solid $primary-color;
        background-color: $primary-color;
        border-color: $primary-color;
        min-width: 98px;
        padding: $button-label-padding;
      }

      .retry-button:focus {
        box-shadow:
          0 0 0 2px $default-white,
          0 0 0 4px $primary-color,
          0 1px 2px 0 $grey-font-color-on-hover;
      }

      .retry-button:hover {
        color: $default-white;
        border: 1px solid $on-primary-hover;
        background-color: $on-primary-hover;
        border-color: $on-primary-hover;
      }
    }

    .dialog-component-footer button {
      margin: 0 0.5rem 0 0;
      line-height: normal;
      transition:
        background-color 0.2s,
        color 0.2s,
        border-color 0.2s,
        box-shadow 0.2s;
      border-radius: 6px;
    }

    .dialog-component-footer button:last-child {
      margin: 0;
    }
  }

  .custom-message-1 {
    text-align: center;
    font-size: $x-large;
    font-weight: $font-extrabold;
    line-height: normal;
  }

  .custom-message-2 {
    text-align: center;
    margin-top: 10px;
    color: black;
    font-size: $medium;
    line-height: normal;
  }
}

@media (max-width: $device-small) {
  .dialog-component {
    min-width: 92%;
    max-width: 92%;
  }
}
