@import 'theme.scss';

.sign-up-tab-component {
  padding: 10px 20px;
}
.sign-up-form-container {
  min-height: 350px;
  display: grid;
  grid-template-rows: 1fr auto;
  .sign-up-and-terms-button-wrapper {
    margin: auto;
  }
  .sign-up-terms-and-condition {
    font-size: 13px;
    margin: 12px 0px;
    color: #495057eb;
    text-align: center;
  }
  .sign-up-button {
    text-align: center;
    margin: 12px 0px;
    border-radius: 5px;
  }
  .redirect-to-login-container {
    background-color: rgba(211, 211, 211, 0.5803921569);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 15px;
    margin: 20px 0px;
    color: #494c50;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      margin-left: 10px;
      color: $White;
      background-color: $primary-color;
      border: 1px solid $primary-color;
      padding: 0.2rem 0.4rem;
    }
    .button-wrapper .hard-action.disable-hover:enabled:active,
    .button-wrapper .hard-action.disable-hover:not(button):not(a):not(.p-disabled):active {
      box-shadow:
        0 0 0 2px $button-shadow-primary-color,
        0 0 0 4px $button-shadow-primary-color,
        0 1px 2px 0 $grey-font-color-on-hover;
    }
  }
}

.sign-up-modal-footer-content {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 12px;
}
.country-picker-dropdown {
  margin-bottom: 16px;
}
.country-dropdown-error {
  font-size: $small;
  color: $red;
}
