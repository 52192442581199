@import 'theme.scss';

.country-wise-exports-container {
  .header-section {
    padding-bottom: 4px;
    position: sticky;
    top: 0px;
    z-index: 14;
    background-color: $White;

    .export-header {
      display: grid;
      grid-template-columns: 1fr 3fr 1fr;
      background-color: $primary-color;
      padding: 1px 4px;
      border-radius: 4px;
      text-transform: uppercase;

      .dropdown-container {
        padding: 4px 0px;
        width: 100%;
        .p-dropdown {
          width: 50%;
          border-color: #dee2e6;
          border-radius: 2px;
          height: 32px !important;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;
          border-radius: 4px;
        }
        .p-dropdown-label {
          font-size: 15px;
          font-weight: $font-semibold;
          color: $Black;
        }
      }

      .report-title {
        font-weight: $font-bold;
        color: $White;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
      }
      .swap-icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        gap: 4px;
        color: $White;
        font-weight: $font-bold;
        .swap-icon {
          display: flex;
          flex-direction: column;
          .upper-arrow {
            font-size: $x-small;
            font-weight: $font-bold;
          }

          .down-arrow {
            font-size: $x-small;
            font-weight: $font-bold;
          }
        }
      }
    }
  }

  .horizontal-scroll-container {
    width: 100%;
    max-height: calc(100vh - 245px);
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-color: #a8a8a8 #f1f1f1;

    .table-wrapper {
      min-width: 100%;
      box-sizing: border-box;

      table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;
        margin-bottom: 20px;

        thead {
          position: sticky;
          top: 0;
          z-index: 1;
          width: 100%;
          .year-row {
            position: sticky;
            top: 0px;
            td {
              background-color: $White;
              padding: 0;
              padding-bottom: 7px;
              height: 32px;
              padding-top: 2px;

              div {
                background-color: $primary-color;
                color: $White;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 3px;
                text-transform: uppercase;
                outline: 1px solid $primary-color;
                font-weight: $fw-bolder;
              }
            }
          }

          tr {
            th {
              outline: 1px solid $primary-color;
              z-index: 1;
              padding: 2px 3px;
              background-color: $table-header-background;
              position: relative;
              text-transform: uppercase;
              font-size: $large;
            }
          }
          .table-row-empty {
            height: 7px;
          }
        }

        tbody {
          border-collapse: collapse;

          .tr-color {
            background-color: $table-header-background;
            outline: 1px solid $primary-color;
            text-transform: uppercase;

            &:hover {
              background: $table-header-background;
            }
          }

          tr {
            &:hover {
              background-color: rgba(0, 0, 0, 0.075);
            }

            td {
              border: 1px solid #dee2e6;
              font-weight: $fw-bolder;
              padding: 3px 0px;
              font-size: $large;
            }
          }

          .positive {
            color: $green;
          }

          .negative {
            color: $red;
          }

          .normal {
            color: $Black;
          }

          .popup-wrapper {
            display: grid;
            grid-template-columns: auto 10px;
            text-decoration: underline;
            cursor: pointer;
            position: relative;

            &:hover {
              color: $blue;
            }

            .icon-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              .popup-icon {
                height: 16px;
                width: 16px;
                opacity: 0.5;
                position: absolute;
                right: 26px;
              }
            }
          }
        }
        .no-data-row {
          height: 8px;
        }
      }
    }
  }

  .p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
    background-color: transparent;
    color: $red;
  }
}

.comparison-table {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  width: 100%;
  .content-title {
    color: $Black;
    display: flex;
    justify-content: center;
    h5 {
      font-weight: $fw-bolder;
    }
  }

  .table-container {
    width: 100%;
    text-align: center;
    display: grid;
    grid-template-columns: 2fr 9fr;
    z-index: 1;
    gap: 4px;

    .left-table-content {
      width: 100%;
      overflow-x: hidden;
    }
    .right-table-content {
      width: 100%;
      overflow-y: hidden;
      scrollbar-color: #a8a8a8 #f1f1f1;
    }
  }

  table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    thead {
      tr {
        th {
          background-color: $table-header-background;
          padding: 2px;
          color: $Black;
          min-width: 200px;
          border: 1px solid $primary-color;
          text-transform: uppercase;
          &.india-country {
            min-width: 100px;
          }
        }
      }
    }
    tbody {
      background-color: $White;
      border-collapse: collapse;

      tr {
        &:hover {
          background-color: rgba(0, 0, 0, 0.075);
        }
        td {
          border: 1px solid #add8e6;
          font-weight: $fw-bolder;
          color: $Black;
        }
      }
      .difference-row,
      .percentage-row {
        background-color: $table-header-background;

        &:hover {
          background: $table-header-background;
        }
      }
      .empty-table-row {
        height: 5px;
      }

      .table-border {
        border: 1px solid $primary-color;
      }

      .highlight {
        background-color: rgba(0, 0, 0, 0.075);
      }

      .positive {
        color: $green;
      }

      .negative {
        color: $red;
      }

      .neutral {
        color: $Black;
      }
    }
  }
}
