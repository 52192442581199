@import 'theme.scss';
.caroselMedia_component {
  .p-galleria-item {
    aspect-ratio: 4.5/3;
    img,
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .p-galleria-item-nav {
    background-color: rgba(255, 255, 255, 0.284);
    height: 45px;
    width: 45px;
    margin: -20px 10px 0px 10px;
    opacity: 1 !important;
    border-radius: 50% !important;
    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
  ul {
    margin-bottom: 0px;
    padding: 10px;
    display: flex;
    align-items: center;
    text-align: center;
  }
  li {
    display: flex;
    align-items: center;
    text-align: center;
  }
  .p-galleria-indicator {
    button {
      background-color: rgba(0, 0, 0, 0.2588235294);
      border-radius: 50% !important;
      border: 1px solid $default-white;
      width: 10px;
      height: 10px;
    }
  }
  .p-highlight {
    button {
      background-color: $default-white;
      border-radius: 50% !important;
    }
  }
}
