@import 'theme.scss';
@import url('https://fonts.googleapis.com/css2?family=Hubballi&family=Noto+Sans+Kannada:wght@100..900&display=swap');

.news-read-more-screen-main-div {
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  margin-top: 90px;
  z-index: 2;
  padding: 2px 10px;
  user-select: none;
  position: fixed;
  top: 0px;
  width: 100vw;
  background-color: $default-white;

  .news-read-more {
    .main-card {
      display: grid;
      grid-template-columns: 2fr 7fr;
      column-gap: 10px;
      justify-content: space-around;
      margin: auto;
      span,
      a {
        word-wrap: break-word !important;
      }
      .childMainDiv {
        padding: 10px;
      }

      .image-div {
        display: flex;
        flex-direction: column;
        gap: 4px;

        ::-webkit-scrollbar {
          width: 0px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #acabab;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #888;
        }

        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        .sticky-buttons {
          background-color: $default-white;
          display: flex;
          gap: 8px;
          padding: 4px 0;
          position: -webkit-sticky;
          position: sticky;
          top: 90px;
          z-index: 2;
          width: 100%;

          .back-button {
            width: 50%;
            .soft-action {
              display: grid;
              grid-template-columns: auto auto;
              justify-content: flex-start;
              gap: 7px;
              .go-back-arrow-icon {
                background-color: $primary-color;
                color: $default-white;
                border-radius: 5px;
                padding: 4px;
              }
            }
          }

          .share-news-button {
            width: 50%;
            .hard-action {
              display: grid;
              grid-template-columns: auto auto;
              justify-content: flex-start;
              gap: 7px;
            }
            .share-icon {
              font-size: $x-large;
            }
          }
          .share-text {
            color: $default-white;
          }
        }

        .assets {
          width: 100%;
          border-radius: 10px;
          margin: 5px 0px;
          cursor: pointer;
        }
      }

      .img-container {
        height: calc(100vh - 152px);
        overflow: scroll;
        padding: 10px;
        border-radius: 4px;
        box-shadow: 0px 0px 5px gray;
      }

      .content-div::-webkit-scrollbar {
        width: 0px !important;
      }
      .content-div {
        height: 100vh;
        overflow: scroll;
        padding: 1px 5px;

        .sticky-buttons {
          position: -webkit-sticky;
          position: sticky;
          background-color: $default-white;
          padding: 4px 0px;
          gap: 3px;
          display: none;
          z-index: 2;
          width: 100%;

          .back-button {
            width: 50%;
            .soft-action {
              display: grid;
              grid-template-columns: auto auto;
              justify-content: flex-start;
              gap: 7px;
              .go-back-arrow-icon {
                background-color: $primary-color;
                color: $default-white;
                border-radius: 5px;
                padding: 4px;
              }
            }
          }
        }
        .sticky-container-div {
          position: sticky;
          top: 0;
          z-index: 1;
        }
        .sticky-div {
          position: sticky;
          width: 100%;
          top: 0px;
          padding: 50px;
          font-size: 20px;
          z-index: 2;
          padding: 3px 10px;
          border-radius: 10px;
          box-shadow: 0px 0px 5px gray;
          background-color: $default-white;
          gap: 3px;
          display: flex;
          flex-direction: column;
          .sub-sticky-div {
            display: grid;
            grid-template-columns: auto 90px !important;
            justify-content: space-between;
            font-size: 14px;
            padding: 0px 3px;
            margin-top: 3px;
          }
          .sticky-icons-container {
            display: flex;
            align-items: center;
            font-size: 14px;
          }
          .calendar-clock-icons {
            color: $primary-color;
            width: 20px;
            margin-top: -3px;
            margin-right: 3px;
          }
          .sticky-box-bottom-part {
            position: absolute;
            bottom: 40px;
            right: 20px;
          }
          .account-icon-container {
            display: flex;
            white-space: nowrap;
            overflow: hidden;
          }
          .account-icon {
            color: $primary-color;
            width: 20px;
            margin-top: -4px;
            margin-right: 3px;
          }
          .user-account-icon {
            font-size: $small;
            border-radius: 50%;
            margin-right: 5px;
            padding: 4px;
            color: $primary-color;
            width: 18px;
            height: 18px;
          }
          .author-text {
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: -3px;
          }
          .right-part-icon-container {
            display: flex;
            justify-content: space-between;
          }
          .view-icon-container {
            display: flex;
            align-items: center;
            .view-icon {
              margin-right: 8px;
              color: $primary-color;
              font-size: $large;
            }
          }
          .heart-icon {
            color: $red;
            gap: 5px;
            display: flex;
            cursor: pointer;
          }
        }

        .sub-sticky-div {
          display: grid;
          grid-template-columns: auto 90px !important;
          justify-content: space-between;
          font-size: 16px;
          padding: 0px 3px;
          margin-top: 3px;
        }
        .news-heading-subject {
          background-size: cover;
          width: 100%;
          height: auto;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          text-align: center;
          font-size: 25px;
          color: $primary-color;
          font-weight: 600;
        }

        .banner-image {
          font-family: 'Roboto', Arial, sans-serif;
        }
        .kannada-text {
          font-family: 'Noto Sans Kannada', sans-serif;
          font-size: 32px;
        }

        .carousel-for-news {
          aspect-ratio: 3 / 2;
          position: relative;
          z-index: 1;
          padding: 10px 0px;
          display: none;

          span {
            width: 10px !important;
            height: 10px !important;
          }

          .download-doc-link {
            background-color: $primary-color;
            color: $default-white;
            padding: 4px 10px;
            border-radius: 4px;
          }
        }
      }
    }
    .view-document-and-file-container {
      display: flex;
      justify-content: flex-end !important;
      margin-top: 10px;
    }
  }
  .news-html {
    margin-top: 10px;
    margin-bottom: 50px;
    .img {
      width: 100%;
    }
  }

  .news-html-full-stop {
    color: $default-white;
    height: 75px;
  }
}

.modal-read-more-download-pdf {
  width: 100%;
  height: 100%;
  .modal-body-style {
    width: 100%;
    height: 100%;
    iframe {
      width: 100%;
      height: 73vh;
      border: none;
    }
  }
}

.heart-logo-blob {
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2.5s infinite;
}

.zoomAnimation {
  border-radius: 50%;
  margin: 10px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.83);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.83);
  }
}

@media (max-width: 767.98px) {
  .news-read-more-screen-main-div {
    grid-gap: 10px;
    display: grid;
    gap: 10px;
    grid-template-columns: 7fr;
    height: 100%;
    justify-content: space-around;
    margin: auto;
    z-index: 201;
    margin-top: 50px;

    .news-html {
      margin-bottom: 90px;
    }

    .news-read-more {
      .main-card {
        margin: auto;
        justify-content: space-around;
        display: grid;
        grid-template-columns: 7fr;
        height: 100%;
        gap: 10px;

        .image-div {
          display: none;

          .sticky-div {
            top: 54px !important;
          }
        }

        .content-div::-webkit-scrollbar {
          width: 0px;
        }
        .content-div {
          height: 100vh;
          display: flex;
          flex-direction: column;
          padding-left: 5px;
          padding-right: 5px;
          overflow-y: auto;
          img {
            width: 100%;
          }

          .scroll-back-button.slide-down {
            animation: slide-down-animation-back 0.2s ease-out;
            animation-fill-mode: forwards;
          }
          @keyframes slide-down-animation-back {
            0% {
              transform: translateY(0);
            }
            100% {
              transform: translateY(100%);
            }
          }
          .scroll-back-button.down {
            animation: down-animation-back 0.2s ease-out;
            animation-fill-mode: forwards;
          }
          @keyframes down-animation-back {
            0% {
              transform: translateY(100%);
            }
            100% {
              transform: translateY(0);
            }
          }

          .sticky-buttons {
            background-color: $default-white;
            position: fixed;
            left: 0;
            bottom: 0;
            display: flex;
            gap: 10px;
            padding: 10px;
            width: 100%;
            z-index: 205 !important;

            .back-button {
              width: 50%;
              .soft-action {
                display: grid;
                grid-template-columns: auto auto;
                justify-content: flex-start;
                gap: 7px;
                .go-back-arrow-icon {
                  background-color: $primary-color;
                  color: $default-white;
                  border-radius: 5px;
                  padding: 4px;
                }
              }
            }
            .share-news-button {
              width: 50%;
              .hard-action {
                display: grid;
                grid-template-columns: auto auto;
                justify-content: flex-start;
                gap: 7px;
              }
              .share-icon {
                font-size: $large;
              }
            }
            .share-text {
              padding-left: 11px;
              font-weight: bold;
            }
          }

          .sticky-container-div {
            margin-top: 10px;
            position: sticky;
            width: 100% !important;
            padding: 3px 4px;
            top: -2px;
            font-size: 20px;
            z-index: 2;
            background-color: $default-white;
            gap: 3px;
            display: flex;
            flex-direction: column;
            padding-bottom: 5px;
          }

          .sticky-div {
            margin: auto;
            margin-top: 60px;
            left: 0px;
            right: 0px;
            position: fixed;
            width: 95% !important;
            border-radius: 4px;
            padding: 3px 4px;
          }

          .news-heading-subject {
            font-size: 21px;
            text-align: start;
          }
          .kannada-text {
            font-size: 23px;
          }
          .carousel-for-news {
            display: block;
            margin-top: 154px;
          }
        }
      }
    }
  }

  .modal-share-news-read-more {
    margin-top: 110px;
  }
  .news-read-more-screen-main-div .news-read-more .main-card .content-div .carousel-for-news .carousel-container {
    width: 100%;
    height: 100%;
    padding: 6px 0px 0px 0px;
  }
}
