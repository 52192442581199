@import 'theme.scss';

.create-account-info-modal {
  padding: 15px;
  margin: auto;
  display: flex;
  justify-content: center;
  user-select: none;
  align-items: center;
  z-index: 1100;
  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 432px;
    background-color: $White;
    padding: 15px 0 25px 0;
    border-radius: 8px;
    border: 1px solid $primary-color;
    align-items: center;
    color: $primary-color;
    height: min-content;
    box-shadow: 0px 0px 2px $primary-color;
    min-height: 400px;
    height: 400px;
    &.premium-button {
      color: $platinum-default-color;
    }
    .coffeeweb-logo {
      margin-top: 6px;
      width: 68%;
    }
    .title {
      font-size: 37px;
      padding: 0px 8px;
      width: 400px;
      text-align: center;
    }
    .info {
      text-align: center;
      font-size: 18px;
      padding: 0px 8px;
      width: 400px;
      font-weight: 600;
    }
    .auth-screen-button {
      border: 1px solid $White;
      font-size: 16px;
      padding: 8px 15px;
      border-radius: 4px;
      color: $White;
      cursor: pointer;
      margin: auto;
      text-align: center;
      width: 210px;
      display: inline-block;
      font-weight: 600;
      &.login-button {
        background: linear-gradient(-45deg, #e73c7e, #23a6d5);
      }
      &.buy-subscription-button {
        background: $platinum-default-color;
      }
      &.premium-button {
        background: $platinum-default-color;
        cursor: default;
      }
    }
  }
}
@media (max-width: 767px) {
  .create-account-info-modal {
    .content-wrapper {
      gap: 22px;
      .info {
        width: auto;
      }
      .title {
        width: auto;
      }
      .create-account-appLinks-wrapper {
        width: 95%;
      }
    }
  }
}
