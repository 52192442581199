@import 'theme.scss';

.coffee_quotes_screen {
  background-color: $default-white;
  width: 100%;
  z-index: 1 !important;
  top: 0px;

  .coffee_quotes_screen_header {
    display: flex;
    padding: 4px 10px 3px 10px;
    width: 100%;
    background-color: $default-white;
    z-index: 2;
    top: 90px;
    position: sticky;

    .time_main_div_for_large_screen {
      width: 32%;
      display: flex;
      height: 34px;
      gap: 3px;
      align-items: center;
      font-weight: $font-semibold;

      .time_sub_div {
        background-color: $default-white;
        border-radius: 4px;
        display: flex;
        padding: 4px 7px;
        align-items: center;
        border: 1px solid $primary-color;
        cursor: pointer;
        width: max-content;
      }

      .time {
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .time_main_div_for_mobile_screen {
      display: none;
      justify-content: space-between;
      height: 33px;
      align-items: center;
      gap: 6px;

      .time_sub_div {
        width: 50%;
      }

      .time_colon {
        margin: 0px 2px;
      }
    }

    .for_trending_news {
      height: auto;
      width: 47% !important;
      margin: 0px;
      display: grid;
      width: fit-content;
      grid-template-columns: auto 1fr;
      overflow: hidden;

      .labaling {
        align-items: center;
        color: #515151;
        display: flex;
        font-weight: $font-semibold;
        justify-content: center;
        margin: 1.5px 5px;
        padding: 0 10px;
        width: 75px;
      }

      .marquee_main_div {
        padding-left: 100px;

        .marquee_tag {
          font-size: 16px;
          display: -webkit-inline-box;
          display: -moz-inline-box;
          display: -webkit-inline-flex;
          display: -ms-inline-flexbox;
          display: inline-box;
          display: inline-flex;
          -webkit-box-align: center;
          -moz-box-align: center;
          -o-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
          -webkit-box-pack: center;
          -moz-box-pack: center;
          -o-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-transition: all 0.2s ease;
          -moz-transition: all 0.2s ease;
          -o-transition: all 0.2s ease;
          -ms-transition: all 0.2s ease;
          transition: all 0.2s ease;
          cursor: pointer;
          white-space: nowrap;
          padding: 0px 14px 0px 3px;
          height: 30px;
          display: flex;
          vertical-align: middle;
          align-items: center;
          margin: 0px 60px;
          border-radius: 30px;
          color: black;
          background-color: $default-white;
          border-radius: 20px;

          .bullet_dot {
            font-weight: $font-medium;
            background-color: $primary-color;
            border-radius: 50%;
            padding: 3px 3px;
            height: 8px;
            width: 8px;
            margin-right: 6px;
          }
        }
        .child,
        .rfm-child {
          display: flex;
        }
      }
      .marquee_news {
        display: flex;
        align-items: center;
      }
    }
  }

  .coffee_quotes_screen_body {
    position: relative;
    width: 100%;
    padding: 0px 10px 50px 10px;

    .robusta_and_arabica_table {
      tr {
        border-bottom: 1px solid rgb(222, 226, 230);
        border-collapse: collapse;
      }
      #coffeequotes_accordian_component {
        &:hover {
          background-color: rgb(224, 224, 224);
        }
      }
      table-layout: auto;
      width: 100%;
      border-collapse: collapse;
      border: 1px solid #dee2e6;
      margin-bottom: 0px;

      .table_head {
        background-color: $primary-color;
        color: $default-white;
      }
      .country-flag-icon {
        height: 18px;
        border: 1px solid rgba(0, 17, 51, 0.15);
        border-radius: 2px;
        margin-right: 5px;
        margin-top: -1px;
      }

      .contract_name {
        font-size: 17px;
        margin-top: 2px;
        font-weight: $font-semibold;
        text-align: start !important;
        padding-left: 5px !important;
        width: 13.5vw;
        span {
          font-weight: $font-normal;
        }
      }
      .chng-cell {
        width: 5vw;
      }

      td {
        font-size: 17px;
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
        vertical-align: middle;
        text-align: center;
        padding: 0rem 0.1rem;

        div,
        span {
          font-family: Arial, Helvetica, sans-serif;
        }
      }
      .popup-icon {
        width: 15px;
        height: 15px;
        opacity: 0.5;
        margin-right: 3px;
      }
    }
    .coffeequotes-table-divider {
      height: 3px;
      width: 100%;
      background-color: $primary-color;
    }

    .creat_account_guest_info_modal {
      margin-top: 20px;
    }

    .span_net_price {
      width: 80px;
    }
    .fx_currency_title {
      width: 110px;
    }
    .contract_names {
      font-weight: $font-semibold;
      text-align: start !important;
      padding-left: 10px !important;
    }

    .highlight_color {
      background-color: #ccc;
    }

    .fx_currency {
      display: flex;
      justify-content: center;

      .fxcurrency_select_dropdown {
        font-size: 13px;
        width: 80px;
        text-align: center;
        outline: none;
      }
      .fxcurrency_select_dropdown:focus {
        border: 1px solid $primary-color !important;
      }
    }
  }
}

.coffee_quotes_comming_soon_modal {
  position: absolute;
  width: 100vw;
  top: 0px;
  z-index: 3;
  display: flex;
  justify-content: center;
  padding-top: 120px;
  .comming_soon_card {
    padding: 100px;
    width: 450px;
    margin: auto;
    background-color: white;
    height: 300px;
    border-radius: 10px;
    font-size: 30px;
    color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-shadow: 0px 0px 4px gray;
  }
}

.coffee-quotes-custom-tooltip.p-tooltip {
  position: fixed;
  font-size: $x-small;
}

@media (min-width: 767.98px) and (max-width: 1360px) {
  .coffee_quotes_screen {
    .coffee_quotes_screen_header {
      .for_trending_news {
        width: 50% !important;
        margin: 0px 5px;
      }
    }
    .workbook-component-wrapper {
      .workbook_and_forex_tab-container {
        .workbox-and-forex-content {
          margin: 0px;
        }
      }
    }
    .coffee_quotes_screen_body {
      td {
        font-size: 11px !important;
      }

      .robusta_and_arabica_table .country-flag-icon {
        height: 16px;
        margin-top: 0;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .coffee_quotes_screen {
    .coffee_quotes_screen_header {
      display: flex;
      flex-direction: column;
      padding: 6px 5px 5px 5px;
      gap: 5px;
      background-color: #e9ecef;
      top: 45px;

      .time_main_div_for_large_screen {
        width: 100%;
        display: none !important;
      }

      .time_main_div_for_mobile_screen {
        display: flex !important;
        width: 100%;

        .time_sub_div {
          background-color: $default-white;
          border-radius: 4px;
          display: flex;
          padding: 4px 5px;
          border: 1px solid $primary-color;
          justify-content: center;
          height: 34px;
          font-size: $medium;
        }
      }

      .for_trending_news {
        width: 100% !important;
        height: 34px !important;
        margin: 1px 0px;
        background-color: $default-white;
        border-radius: 4px;
        border: 1px solid $primary-color;

        .labaling {
          margin: 0px 5px 0px 0px !important;
        }
        .marquee_main_div {
          padding-left: 200px;
        }
      }
    }

    .coffee_quotes_screen_body {
      padding: 3px 0px;
      .coffeequotes-table-divider {
        width: 97%;
        margin: auto;
      }

      .robusta_and_arabica_table {
        display: none;
      }
    }
  }
  .coffee_quotes_comming_soon_modal {
    padding: 100px 10px 10px 10px;
    .comming_soon_card {
      width: 100%;
    }
  }
}

.css-igs3ac {
  border: 1px solid $primary-color !important;
}

@media (max-width: 767.98px) {
  .social-media {
    display: none;
  }
}
