@import 'theme.scss';

.global-differential-modal {
  height: inherit;
  .modal-body-style {
    display: grid;
    grid-template-columns: auto 1fr auto;
    .left-arrow,
    .right-arrow {
      cursor: pointer;
      top: 50%;

      .angle-double-icon {
        color: $primary-color;
        font-size: $xx-large;
        rotate: 180deg;
      }
    }
    .slide-wrapper {
      min-width: 100%;
      max-width: 600px;
      margin: auto;
      margin-top: 0px;
      .slide-title {
        font-size: $x-large;
        text-align: center;
        font-weight: $font-semibold;
        color: $primary-color;
      }
      .slide-content {
        .history-data-slide {
          width: 100%;
          overflow-x: auto;
          margin: auto;
          .history-table-table-wparrer {
            max-height: 74vh;
            overflow-x: auto;
            border-bottom: 1px solid #dee2e6;
          }
          table {
            border-collapse: collapse;
            text-align: center;
            width: 100%;
            border-bottom: 1px solid #dee2e6;
            font-weight: $font-semibold;
            .history-table-thead {
              position: sticky;
              top: -1px;
            }
            thead {
              background-color: $primary-color;
              color: $default-white;
              position: relative;
              border: 1px solid $White;
              tr {
                th {
                  background-color: $primary-color;
                  padding: 4px;
                  position: relative;
                  outline: 1px solid $White;
                  .country-flag-with-name {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    justify-content: center;
                    img {
                      border: 1px solid #00113326;
                      border-radius: 3px;
                      height: 24px;
                      object-fit: cover;
                      width: 35px;
                    }
                  }
                }
              }
              .empty_div {
                position: -webkit-sticky;
                background-color: $White;
                height: 100%;
                width: 100%;
                top: 0px;
                z-index: -1;
              }
            }
            tbody {
              tr {
                td {
                  border: 1px solid #dee2e6 !important;
                }
                &:hover {
                  background-color: rgba(0, 0, 0, 0.075);
                }
              }
              tr:nth-child(1) {
                background-color: rgb(233, 241, 249);
              }
              .remark-cell {
                max-width: 300px;
              }
              .currency-and-value {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 1px;
                .symbol {
                  text-align: end;
                  margin-right: 2px;
                  &.cent-symbol {
                    margin-top: -1px;
                  }
                }
                .value {
                  text-align: start;
                }
              }
            }
          }
          .mobile-view {
            .popup-header {
              font-weight: $font-semibold;
              margin: 0px 4px;
              img {
                width: 30px;
                border-radius: 2px;
                border: 1px solid rgba(0, 17, 51, 0.15);
              }
            }
            .history-data-cards {
              .each-card {
                border: 1px solid lightgray;
                border-radius: 4px;
                margin: 10px 4px;
                padding: 5px 10px;
                font-size: $small;
                display: flex;
                flex-direction: column;
                gap: 3px;
                .each-row {
                  display: flex;
                  align-items: flex-start;
                  span {
                    color: $gray;
                    margin-right: 3px;
                  }
                  .differentil-between {
                    display: flex;
                    gap: 3px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .global-differential-modal {
    max-width: 95vw;
    overflow: visible !important;

    .global-differential-body {
      grid-template-columns: 0px 1fr 0px;
      .left-arrow {
        width: auto;
      }
      .left-arrow {
        width: auto;
        margin-left: -23px;
      }
      .right-arrow {
        width: auto;
        margin-left: 10px;
      }
      .slide-wrapper {
        .slide-content {
          .history-data-slide {
            width: 100%;
            height: 75vh;
            overflow-x: auto;
            margin: auto;
            margin-top: 7px;
            table {
              tr {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
    .currency-and-value {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1px;
      .symbol {
        text-align: end;
        margin-right: 2px;
        &.cent-symbol {
          margin-top: -1px;
        }
      }
      .value {
        text-align: start;
      }
    }

    .modal-dialog {
      margin: auto;
      width: 90vw;
    }
  }
}
