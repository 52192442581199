@import 'theme.scss';

.forex-component-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 2px solid $primary-color;
  border-top: 0;
  filter: blur(3px);
  .currency-contaner {
    border-right: 1px solid $primary-color;
    table {
      width: 100%;
      tr {
        border: 1px solid $gray;
        td {
          padding: 3px;
        }
        .country-type {
          display: flex;
          gap: 10px;
          align-items: center;
          margin-left: 5px;
          img {
            border-radius: 3px;
            border: 1px solid rgba(0, 17, 51, 0.15);
            width: 34px;
          }
        }
      }
    }
  }
  .graph-contaner {
    border-left: 1px solid $primary-color;
    canvas {
      height: 380px !important;
      width: 95% !important;
      margin: auto;
    }
  }
}

.create-account-info-modal-forex {
  width: 100%;
  position: absolute;
  top: 0;
}
