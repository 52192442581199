@import 'theme.scss';

.updated-time-pick {
  display: contents;
  width: fit-content;

  .updated-time-pick-div {
    display: flex;
    gap: 4px;
    padding-left: 3px;

    .updated-time-pick-arrows {
      display: flex;
      flex-direction: column;
      margin-left: 5px;
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
}
