@import 'theme.scss';

:root {
  --loginThrougnOtpTab: 100%;
}

.login-screen {
  background-position: 20% 10%;
  object-fit: cover;
  min-height: calc(100vh - 81px);
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  z-index: -1;

  .tab-view {
    overflow: hidden;
    border-radius: 4px;
    .p-tabview-nav {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 4px;
      margin-bottom: 0px;
      list-style: none !important;

      .p-tabview-selected {
        background-color: $primary-color;
        color: $default-white;
        border-radius: 3px;
        .p-tabview-nav-link {
          color: $default-white;
        }
      }
      .p-tabview-nav-link {
        display: flex;
        justify-content: center;
        color: #495057;
        font-size: 15px;
        &:hover {
          text-decoration: none;
        }
      }
      .p-tabview-ink-bar {
        display: none;
      }
      .p-tabview-nav-link {
        border-color: transparent;
        padding: 0.7rem 1rem;
      }
    }
    .p-tabview-panels {
      padding: 0px;
    }
  }

  .login-card {
    width: 100%;
    margin: auto;
    max-width: 460px;
    min-height: auto;
    position: relative;
    left: 10%;
    box-shadow:
      0 12px 15px 0 rgba(0, 0, 0, 0.24),
      0 17px 50px 0 rgba(0, 0, 0, 0.19);

    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }

      50% {
        background-position: 100% 50%;
      }

      100% {
        background-position: 0% 50%;
      }
    }

    .box {
      position: relative;
    }

    .login_card_container {
      position: relative;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 10px;
      box-shadow: 0 25px 45px rgba(0, 0, 0, 0.2);

      &:after {
        content: '';
        position: absolute;
        top: 5px;
        right: 5px;
        bottom: 5px;
        left: 5px;
        border-radius: 5px;
        pointer-events: none;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 2%);
      }
    }

    .login_form-control:focus {
      color: unset !important;
      background: rgba(255, 255, 255, 0.2);
      border-color: unset !important;
      border: 1px solid $bg_MainColor !important;
      outline: 0;
      box-shadow: unset !important;
    }

    .social-buttons-container {
      .social-buttons {
        padding: 0.5rem 1rem;

        .flex {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .app-btn {
          width: 45%;
          max-width: 160px;
          color: $default-white;
          margin: 12px 10px;
          text-align: left;
          border-radius: 5px;
          text-decoration: none;
          font-family: 'Lucida Grande', sans-serif;
          font-size: 10px;
          text-transform: uppercase;
          background-color: $Black !important;
          border: 1px solid lightgrey;

          p {
            margin: 0.5rem !important;
            color: $default-white;

            .big-txt {
              font-size: 15px;
              text-transform: capitalize;
            }
          }

          .social-images {
            width: 20%;
            text-align: center;
            font-size: 28px;
            margin-right: 7px;
            color: $default-white;
          }
        }
      }
    }
  }

  .login-tab-container {
    justify-content: space-around;
    display: flex;
  }

  .flex {
    width: 100%;
    display: flex;
  }
}

@media screen and (max-width: 1199px) {
  .login-screen {
    padding: 0 0 40px 0;
    .login-card {
      left: 0;
    }
  }
}

@media screen and (max-width: 992px) {
  .login-screen {
    padding: 0 0 40px 0;
    .login-card {
      .social-buttons-container {
        .social-buttons {
          .app-btn {
            font-size: 9px;

            p {
              .big-txt {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  .mobile_block {
    display: block !important;
  }

  .pre_header {
    .collapse:not(.show) {
      display: block !important;
    }
  }
}

@media (max-width: 767px) {
  .login-screen {
    min-height: calc(100vh - 50px);

    .login-card {
      width: 97%;
      margin: auto;
      // max-width: 400px;
      margin-top: 20px;
      margin-bottom: 20px;
      min-height: auto;
      position: relative;
      left: unset;

      .social-buttons-container {
        .social-buttons .app-btn p {
          margin: 0.5rem;
          color: $default-white;
          font-size: 8px;
        }
      }
    }
  }

  .collapse:not(.show) {
    position: absolute;
    right: 0;
    width: 50%;
  }
}

@media (max-width: 350px) {
  .goog-te-combo {
    font-size: 14px !important;
    width: 135px !important;
  }

  .login-screen {
    .login-card {
      .nav-tabs {
        .nav-link {
          font-size: 9px;
        }
      }

      .social-buttons-container {
        .social-buttons {
          .app-btn {
            p {
              font-size: 5px;

              .big-txt {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }

  .footer {
    font-size: 12px;
  }
}

:root {
  --margin1: 45px;
  --padding1: 0px;
}
