@import 'theme.scss';

#coffeequotes_accordian_component {
  height: 22px;
  font-size: 16px;
  cursor: pointer;

  .contract-arbitrage-wrapper {
    display: grid;
    grid-template-columns: 160px auto;
    align-items: center;
  }

  .sign-value-wrapper-arabica {
    display: grid;
    grid-template-columns: 8px 34px;
    justify-content: center;
    width: auto;
    gap: 4px;
  }

  .sign-value-wrapper-robusta {
    display: grid;
    grid-template-columns: 8px 34px;
    justify-content: center;
    width: auto;
    gap: 4px;
    margin-left: 14px;
  }

  .sign-value-wrapper-percent {
    display: grid;
    grid-template-columns: 8px 34px;
    justify-content: center;
    width: auto;
    gap: 4px;
    margin-left: -6px;
  }

  .sign-value-wrapper-robusta span:nth-child(2) {
    text-align: left;
  }

  .sign-value-wrapper-arabica span:nth-child(2) {
    text-align: left;
  }
}

@media (min-width: 767.98px) and (max-width: 1360px) {
  #coffeequotes_accordian_component {
    .sign-value-wrapper-robusta,
    .sign-value-wrapper-arabica {
      grid-template-columns: 6px 22px;
      gap: 3px;
      margin-left: 0;
    }

    .sign-value-wrapper-percent {
      grid-template-columns: 6px 22px;
      gap: 1px;
      margin-left: 0;
    }

    .contract-arbitrage-wrapper {
      display: grid;
      grid-template-columns: 100px auto;
      align-items: center;
    }
  }
}
