@import 'theme.scss';

.usda-coffee-report-container {
  user-select: none;
  .usda-heading-container {
    width: 100%;
    background-color: $primary-color;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    position: sticky;
    top: 0;
    z-index: 1;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  .usda-map-data {
    margin: auto;
    height: calc(100vh - 245px);
    overflow-y: auto;
    scrollbar-color: #a8a8a8 #f1f1f1;
  }

  .usda-country-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;

    .dropdown-container {
      padding: 4px 0;
      width: 100%;
    }

    .p-dropdown {
      width: 56%;
      border-color: $light-grey-border;
      height: 32px !important;
      display: flex;
      justify-content: center;
      font-weight: $font-bold;
      align-items: center;
      margin: auto;
      border-radius: 4px;
    }
  }

  .usda-heading {
    // flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 0px;
    height: 100%;

    .usda-heading-content {
      margin: 0;
      font-size: $medium;
      font-weight: $font-bold;
      color: $White;
    }
  }

  .usda-date-container {
    width: 99%;
    border: 1px solid $primary-color;
    border-radius: 8px;
    padding: 6px 0px;
    margin: 8px auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    background-color: $table-header-background;

    .usda-date {
      text-align: left;
      margin-left: 28px;
      position: absolute;
      width: max-content;
      color: $Black;
    }

    .usda-tags {
      text-align: center;
      color: $Black;
      flex: 1;
    }

    p {
      margin: 0px;
      color: $primary-color;
      font-size: $medium;
      font-weight: $font-bold;
    }

    .usda-upcoming-report {
      margin-left: 5px;

      p {
        margin: 0;
        color: $primary-color;
        font-size: $medium;
        font-weight: $font-bold;
      }
    }

    .usda-country-data {
      p {
        margin: 0;
        color: $primary-color;
        font-size: $medium;
        font-weight: $font-semibold;
      }
    }
  }

  .usda-published-container {
    width: 99%;
    padding: 15px;
    margin: 20px auto;

    p {
      display: flex;
      justify-content: center;
      color: $blue;
      font-size: $medium;
      font-weight: $font-semibold;
    }
  }

  .usda-upcoming-container {
    font-size: $medium;
    font-weight: $font-semibold;
    color: $blue;
    text-align: center;
    padding: 38px 0px 30px 0;
  }

  .usda-report-highlights-container {
    padding: 15px;
    margin: auto;
    width: 80%;

    .reports-title {
      font-size: $medium;
      font-weight: $font-semibold;
      text-align: center;
      display: flex;
      justify-content: center;
      color: $blue;
    }

    .report-content {
      margin: 0 auto;
      text-align: center;

      .read-more {
        font-weight: $font-bold;
        color: $primary-color;
      }
    }

    .link-report-container {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: $medium;
        font-weight: $font-semibold;
        margin-right: 10px;
      }

      .report-link {
        font-size: $medium;
        font-weight: $font-semibold;
        margin-right: 10px;
        color: $Black;
        text-decoration: underline;
        &:hover {
          color: $blue;
        }
      }
      .disabled-link {
        font-size: $medium;
        font-weight: $font-semibold;
        margin-right: 10px;
        color: $Black;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          color: $blue;
        }
      }
    }
  }

  .no-data-found-container {
    width: 26%;
    margin: 68px auto;
    display: flex;
    align-items: center;
    flex-direction: column;

    .no-data-found-image {
      width: 100%;
      margin: auto;
    }

    .no-data-found-text {
      width: fit-content;
      text-align: center;
      margin: auto;
      border: 1px solid $primary-color;
      background: $primary-color;
      color: $default-white;
      padding: 6px 16px;
      font-size: 18px;
      border-radius: 24px;
      margin-bottom: 36px;
    }
  }
}

@media (max-width: 767.98px) {
  .usda-coffee-report-container {
    .usda-country-dropdown {
      .p-dropdown {
        margin: initial;
      }

      .p-dropdown-label .common-style-for-template {
        div {
          font-size: small;
        }

        .selected-country-flag {
          width: 35px;
        }
      }
    }

    .usda-heading {
      .usda-heading-content {
        font-size: 15px;
        text-align: center;
      }
    }

    .usda-heading-container {
      padding: 3px 8px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .usda-date-container {
      display: flex;
      flex-direction: column;
      border: 2px solid $primary-color;
      width: 100%;

      .usda-date {
        margin-left: 0px;
        position: static;
        margin: auto;
        text-align: center;
      }

      .usda-tags {
        color: $primary-color;
        margin-top: 2px;
      }
    }

    .usda-report-highlights-container {
      padding: 10px;
      margin: 0;
      width: auto;

      .reports-title {
        color: $Black;
        margin-bottom: 9px;
      }

      .report-content {
        margin: 0 auto;
        text-align: justify;
        width: fit-content;
      }

      .link-report-container {
        margin-top: 9px;
        margin-bottom: 11px;
      }
    }

    .no-data-found-container {
      width: 80%;
    }
  }
}
