@import 'theme.scss';

.country-clock-container {
  margin-left: 12px;
  margin-right: 12px;

  .search-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    width: 100%;
    margin-bottom: 18px;
    margin-left: 10px;

    .select-button-wrapper {
      display: flex;
      gap: 30px;

      .p-button:focus {
        box-shadow: none;
        padding: 0.375rem 0.75rem;
      }
      .p-selectbutton .p-button.p-highlight {
        background: $primary-color;
        border-color: $primary-color;
        padding: 0.375rem 0.75rem;
        color: $White;
      }
      .p-selectbutton .p-button {
        padding: 0.375rem 0.75rem;
        border-color: $primary-color;
        color: $primary-color;
      }
    }
    .search-country-wrapper {
      display: flex;
      gap: 8px;
      align-items: center;

      .country-dropdown-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        .clear-icon {
          cursor: pointer;
          color: $primary-color;

          .close-icon-tool-tip {
            visibility: hidden;
            position: fixed;
          }

          &:hover .close-icon-tool-tip {
            visibility: visible;
            margin-left: -12px;
            background-color: $White;
            padding: 4px 10px;
            border-radius: 4px;
            color: rgb(0, 132, 255);
            box-shadow: 0px 0px 5px gray;
            cursor: pointer;
          }
        }

        .drop-down-field {
          &:hover {
            border-color: $primary-color;
          }
          &:focus {
            box-shadow: none;
            border-color: $primary-color;
          }
        }
        .p-dropdown:not(.p-disabled).p-focus {
          box-shadow: none;
        }
        .p-dropdown {
          width: 200px;
          height: 2.5rem;
          border-color: $primary-color;
          align-items: center;
        }
        .p-inputtext:enabled:focus {
          border-color: $primary-color;
          box-shadow: 0 0 0 0.2rem #50b3f640;
        }
      }

      .setting-icon {
        cursor: pointer;
        margin-right: 0px;
        .setting-tooltip {
          visibility: hidden;
          position: fixed;
        }
      }

      .setting-icon:hover .setting-tooltip {
        visibility: visible;
        margin-left: -65px;
        margin-top: -12px;
        background-color: $White;
        padding: 4px 10px;
        border-radius: 4px;
        color: rgb(0, 132, 255);
        box-shadow: 0px 0px 5px gray;
        cursor: pointer;
      }
    }
  }

  .p-carousel-next.p-link {
    background-color: $White;
    color: $primary-color;
    svg.p-icon {
      width: 2rem;
      height: 2rem;
    }
  }

  .p-carousel-prev.p-link {
    background-color: $White;
    color: $primary-color;
    svg.p-icon {
      width: 2rem;
      height: 2rem;
    }
  }

  .carousel-wrapper {
    width: 100%;
    .p-carousel-items-container {
      align-items: center;
    }
    .no-results-found {
      text-align: center;
      font-size: 22px;
    }
    .no-data-title {
      font-size: 22px;
      font-weight: $fw-bold;
    }
    .p-carousel-prev,
    .p-carousel-next {
      svg {
        color: gray;
      }
    }
  }
  .clock-container {
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .clock-content {
    .analog-clock-dark {
      background: linear-gradient(180deg, rgba(2, 0, 36, 1) 23%, rgba(9, 9, 121, 1) 100%, rgba(0, 212, 255, 1) 100%);
      border-radius: 50%;
      border: 4px solid rgb(172, 169, 169);
      color: whitesmoke;

      .react-clock__mark__body {
        background-color: #c3bebe;
      }

      .react-clock__hand__body {
        background: whitesmoke;
      }
      .react-clock__second-hand__body {
        background-color: $red;
        z-index: 2;
      }
    }
    .analog-clock-light {
      background: linear-gradient(180deg, rgba(235, 233, 233, 1) 25%, rgba(235, 233, 233, 1) 50%, rgba(161, 159, 159, 1) 100%);
      border-radius: 50%;
      border: 4px solid rgb(172, 169, 169);
      color: $Black;
      .react-clock__mark__body {
        background-color: #757575;
      }
      .react-clock__hand__body {
        background: #757575;
      }

      .react-clock__second-hand__body {
        background-color: $red;
        z-index: 2;
      }
    }

    .clock-details-info {
      position: relative;
      width: fit-content;
      text-align: justify;
      .country-name-info {
        font-weight: $fw-bolder;
        font-size: 18px;
        margin-bottom: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 140px;
        text-align: center;
      }
      .country-flag-info {
        position: absolute;
        top: 78%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: auto;
        z-index: 1;
        box-shadow: 0px 0px 0px 2px rgb(199, 199, 199, 0.7);
      }
    }

    .time-details-container {
      display: flex;
      justify-content: center;
      gap: 4px;
      margin-top: 4px;
      flex-direction: column;
      align-items: center;

      .weekday-info {
        font-size: 16px;
        font-weight: $fw-bolder;
      }
      .current-time-info {
        font-size: 16px;
        font-weight: $fw-bolder;
      }
    }
    .react-clock__face {
      border: none;
    }
    .react-clock__hand.react-clock__second-hand {
      z-index: 5;
    }
    .react-clock__hand.react-clock__minute-hand {
      z-index: 4;
    }
    .react-clock__hand.react-clock__hour-hand {
      z-index: 3;
    }
  }

  .digital-clock-container {
    .digital-clock-country-details {
      display: flex;
      justify-content: center;
      .digital-country-name-info {
        font-weight: $fw-bolder;
        font-size: 18px;
        margin-bottom: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 140px;
        text-align: center;
      }
    }
    .digital-clock-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      .digital-clock {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 140px;
        width: 140px;
        border-radius: 50%;
        border: 4px solid rgb(172, 169, 169);

        .digital-clock-item {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          border-radius: 50%;
          background: linear-gradient(180deg, rgba(2, 0, 36, 1) 23%, rgba(9, 9, 121, 1) 100%, rgba(0, 212, 255, 1) 100%);

          .digital-clock-time-wrapper {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 8px;
            height: 50%;
            .digital-time-info {
              font-size: 40px;
              margin-top: -12px;
              color: whitesmoke;
            }
            .digital-period-info {
              margin-top: -20px;
              font-size: 12px;
              color: whitesmoke;
            }
          }
          .country-flag-wrapper {
            position: absolute;
            top: 64%;
            .country-flag-info {
              width: 30px;
              box-shadow: 0px 0px 0px 2px rgb(199, 199, 199, 0.7);
            }
          }
        }

        .digital-clock-item.digital-clock-light {
          background: linear-gradient(180deg, rgba(235, 233, 233, 1) 25%, rgba(235, 233, 233, 1) 50%, rgba(235, 233, 233, 1) 100%);
          .digital-clock-time-wrapper {
            .digital-time-info {
              color: $Black;
            }
            .digital-period-info {
              color: $Black;
            }
          }
        }
      }
    }
    .digital-weekday-info {
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-weight: $fw-bolder;
      margin-top: 4px;
    }
  }
}

.clock-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: #f8f9fa;
  color: $primary-color;
}
.p-inputtext:enabled:focus {
  border-color: $primary-color;
  box-shadow: 0 0 0 0.2rem #50b3f640;
}

.clock-dropdown-panel {
  top: 50px !important;
  width: 200px;
  z-index: 0 !important;
}

.country-dropdown-item-container {
  display: flex;
  gap: 12px;
  .country-flag-container {
    img {
      width: 36px;
      border: 1px solid rgba(0, 17, 51, 0.15);
      border-radius: 3px;
    }
  }
  .country-name-item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100px;
  }
}

.selected-country-container {
  display: flex;
  gap: 12px;
  align-items: center;
  .selected-country-flag {
    img {
      width: 36px;
      border: 1px solid rgba(0, 17, 51, 0.15);
      border-radius: 3px;
    }
  }
  .selected-country-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 66px;
  }
}

@media (max-width: 1280px) {
  .country-clock-container {
    width: 100%;
    margin: auto;
    .search-container {
      .select-button-wrapper {
        margin-left: 12px;
      }
      .search-country-wrapper {
        margin-right: 12px;
      }
    }
    .clock-content {
      .clock-details-info {
        .country-flag-info {
          width: 24px;
          margin-top: 3px;
        }
        .country-name-info {
          width: 80px;
          margin: auto;
        }
      }
      .time-details-container {
        flex-direction: column;
        align-items: center;
        gap: 0px;
      }
    }

    .digital-clock-container {
      .digital-clock-wrapper {
        .digital-clock {
          height: 100px;
          width: 100px;
          .digital-clock-item {
            .digital-clock-time-wrapper {
              .digital-time-info {
                font-size: 25px;
                margin-top: -6px;
              }
              .digital-period-info {
                margin-top: -18px;
                font-size: 10px;
              }
            }
            .country-flag-wrapper {
              top: 61%;
              .country-flag-info {
                width: 26px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .p-dropdown-items-wrapper {
    background-color: $White;
    box-shadow: none;
  }

  .p-inputtext:enabled:hover {
    border-color: $primary-color;
  }
  .country-clock-container {
    display: flex;
    justify-content: center;
    .clock-setting-layout {
      display: none;
    }
    .mobile-view-container {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 12px;
      margin-top: 16px;
      flex-direction: column;
      position: relative;
      z-index: 1;
      .mobile-view-header-wrapper {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        .select-button-wrapper {
          width: 40%;
          margin-left: 12px;
          .p-button:focus {
            box-shadow: none;
            padding: 0.375rem 0.75rem;
          }
          .p-selectbutton .p-button.p-highlight {
            background: $primary-color;
            border-color: $primary-color;
            padding: 10.5px 10.5px;
            color: $White;
            font-size: 10px;
          }
          .p-selectbutton .p-button {
            padding: 10.5px 10.5px;
            border-color: $primary-color;
            color: $primary-color;
            font-size: 10px;
          }
          .select-button {
            display: flex;
            width: max-content;
            margin-right: 4px;
          }
        }
        .remove-button {
          display: none;
        }
        .setting-button-container {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-left: 16px;
        }
        .setting-wrapper {
          width: 60%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .dropdown-wrapper {
          width: 100%;

          .drop-down-field {
            &:hover {
              border-color: $primary-color;
            }
            &:focus {
              box-shadow: 0 0 0 0.2rem #50b3f640;
            }
          }

          .p-dropdown {
            height: 2.5rem;
            width: 100%;
            align-items: center;
            border-color: $primary-color;
          }
        }
      }

      .guest-dropdown-wrapper {
        display: flex;
        width: 55%;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-bottom: 10px;
        .guest-drop-down-field {
          width: 100%;
        }
        .p-dropdown {
          width: 100%;
          border-color: $primary-color;
        }
        .p-dropdown:not(.p-disabled):hover {
          border-color: $primary-color;
        }
      }
    }

    .clock-content {
      .clock-details-info {
        .country-flag-info {
          width: 40px;
          top: 74%;
          margin-top: 0px;
        }
      }
      .time-details-container {
        display: flex;
        flex-direction: row;
        gap: 6px;
        .weekday-info {
          font-size: 14px;
        }
        .current-time-info {
          font-size: 14px;
        }
      }
    }

    .digital-clock-container {
      .digital-clock-wrapper {
        .digital-clock {
          height: 150px;
          width: 150px;
          .digital-clock-item {
            .country-flag-wrapper {
              top: 78%;
              .country-flag-info {
                width: 36px;
              }
            }
            .digital-clock-time-wrapper {
              .digital-time-info {
                font-size: 40px;
              }
              .digital-period-info {
                margin-top: -22px;
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .search-container {
      display: none;
    }
  }
  .clock-dropdown-panel {
    top: 28px !important;
    z-index: 90 !important;
  }

  .mobile-view-drop-down-panel {
    top: 28px !important;
    z-index: 90 !important;
    width: 175px !important;
    left: 177.35px;
  }
  .mobile-view-drop-down-panel .p-dropdown-item.p-highlight {
    background-color: #f8f9fa;
    color: $primary-color;
  }
  .guest-mobile-view-drop-down-panel {
    top: 28px !important;
    z-index: 90 !important;
  }
  .guest-mobile-view-drop-down-panel .p-dropdown-item.p-highlight {
    background-color: #f8f9fa;
    color: $primary-color;
  }
}

@media (max-width: 280px) {
  .country-clock-container {
    .mobile-view-container {
      .mobile-view-header-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .select-button-wrapper {
          width: 100%;
          .p-selectbutton .p-button.p-highlight {
            padding: 8px 19px;
            font-size: 13px;
          }
          .p-selectbutton .p-button {
            padding: 8px 19px;
            font-size: 13px;
          }
        }

        .setting-wrapper {
          width: 95%;
          margin-left: 11px;
        }
      }
    }
  }
  .mobile-view-drop-down-panel {
    top: 28px !important;
    z-index: 90 !important;
    width: 175px !important;
    left: 177.35px;
  }
  .mobile-view-drop-down-panel .p-dropdown-item.p-highlight {
    background-color: #f8f9fa !important;
    color: $primary-color !important;
  }
  .guest-mobile-view-drop-down-panel {
    top: 28px !important;
    z-index: 90 !important;
  }
  .guest-mobile-view-drop-down-panel .p-dropdown-item.p-highlight {
    background-color: #f8f9fa;
    color: $primary-color;
  }
}
