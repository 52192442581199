@import 'theme.scss';

.reports-section {
  .header-container-wrapper {
    background-color: $White;
    position: sticky;
    top: 0px;
    z-index: 12;
    padding-bottom: 4px;
  }

  .header-container {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    background-color: $primary-color;
    padding: 4px;
    border-radius: 4px;
    text-transform: uppercase;

    .content-title {
      font-weight: $font-bold;
      color: $White;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
    }

    .market-selector {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      gap: 4px;
      color: $White;
      font-weight: $font-bold;

      .arrow-toggle {
        display: flex;
        flex-direction: column;

        .arrow-up {
          font-size: $x-small;
          font-weight: $font-bold;
        }

        .arrow-down {
          font-size: $x-small;
          font-weight: $font-bold;
        }
      }
    }

    .swap-content-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      gap: 4px;
      color: $White;
      font-weight: $font-bold;

      .swap-button {
        display: flex;
        flex-direction: column;

        .upper-arrow {
          font-size: $x-small;
          font-weight: $font-bold;
        }

        .down-arrow {
          font-size: $x-small;
          font-weight: $font-bold;
        }
      }
    }

    .filter-cell {
      padding: 4px 4px;
      width: 20%;

      .p-dropdown {
        width: 70%;
        border-color: #dee2e6;
        border-radius: 2px;
        height: 28px !important;
        align-items: center;
        min-width: 0px;
      }

      .p-dropdown-label {
        font-size: 15px;
        font-weight: $fw-bolder;
        color: $Black;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .title-cell {
      width: 60%;
      text-transform: uppercase;
    }

    .swap-button-container {
      padding: 4px 4px;
      width: 20%;

      .p-dropdown {
        width: 100%;
        border-color: #dee2e6;
        border-radius: 2px;
        height: 28px !important;
        align-items: center;
      }

      .p-dropdown-label {
        font-size: 15px;
        font-weight: $font-semibold;
      }

      .swap-button-title {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        gap: 4px;

        .swap-button-icons {
          display: flex;
          flex-direction: column;

          .upper-arrow {
            font-size: $x-small;
            font-weight: $font-bold;
          }

          .down-arrow {
            font-size: $x-small;
            font-weight: $font-bold;
          }
        }
      }
    }
  }

  .report-wrapper {
    overflow-y: scroll;
    padding: 0px 1px;
    height: calc(100vh - 242px);
    scrollbar-color: #a8a8a8 #f1f1f1;

    table {
      width: 100%;
      text-align: center;
      border-collapse: collapse;
      margin-bottom: 20px;

      thead {
        position: sticky;
        top: 0;
        z-index: 1;

        .date-header {
          position: sticky;
          top: 0;

          th {
            background-color: $White;
            padding: 0;
            padding-bottom: 5px;
            height: 32px;

            div {
              background-color: $primary-color;
              color: $White;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 4px;
            }
          }
        }

        .table-header-row {
          background-color: #e9f1f9;
          position: sticky;
          top: 0;
          z-index: 1;
        }

        .arabica-tr-sticky {
          background-color: #e9f1f9;
          position: sticky;
          top: 0;
          z-index: 1;
        }

        .table-header-row-arabica {
          background-color: #e9f1f9;
          position: sticky;
          top: 0;
          z-index: 1;
        }

        tr {
          th {
            padding-bottom: 4px;
            z-index: 1;
            padding: 2px;
            background-color: #e9f1f9;
            outline: 1px solid $primary-color;
            position: relative;
            min-width: 80px;
          }

          .serial-number-column {
            width: 6%;
          }

          .suspended-column {
            width: 10%;
          }

          .origin-column {
            width: 28%;
          }

          .compare-previous-day-column {
            width: 24%;
          }

          .validity-column,
          .non-tenderable-column {
            width: 16%;
          }

          .index-column {
            width: 4%;
          }

          .country-of-origin-column {
            width: 12%;
          }

          .port-name-column {
            // width: 10.5%;
            .flag-country-container {
              display: grid;
              grid-template-columns: 30px auto;
              align-items: center;
              justify-content: center;
              padding: 0px 5px;
              img {
                border: 1px solid rgba(0, 17, 51, 0.1490196078);
                height: 18px;
                width: 24px;
                border-radius: 3px;
                object-fit: cover;
              }

              .warehouse-name {
                font-size: 15px;
              }
            }
            .country-name {
              font-size: $small;
            }
          }

          .previous-day-comparison-column {
            width: 10%;
          }

          .total-column {
            width: 8%;
          }
        }
      }

      tbody {
        border-collapse: collapse;

        .total-tr {
          background-color: #e9f1f9;
          outline: 1px solid $primary-color;

          &:hover {
            background: #e9f1f9;
          }
        }
        .origin-wrapper-for-robusta,
        .origin-wrapper-for-arabica {
          display: grid;
          margin: auto;
          width: 100%;
          justify-content: center;
          align-items: center;
          padding: 0px 5px;
          .flag-icon {
            border: 1px solid #00113326;
            height: 18px;
            width: 24px;
            border-radius: 3px;
            object-fit: cover;
          }
          .origin-name {
            text-align: start;
          }
          .country-name {
            text-align: start;
          }
        }

        .origin-wrapper-for-robusta {
          grid-template-columns: 35px 130px 100px;
        }
        .origin-wrapper-for-arabica {
          grid-template-columns: 35px 150px;
          padding-left: 10px;
        }

        tr {
          &:hover {
            background-color: rgba(0, 0, 0, 0.075);
          }

          td {
            border: 1px solid #dee2e6;
            font-weight: $fw-bolder;
            padding: 2px;
          }
        }

        .positive {
          color: $green;
        }

        .negative {
          color: $red;
        }
      }
    }
  }
}
