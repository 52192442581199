@import 'theme.scss';
.error-boundary-container {
  background-color: #f8d7da;
  border: 1px solid #dc3545;
  padding: 20px;
  margin: 20px auto;
  max-width: 400px;
}

.error-boundary-container h2 {
  color: #dc3545;
}

.session-expired-wrapper {
  background-color: $light-gray-color;
  padding: 10px;
  height: 100vh;
  .session-expired-container {
    border: 1px solid lightgray;
    background-color: $White;
    border-radius: 5px;
    padding: 15px;
    margin: 20px auto;
    max-width: 400px;
    .info {
      padding: 10px 0px;
      text-align: center;
    }
    h3 {
      color: $primary-color;
    }
    .session-expired-login-button {
      display: flex;
      justify-content: center;
      margin: auto;
    }
  }
}
