@import 'theme.scss';

.supportRequest-page {
  .supportRequest {
    margin-bottom: 35px;
    .support-header {
      border: 1px solid transparent;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 2px;

      .support-title {
        font-size: 25px;
        font-weight: $font-semibold;
        margin: 8px 15px;
      }
    }

    .support-main-container {
      width: 74%;
      padding-top: 40px;
      margin: 0px auto;
      display: flex;
      justify-content: space-between;

      .support-image-content {
        width: 35%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .support-image-container {
          display: flex;
          flex-direction: column;
          gap: 55px;
          width: 90%;

          .support-image-svg {
            display: inline-block;
            justify-content: center;
            width: 100%;

            .support-svg {
              width: 100%;
            }
          }

          .support-logo-content {
            display: inline-block;
            width: 80%;
            margin: auto;

            .coffeeweb-logo {
              display: block;
              width: 100%;
            }
          }
        }
        .support-image-logo {
          margin-top: -15px;
        }
      }

      .support-image {
        margin-top: -136px;

        &.form-submit {
          margin-top: 0;
        }
      }

      .support-image-logged-in {
        margin-bottom: 54px;
      }

      .support-form-container {
        display: flex;
        flex-direction: column;
        gap: 14px;
        flex: 0 0 50%;
        max-width: 50%;

        .input-text {
          width: 100%;
        }
        .p-inputtext {
          border: 1px solid $primary-color;
        }

        .label-style {
          margin-top: -11px !important;
        }
        .p-float-label {
          margin-bottom: 5px;
          margin-top: 7px;
        }
        .message-float-label {
          margin-top: -2px;
        }
        .p-inputtext:focus {
          box-shadow: none;
        }
        .support-terms-and-conditions-container {
          font-size: $small;
          display: flex;
          align-items: center;
          gap: 5px;
        }

        .p-checkbox-box {
          border: 1px solid $primary-color;
          cursor: pointer;
        }

        .error-checkbox {
          border-color: $red;
        }

        .checkbox-error {
          color: $red;
          border-bottom: 1px solid $red;
        }

        .p-checkbox-icon {
          margin-top: -1px;
        }

        .p-checkbox.p-highlight .p-checkbox-box {
          border-color: $primary-color;
          background: $primary-color;
        }
        .p-checkbox .p-checkbox-box.p-highlight:hover {
          border-color: $primary-color;
          background: $primary-color;
        }
        .p-checkbox .p-checkbox-box:hover {
          border-color: $primary-color;
        }
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
          border-color: $primary-color;
          box-shadow: none;
        }
        .error-message {
          font-size: $small;
          color: $red;
          margin: -20px 0px 2px 2px;
        }
        .error-for-subject {
          font-size: $small;
          color: $red;
          margin: -25px 0px 10px 2px;
        }
        .required-field {
          color: $red;
          font-size: $x-large;
          margin-left: 1px;
        }

        .support-success-message {
          padding: 20px;
          text-align: center;
          font-size: $medium;

          .icon-container {
            margin-bottom: 10px;
          }

          .check-circle-icon {
            color: $green;
            font-size: 40px;
          }

          .support-success-header {
            color: $Black;
            font-weight: bold;
            font-size: $x-large;
            margin-bottom: 10px;
          }

          .support-content {
            font-size: $medium;
          }

          .message-container {
            line-height: 1.6;
          }
          .support-success-button {
            display: flex;
            justify-content: center;
          }
        }
        .support-submit-button {
          display: flex;
          justify-content: center;
          margin-top: 10px;
        }
      }
    }

    .support-main {
      padding-top: 24px;
    }

    .go-back-button-container {
      display: none;
    }
  }
  .version-number {
    position: fixed;
    z-index: 999;
    right: 1px;
    bottom: 41px;
    font-size: 9px;
    color: $gray;
  }
  .loggedIn-version-number-style {
    position: fixed;
    z-index: 999;
    right: 1px;
    bottom: 0px;
    color: $gray;
    font-size: 9px;
  }
}

@media (max-width: 1024px) {
  .version-number,
  .loggedIn-version-number-style {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .supportRequest-page {
    .supportRequest {
      .support-header {
        display: none;
      }

      .support-main-container {
        display: block;
        width: 100%;
        margin: 0;
        padding: 16px 10px;

        .support-image-content {
          width: 100%;
          justify-content: center;
          margin-bottom: 20px;
          .support-image-container {
            gap: 40px;
            .support-image-svg {
              width: 200px;
              margin: auto;
            }
            .support-logo-content {
              width: 70%;
            }
          }
          .support-image-logo {
            margin-top: 0;
          }
        }
        .support-image {
          margin-top: 0;
        }

        .support-form-container {
          flex: 0 0 100%;
          max-width: 100%;

          .support-submit-button {
            width: 100%;
            .button-wrapper {
              width: 100%;
            }
          }
        }
      }

      .go-back-button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        .soft-action {
          display: grid;
          grid-template-columns: auto auto;
          justify-content: flex-start;
          gap: 7px;
          .go-back-arrow-icon {
            background-color: $primary-color;
            color: $default-white;
            border-radius: 5px;
            padding: 4px;
          }
        }
      }
    }
  }
}
