@import 'theme.scss';

:root {
  --loginThrougnOtpTab: 100%;
}

.login-screen {
  .nav-link {
    color: #495057;
  }
  .login-card {
    width: 100%;
    margin: auto;
    max-width: 460px;
    min-height: auto;
    position: relative;
    left: 10%;
    box-shadow:
      0 12px 15px 0 rgba(0, 0, 0, 0.24),
      0 17px 50px 0 rgba(0, 0, 0, 0.19);

    .login-card-content {
      padding: 1.25rem;
      p {
        color: $red;
      }
      .login-form-groups {
        background-color: $bg_MainColor;
      }
      .forgot-password-container {
        margin: 22px 0px 21px 0px;
        .forgot-password-text {
          color: rgb(0, 123, 255);
          text-align: center;
          width: fit-content;
          margin: auto;
          cursor: pointer;
        }
      }
      .login-links {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #d3d3d394;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 15px;
        color: #494c50;
        gap: 10px;
        .button-wrapper .hard-action.disable-hover:enabled:active,
        .button-wrapper .hard-action.disable-hover:not(button):not(a):not(.p-disabled):active {
          box-shadow:
            0 0 0 2px $button-shadow-primary-color,
            0 0 0 4px $button-shadow-primary-color,
            0 1px 2px 0 $grey-font-color-on-hover;
        }
      }
    }

    .login-form {
      .email-input-field {
        margin-bottom: 1.5rem;

        .form-group {
          margin-bottom: 0;
        }
      }
      .login-form-inputs {
        outline: none;
        border: none;
        border: 1px solid $bg_MainColor;
        background: rgba(255, 255, 255, 0.2);
        padding-left: 14px;
        border-radius: unset;
        color: $Black;
        font-size: $medium;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
        border-radius: 0px 4px 4px 0px;
      }
      .pass-wrapper {
        position: relative;
        display: flex;
        width: fit-content;
        width: 100%;

        .eye-icon {
          position: absolute;
          top: 16%;
          border: unset;
          background: unset;
          width: auto;
          right: 9px;

          img {
            width: 20px;
            cursor: pointer;
          }
        }
      }
      .errorMessage {
        color: $red;
        font-size: $small;
        margin: 4px 0px;
      }
      .login-from-login-button {
        margin: 20px 0px 10px 0px;
      }
      .or-divider {
        display: grid;
        grid-template-columns: 1fr 40px 1fr;
        align-items: center;
        width: 100%;
        text-align: center;
        color: $gray;
        padding: 0px 10px 10px 10px;
        .hr-line {
          height: 1px;
          background-color: $light-gray-color;
        }
      }

      .login-button {
        .login-link {
          color: $default-white;
          text-decoration: none;
        }
      }
      .flex {
        width: 100%;
        display: flex;
      }
      .password-input-block {
        .login-form-groups {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }

      50% {
        background-position: 100% 50%;
      }

      100% {
        background-position: 0% 50%;
      }
    }

    .nav-tabs {
      padding: 0.2rem;
      background-color: $secondary-color;

      .nav-item {
        padding-bottom: unset !important;
        width: 50%;
      }

      .nav-link {
        margin-bottom: -1px;
        border: 1px solid transparent;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        font-weight: 700;
        font-size: 15px;
        width: 100%;
        transition: 0s !important;

        &:hover {
          color: $bg_MainColor;
        }
      }

      .nav-link.active {
        color: #495057;
        background-color: $default-white;
        border-color: $light-grey-border $light-grey-border $White;
        width: 100%;
        color: $default-white;
        font-size: 15px;
        font-weight: 600;
        background-color: $primary-color !important;
        border-bottom: 0px !important;
        border-radius: 3px !important;
        transition: 0s !important;
      }
    }

    .btn.focus,
    .btn:focus {
      outline: 0;
      box-shadow: unset;
      border: unset !important;
    }
  }
  .login-support-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.login-confirmation-modal {
  background-color: $primary-color;
  padding: 0px !important;

  .coffeeWebLogoModal {
    display: inline-block;
    align-items: flex-start;
    width: 180px;
    margin: auto;
    padding: 16px;
  }
  .coffeeWebDevLogo {
    display: inline-block;
    align-items: flex-start;
    width: 150px;
    margin: auto;
    padding: 0px;
  }
}

.login-country-choose-modal {
  width: 440px;
  background-color: $White;
  .country-choose-drop-down {
    border: 1px solid $primary-color;
    box-shadow: none;
    width: 100%;
  }

  .dialog-header-container {
    display: flex;
    justify-content: space-between;
    .header-close-button {
      cursor: pointer;
    }
  }
  .country-selected-container {
    display: flex;
    gap: 12px;
    align-items: center;
    .selected-country-image {
      img {
        width: 34px;
      }
    }
    .selected-country-name {
      font-size: $large;
    }
  }
  .dropdown-error-message {
    font-size: $small;
    color: $red;
  }
}
.login-country-choose-modal.p-dialog .p-dialog-content {
  padding: 0px 24px 16px 24px;
}

.login-country-choose-modal.p-dialog .p-dialog-footer {
  padding: 0px 24px 17px 24px;
}

.country-dropdown-item {
  display: flex;
  gap: 12px;
  .country-title-container {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
  }
}

.country-dropdown-panel {
  width: 28px;

  .p-inputtext:enabled:hover {
    border-color: $primary-color;
    box-shadow: none;
  }
  .p-inputtext:enabled:focus {
    box-shadow: none;
    border-color: $primary-color;
  }
}

.country-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: $primary-color;
  background-color: #f8f9fa;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1199px) {
  .login-screen {
    .login-card {
      left: 0;
    }
  }
}

@media screen and (max-width: 992px) {
  .login-screen {
    .login-card {
      .nav-tabs {
        .nav-item {
          width: 50%;

          .nav-link {
            width: 100%;
          }
        }
      }

      .pass-wrapper {
        width: 100%;

        .password-flow {
          width: 100%;
        }
      }
    }

    .input-group {
      flex-wrap: initial !important;
    }

    .password-input-block {
      .pass-wrapper {
        width: 100%;
      }
    }
  }

  .password-flow {
    width: 100%;
  }

  .mobile-input-block {
    display: block !important;
  }

  .pre_header {
    .collapse:not(.show) {
      display: block !important;
    }

    .navbar-collapse {
      flex-basis: initial;
      flex-grow: initial;
    }
  }
}

@media (max-width: 767px) {
  .pre_header {
    .navbar {
      .main_logo {
        text-align: center;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        width: 50%;

        svg {
          width: 200px;
        }
      }
    }
  }

  .login-screen {
    .login-card {
      width: 97%;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      min-height: auto;
      position: relative;
      left: unset;

      .nav-tabs {
        .nav-link.active {
          color: #495057;
          background-color: $default-white;
          border-color: $light-grey-border $light-grey-border $White;
          color: $bg_MainColor;
          padding: 0.5rem;
          color: $default-white;
        }
      }

      .pass-wrapper {
        width: 100%;

        .password-flow {
          width: 100%;
        }
      }
    }
  }

  .collapse:not(.show) {
    position: absolute;
    right: 0;
    width: 50%;
  }
  .login-country-choose-modal {
    width: 94%;
    background-color: $White;
  }

  .login-dialog-footer {
    .login-terms-and-condition {
      display: block;
      text-align: left;
    }
  }
}

@media (max-width: 350px) {
  .goog-te-combo {
    font-size: $small !important;
    width: 135px !important;
  }

  .navbar-nav {
    flex-direction: inherit !important;
  }

  .login-screen {
    .login-card {
      .nav-tabs {
        .nav-link {
          font-size: 9px;
        }
      }
    }
  }
}

:root {
  --margin1: 45px;
  --padding1: 0px;
}

.single-device-container {
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  .warning-icon {
    width: 70px;
    margin-bottom: 10px;
  }
  .title {
    color: $Black;
    margin-bottom: 15px;
    padding: 0px 8px;
  }
  .description {
    color: $red;
    padding: 0px 8px;
  }
}

.single-device-footer-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.logoutPopupModalBodyWrapper {
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  .circular-progress {
    position: relative;
    width: 50px;
    height: 50px;
    .background-circle {
      fill: none;
      stroke: #d9d9d9;
      stroke-width: 3;
    }

    .progress-circle {
      fill: none;
      stroke: #2cba00;
      stroke-linecap: round;
      stroke-width: 3;
      stroke-dasharray: 147.6548547187203;
      stroke-dashoffset: 147.6548547187203;
      animation:
        progress-animation 5s linear reverse,
        color-change 5s linear reverse;
    }
    .center-text {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .logoutPopupModalBodyImageDiv {
    display: block;
    .logout-popup-warning-icon {
      width: 70px;
      margin-bottom: 15px;
    }
  }

  .countDownCircleContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;

    .remaining-time-text {
      font-size: $xx-large;
      display: flex;
      margin: auto;
    }
  }

  .logoutPopupText {
    color: $Black;
  }
}

.logoutPopupModalFooter {
  display: flex;
  justify-content: center;
  padding: 3px;
}
@keyframes progress-animation {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes color-change {
  0% {
    stroke: #ff0000;
  }
  25% {
    stroke: #ffa700;
  }
  50% {
    stroke: #fff400;
  }
  75% {
    stroke: #a3ff00;
  }
  100% {
    stroke: #2cba00;
  }
}
.login-country-picker-dropdown {
  margin-bottom: 5px;
}
.login-dialog-footer {
  .login-terms-and-condition {
    font-size: 13px;
    margin: 0px 0px 10px 0px;
    color: #495057eb;
  }
  .dialog-button-container {
    display: flex;
    justify-content: center;
    gap: 12px;
    width: 100%;
    margin: 5px 0px;
  }
}
