@import 'theme.scss';

.button-wrapper {
  @mixin hard-action-button-activity {
    &.disable-hover {
      &:hover {
        color: $White;
        border: 1px solid $primary-color;
        background-color: $primary-color;
        border-color: $primary-color;
      }
      &:enabled:active,
      &:not(button):not(a):not(.p-disabled):active {
        background: $primary-color;
        color: $White;
        border-color: $primary-color;
        box-shadow:
          0 0 0 2px $White,
          0 0 0 4px $button-shadow-primary-color,
          0 1px 2px 0 $grey-font-color-on-hover;
      }
    }
    &:hover {
      color: $White;
      border: 1px solid $on-primary-hover;
      background-color: $on-primary-hover;
      border-color: $on-primary-hover;
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }

    &:enabled:active,
    &:not(button):not(a):not(.p-disabled):active {
      background: $on-primary-hover;
      color: $White;
      border-color: $on-primary-hover;
      box-shadow:
        0 0 0 2px $White,
        0 0 0 4px $button-shadow-primary-color,
        0 1px 2px 0 $grey-font-color-on-hover;
    }
    .p-button-label {
      font-weight: $font-normal;
    }
  }

  @mixin soft-action-button-activity {
    &.disable-hover {
      &:hover {
        color: $primary-color;
        background-color: $White;
        border: 1px solid $primary-color;
      }
      &:enabled:active,
      &:not(button):not(a):not(.p-disabled):active {
        background: $White;
        color: $primary-color;
        border-color: $primary-color;
      }
    }
    &:hover {
      background-color: $White;
      color: $on-primary-hover;
      border-color: $on-primary-hover;
    }

    &:focus {
      box-shadow:
        0 0 0 2px $White,
        0 0 0 4px $button-shadow-primary-color,
        0 1px 2px 0 $grey-font-color-on-hover;
    }

    &:enabled:active,
    &:not(button):not(a):not(.p-disabled):active {
      background: $White;
      color: $on-primary-hover;
      border-color: $on-primary-hover;
    }
    .p-button-label {
      font-weight: $font-normal;
    }
  }

  @mixin hard-danger-action-activity {
    &:hover {
      background-color: $danger-red-color;
      color: $White;
      border: 1px solid $White;
    }

    &:focus {
      box-shadow:
        0 0 0 2px $White,
        0 0 0 4px $button-shadow-primary-color,
        0 1px 2px 0 $grey-font-color-on-hover;
    }

    &:enabled:active,
    &:not(button):not(a):not(.p-disabled):active {
      background: $White;
      color: $on-primary-hover;
      border-color: $on-primary-hover;
    }
    .p-button-label {
      font-weight: $font-normal;
    }
  }

  @mixin danger-action-activity {
    &.disable-hover {
      &:hover {
        color: $White;
        background-color: $danger-red-color;
        border: 1px solid $danger-red-color;
      }
      &:enabled:active,
      &:not(button):not(a):not(.p-disabled):active {
        background-color: $danger-red-color;
        color: $White;
        border-color: $danger-red-color;
        box-shadow:
          0 0 0 2px $White,
          0 0 0 4px $button-shadow-danger-color,
          0 1px 2px 0 $grey-font-color-on-hover;
      }
    }
    &:hover {
      background-color: $button-on-hover-danger-color;
      color: $White;
      border: 1px solid $button-on-hover-danger-color;
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }

    &:enabled:active,
    &:not(button):not(a):not(.p-disabled):active {
      background: $danger-red-color;
      color: $White;
      border-color: $danger-red-color;
      box-shadow:
        0 0 0 2px $White,
        0 0 0 4px $button-shadow-danger-color,
        0 1px 2px 0 $grey-font-color-on-hover;
    }
    .p-button-label {
      font-weight: $font-normal;
    }
  }

  @mixin grey-soft-action-button-activity {
    &:hover {
      background-color: $White;
      color: $Black;
      border-color: $light-gray-color;
    }

    &:focus {
      box-shadow:
        0 0 0 2px $White,
        0 0 0 4px $light-gray-color,
        0 1px 2px 0 $grey-font-color-on-hover;
    }

    &:enabled:active,
    &:not(button):not(a):not(.p-disabled):active {
      background: $White;
      color: $on-primary-hover;
      border-color: $on-primary-hover;
    }
    .p-button-label {
      font-weight: $font-normal;
    }
  }

  @mixin icon-css-property {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    gap: 7px;
  }
  .p-button {
    display: block;
  }
  .hard-action {
    min-width: $button-min-width;
    color: $White;
    border: 1px solid $primary-color;
    background-color: $primary-color;
    padding: $button-label-padding;
    font-size: $medium;
    border-radius: 5px;

    @include hard-action-button-activity;
  }
  .soft-action {
    min-width: $button-min-width;
    color: $primary-color;
    padding: $button-label-padding;
    background-color: $White;
    border: 1px solid $primary-color;
    border-radius: 5px;

    @include soft-action-button-activity;
  }
  .hard-danger-action {
    min-width: $button-min-width;
    color: $primary-color;
    padding: $button-label-padding;
    background-color: $White;
    border: 1px solid $primary-color;
    border-radius: 5px;

    @include hard-danger-action-activity;
  }
  .danger-action {
    min-width: $button-min-width;
    color: $White;
    padding: $button-label-padding;
    background-color: $danger-red-color;
    border: 1px solid $danger-red-color;
    border-radius: 5px;

    @include danger-action-activity;
  }
  .grey-soft-action {
    min-width: $button-min-width;
    color: $Black;
    padding: $button-label-padding;
    background-color: $White;
    border: 1px solid $light-gray-color;
    border-radius: 5px;

    @include icon-css-property;
    @include grey-soft-action-button-activity;
  }
  .full {
    width: 100%;
  }
  .x-small {
    font-size: $small;
    padding: 0.2rem 0.4rem;
  }

  .xx-small {
    font-size: $small;
    padding: 0.2rem 0.4rem;
    min-width: 90px;
  }
  .small {
    padding: 0.35rem 0.7rem;
    font-size: $medium;
  }
  .medium {
    font-size: $small;
    padding: 0.2rem 0.4rem;
    min-width: 117px;
  }
  .large {
    padding: 0.55rem 1rem;
    font-size: $x-large;
  }
}
