@import 'theme.scss';

@mixin coffee-report-header-mixin {
  position: sticky;
  top: 0px;
  background-color: $White;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px 15px 8px 15px;
  align-items: center;
  z-index: 12;
}

@mixin coffee-report-title-mixin {
  font-size: 25px;
  font-weight: $font-semibold;
}

@mixin coffee-report-dropdown-mixin {
  align-items: center;
  font-size: $large;
  font-weight: $font-semibold;

  span {
    width: 100%;
    border-radius: 3px;
    color: #4b5563;
  }

  .p-dropdown {
    min-width: 290px;
    border-color: $primary-color;
  }

  .p-dropdown-label {
    font-weight: $font-bold;
    color: $Black;
  }
}

.coffee-reports-component {
  user-select: none;
  .coffee-report-container {
    height: calc(100vh - 144px);
    overflow: auto;

    .coffee-report-header {
      @include coffee-report-header-mixin;

      .title {
        @include coffee-report-title-mixin;
      }

      .coffee-report-dropdown {
        @include coffee-report-dropdown-mixin;
      }
    }

    .coffee-report-table {
      width: 100%;
      padding: 0px 15px;
      margin: auto;
      height: calc(100vh - 204px);
      overflow: auto;
    }

    .coffee-report-table::-webkit-scrollbar {
      height: 12px;
      width: 12px;
    }

    .coffee-report-table::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: darkgray;
    }

    .coffee-report-table::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }
  }
  .country-wise-export-report {
    .coffee-report-header {
      @include coffee-report-header-mixin;

      .title {
        @include coffee-report-title-mixin;
      }

      .coffee-report-dropdown {
        @include coffee-report-dropdown-mixin;
      }
    }

    .coffee-report-table {
      width: 98%;
      margin: auto;
      height: calc(100vh - 195px);
      overflow: auto;
    }

    .coffee-report-table::-webkit-scrollbar {
      height: 12px;
      width: 12px;
    }

    .coffee-report-table::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: darkgray;
    }

    .coffee-report-table::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }
  }
}
.coffee-reports-create-account-info-modal-popup {
  position: fixed;
  width: 100%;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 14;
}

.create-account-info-message .info {
  color: $platinum-default-color;
}

.guest-create-account-info-message .info {
  color: $primary-color;
}

@media (max-width: 1024px) {
  .coffee-reports-component {
    .coffee-report-container {
      .coffee-report-table {
        width: 94%;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .coffee-reports-component {
    .coffee-report-container {
      .coffee-report-header {
        justify-content: flex-end;

        .title {
          display: none;
        }
      }
    }
  }
}
.coffee-reports-component {
  .tab-wrapper {
    display: flex;
    justify-content: center;
    background-color: white;
    padding: 4px;
    border-top: 1px solid lightgray;
    user-select: none;

    .tab-container {
      position: relative;
      width: max-content;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 2px;
      background-color: #e9f1f9;
      border-radius: 9px;
      border: 1px solid lightgray;

      .tab {
        width: 230px;
        position: absolute;
        z-index: 99;
        outline: none;
        opacity: 0;

        &1:checked ~ .indicator {
          left: 3px;
        }

        &2:checked ~ .indicator {
          left: calc(230px + 2px);
        }

        &3:checked ~ .indicator {
          left: calc(230px * 2 + 2px);
        }
      }

      .tab_label {
        width: 230px;
        height: 39px;
        margin-bottom: 0;
        position: relative;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        font-size: 15px;
        font-weight: 600;
        color: rgb(74, 74, 74);
        cursor: pointer;
      }

      .indicator {
        content: '';
        width: 229px;
        height: 37px;
        background: #ffffff;
        position: absolute;
        top: 3px;
        left: 2px;
        z-index: 9;
        border: 1px solid lightgray;
        border-radius: 7px;
        transition: all 0.2s ease-out;
      }
    }
  }
}

@media (max-width: 767px) {
  .coffee-reports-component {
    .country-wise-export-report .coffee-report-header {
      display: flex;
      justify-content: end;
      .title {
        display: none;
      }
    }
    .tab-wrapper {
      .tab-container {
        .tab_label {
          width: -webkit-fill-available;
          font-size: 12px;
          padding: 5px;
        }
        .indicator {
          width: 134px;
          transition: none;
          width: calc(100% / 3);
        }
        .tab {
          &:nth-of-type(1):checked ~ .indicator {
            left: 0;
          }

          &:nth-of-type(2):checked ~ .indicator {
            left: calc(98% / 3);
          }

          &:nth-of-type(3):checked ~ .indicator {
            left: calc(198% / 3);
          }
        }
      }
    }

    .coffee-reports-create-account-info-modal-popup .coffee-web-app-links,
    .create-account-info-modal .content-wrapper .coffee-web-app-links {
      width: 311px;
    }
    .create-account-info-modal .content-wrapper {
      width: 334px;
      height: 330px;
    }
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .coffee-reports-create-account-info-modal-popup .coffee-web-app-links,
  .create-account-info-modal .content-wrapper .coffee-web-app-links {
    width: 360px;
  }
}

@media (max-width: 1024px) {
  .coffee-reports-component .coffee-reports-create-account-info-modal-popup .content-wrapper {
    gap: 20px;
    .info {
      font-size: 14px;
    }
  }
}
