.layout-body-content {
  width: 100%;
  margin-top: 90px;

  &.menu-absent {
    margin-top: 50px;
  }

  .layout-loader-wrapper {
    width: 100%;
    position: fixed;
    height: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffffcc;
    z-index: 1200;

    .layout-loader {
      width: 80px;
      height: 80px;
      position: relative;
      top: -90px;
      animation: heartbeat 1s infinite;
    }
    .custom-loader-text-message {
      font-size: 24px;
      font-weight: 600;
    }

    @keyframes heartbeat {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.2);
      }

      100% {
        transform: scale(1);
      }
    }
  }
}

@media (max-width: 767.98px) {
  .layout-body-content {
    margin-top: 50px;
  }
}
