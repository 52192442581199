@import 'theme.scss';

.lot-wise-report-container {
  max-height: calc(100vh - 180px);
  display: grid;
  grid-template-rows: 40px 1fr;
  overflow: hidden;

  thead {
    border: solid;
    color: $White;
    border: 0px solid $primary-color;
    border-top: 1px solid $primary-color;

    tr {
      th {
        padding: 4px 0px;
      }
    }
  }

  tbody {
    border-collapse: collapse;

    tr {
      td {
        border: 1px solid #dee2e6;
        font-weight: $fw-bolder;
      }
    }
  }

  .table-content {
    width: 100%;
    text-align: center;
    display: grid;
    grid-template-columns: 2fr 8fr;
    z-index: 1;
    gap: 4px;
    margin-top: -4px;

    .blank-row {
      height: 7px;
    }

    .highlighted {
      background-color: rgba(0, 0, 0, 0.075);
    }

    .table-content-left {
      width: 100%;
      height: calc(100vh - 255px);
      overflow: scroll;
      overflow-x: hidden;
      padding: 0px 1px;

      &::-webkit-scrollbar {
        width: 0px !important;
      }

      table {
        width: 100%;
        margin-bottom: 16px;
        border-collapse: collapse;

        thead {
          .table-content-left-header {
            position: sticky;
            top: 32px;
            z-index: 10;
            outline: 1px solid $primary-color;
            text-transform: uppercase;
          }

          .empty-header-row {
            height: 32px;
            position: sticky;
            top: 0;
            background-color: $White;
            outline: 1px solid $primary-color;
          }
          .empty-row {
            height: 32px;
            outline: 1px solid $primary-color;
          }

          tr {
            z-index: 1;
            color: $Black;

            th {
              outline: 1px solid $primary-color;
              background-color: $table-header-background;
              position: relative;
            }
          }
        }

        tbody {
          tr {
            td {
              border: 1px solid #add8e6;
              color: $Black;
            }

            .negative-color {
              color: $red;
            }

            .positive-color {
              color: $green;
            }

            .light-purple {
              color: $light-purple;
            }
          }
        }
      }
    }

    .table-content-right {
      width: max-content;
      height: calc(100vh - 240px);
      overflow: scroll;
      display: flex;
      overflow-x: scroll;
      width: 100%;
      gap: 40px;
      scrollbar-color: #a8a8a8 #f1f1f1;
      padding: 0px 3px;

      table {
        width: 100%;
        height: max-content;
        border-collapse: collapse;

        thead {
          width: 100%;

          .header-row-top {
            height: 32px;
            position: sticky;
            top: 0;
            background-color: $White;
            outline: 1px solid $primary-color;
            z-index: 10;
          }
          .table-header-main {
            position: sticky;
            top: 32px;
            text-transform: uppercase;
          }
          .header-row-bottom {
            height: 32px;
          }

          .table-content-right-title {
            position: sticky;
            top: 0;
            background-color: $White;
          }
          .table-content-right-row-title {
            position: sticky;
            top: 33px;
          }
          tr {
            z-index: 1;
            th {
              min-width: 140px;
              margin: auto;
              color: $Black;
              outline: 1px solid $primary-color;
              background-color: $table-header-background;
              position: relative;
            }

            .positive-label {
              color: $green;
            }

            .negative-label {
              color: $red;
            }
          }
        }

        tbody {
          width: 100%;

          tr {
            td {
              border: 1px solid #add8e6;
              color: $Black;
            }

            .negative-color {
              color: $red;
            }

            .positive-color {
              color: $green;
            }

            .positive-label {
              color: $green;
            }

            .negative-label {
              color: $red;
            }
          }
        }
      }
    }
  }
}
