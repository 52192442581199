@import 'theme.scss';

.popup_card_for_notification {
  max-height: 400px;
  overflow: scroll;
  overflow-x: hidden;
  width: 430px;

  .notification-sub-div {
    padding: 5px 15px;
    font-size: $x-large;
    color: $primary-color;
    display: flex;
    justify-content: space-between;
    width: 430px;
    align-items: center;
    position: absolute;
    background-color: $default-white;
    z-index: 2;
    border-bottom: solid 2px lightgray;
  }
  .notification-mark-read {
    color: $primary-color;
    border-radius: 4px;
    font-size: $small;
    cursor: pointer;
  }

  b {
    font-weight: 400;
  }

  #unRead {
    background-color: #e9ecef;
    color: rgb(46, 46, 46);
    border-left: 4px solid $primary-color;
  }

  #unRead:hover {
    background-color: #e0e1e2;
    border-left: 6px solid $primary-color;
  }
  .see-more-notification {
    background-color: $default-white;
    border-radius: 0 0 5px 5px;
    border-top: 2px solid #e0e1e2;
    bottom: -6px;
    color: $primary-color;
    padding: 10px;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 2;
  }
  .see-all-notification-text {
    cursor: pointer;
    width: 50%;
    margin: auto;
    &:hover {
      text-decoration: underline;
    }
  }
}

.notification_icon_box {
  display: flex;
  align-items: center;
  text-align: center;

  .notification-icon {
    font-size: $medium;
    border-radius: 50%;
    background-color: $default-white;
    padding: 7px;
    margin-top: 2px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    color: $primary-color;
    cursor: pointer;

    .p-badge {
      background-color: rgb(211, 47, 47);
      border-radius: 4px;
      height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $xx-small;
      position: absolute;
    }
  }
}

.infinite_scroll {
  margin-top: 33px;
  margin-bottom: 40px;

  .infinite_scroll_div {
    display: flex;
    justify-content: center;
    color: $primary-color;
    position: relative;
  }
  .notification-menu {
    border-top: 1px solid lightgray;
  }
  .notification-list {
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 15px;
    cursor: pointer;
  }
  .notification-html-news-list {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .notification-news-date {
    color: $gray;
    font-size: 12px;
  }
}

.no-notification {
  color: $gray;
  padding: 30px 20px;
  margin: auto;
  display: flex;
  justify-content: center;
  font-size: $large;
}

.overlay-panel-content {
  position: fixed !important;
  top: 50px !important;
  display: block !important;
  width: 430px;
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  z-index: 1000 !important;

  &.is-loading {
    z-index: 1300 !important;
  }

  .p-overlaypanel-content {
    padding: 0px;
  }
}

@media (max-width: 767.98px) {
  .p-overlaypanel {
    width: 90%;
  }

  .popup_card_for_notification {
    width: 100%;

    .notification-sub-div {
      width: 100%;
    }
  }

  .no-notification {
    padding: 30px 100px;
  }

  .notification_icon_box {
    .notification-icon {
      padding: 8px;
    }
  }
}

.notification-icon {
  .p-badge {
    top: 1px !important;
  }
}

.toast_div {
  position: fixed !important;
  bottom: 35px;
  right: 10px;
  .toast_sub_div {
    background-color: white;
    display: flex !important;
    flex-direction: row !important;
    padding: 15px;
    align-items: self-end;
    gap: 15px;
    box-shadow: 0px 0px 5px gray;
    border-radius: 8px;

    .toast_container {
      max-width: 200px;

      .toast_button {
        padding: 5px 10px;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .toast_div {
    position: fixed !important;
    top: 25px;
    right: 0px;
    width: 100vw;
    padding: 0px 10px;

    .toast_sub_div {
      background-color: white;
      display: flex !important;
      flex-direction: row !important;
      padding: 15px;
      bottom: 35px;
      right: 10px;
      align-items: self-end;
      gap: 15px;
      box-shadow: 0px 0px 5px gray;
      border-radius: 8px;
    }
  }
}
