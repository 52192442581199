@import 'theme.scss';
.global-raw-coffee-price-container {
  margin-top: 90px;
  user-select: none;
  .global-raw-coffee-price-headers {
    position: fixed;
    background-color: $default-white;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 12px 15px 5px 15px;
    align-items: center;
    background-color: white;
    z-index: 2;
    .title {
      font-size: 25px;
      font-weight: 600;
    }
    .currency-dropdown {
      display: grid;
      align-items: center;
      font-size: 18px;
      gap: 5px;
      grid-template-columns: auto 1fr;
      font-weight: 600;
      span {
        width: 100%;
      }
    }
    .react-custom-flag-select__select__wrapper {
      width: 300px;
    }
  }
  .global-raw-coffee-price-wrapper {
    padding-top: 63px;
    padding-bottom: 25px;
    margin: 0px 15px;

    .crop-year-main-header {
      background-color: $primary-color;
      padding: 2px 12px;
      color: $White;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      font-weight: 600;
      margin-bottom: 5px;
      position: sticky;
      top: 149px;
      z-index: 1;
      .country-and-crop-year {
        font-size: 20px;
        display: grid;
        align-items: center;
        grid-template-columns: 45% 50%;
        .country-with-flag {
          display: flex;
          align-items: center;
          gap: 2px;
        }
        .flag-icon {
          border: 1px solid #00113326;
          border-radius: 3px;
          height: 28px;
          margin-right: 3px;
          object-fit: cover;
          width: 40px;
        }
      }
      .coffee-labeling {
        text-align: center;
      }
    }

    .global-raw-coffee-price-table {
      .each-table {
        width: 100%;
        .global-raw-coffee-price-table-header {
          .crop-year-th {
            th {
              padding: 0px;
              border: 1px solid $primary-color;
              .crop-year-div {
                border-bottom: 1px solid $primary-color;
                background-color: #e9f1f9;
                margin-bottom: 5px;
                display: grid;
                grid-template-columns: 5fr 2fr 5fr;
                color: $primary-color;
                padding: 4px 18px;
                .updated-on-labeling {
                  color: #b200ff;
                }
                .forex-type-value {
                  text-align: end;
                }
              }
            }
          }
          .top-first-th-row {
            th {
              border: 1px solid $primary-color;
              background-color: #e9f1f9;
            }
            .sl-no-th {
              width: 3%;
            }
            .quality-th {
              width: 35%;
            }

            .terminal-th {
              width: 18%;
            }
            .remarks-th {
              width: 40%;
            }
          }
          .quality-group-name-heading,
          .between-heading {
            color: $Black;
          }
          .arabica-heading {
            color: darkcyan;
          }
          tr,
          th,
          td {
            border: 1px solid lightblue;
            text-align: center;
            color: $Black;
            padding: 4px 0px;
            background-color: $default-white;
          }
        }
        .global-raw-coffee-price-table-body {
          border: 1px solid $primary-color;

          .high-value {
            color: $green;
            font-weight: $font-semibold;
          }

          .low-value {
            color: $red;
            font-weight: $font-semibold;
          }

          .quality-name {
            text-decoration: underline;
            position: relative;
            .quality-name-popup-icon {
              display: flex;
              align-items: center;
              justify-content: center;
              text-transform: capitalize;
            }
            &:hover {
              color: blue;
              .popup-icon {
                opacity: 1;
              }
            }
            .popup-icon {
              height: 16px;
              width: 16px;
              opacity: 0.5;
              position: absolute;
              right: 22px;
            }
          }
          .between {
            font-weight: 600;
          }
          tr,
          th,
          td {
            border: 1px solid lightblue;
            text-align: center;
            padding: 4px 0px;
          }
          tr:hover {
            background-color: rgba(0, 0, 0, 0.075);
            cursor: pointer;
          }
        }
      }
      .end-divider-wrapper {
        margin: 18px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .end-divider-dots {
          border-radius: 15px;
          height: 6px;
          width: 6px;
          background-color: $primary-color;
        }
        .end-divider-line {
          height: 0px;
          width: 100%;
          border: 1px solid $primary-color;
          background-color: $primary-color;
        }
      }
    }
  }
  .no-data-found {
    width: 390px;
    margin: auto;

    .no-data-found-image {
      width: 100%;
      padding: 10px;
    }
    .no-data-text {
      width: fit-content;
      text-align: center;
      margin: auto;
      border: 1px solid $primary-color;
      background: $primary-color;
      color: $default-white;
      padding: 6px 16px;
      font-size: 18px;
      border-radius: 24px;
      margin-bottom: 36px;
    }
  }
  .raw-coffee-price-create-account-info-modal-popup {
    position: fixed;
    width: 100%;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
  }
}

@media (max-width: 767.98px) {
  .global-raw-coffee-price-container {
    margin-top: 50px;
    .global-raw-coffee-price-headers {
      display: block;
      .react-custom-flag-select__select__wrapper {
        width: 250px;
      }
    }
    .global-raw-coffee-price-wrapper {
      padding-top: 105px;
    }
  }
}
