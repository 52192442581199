@import 'theme.scss';

.weekly-wise-report-container {
  thead {
    border: solid;
    color: $Black;
    border: 0px solid $primary-color;

    tr {
      th {
        padding: 4px 0px;
      }
    }
  }

  .highlighted {
    background-color: rgba(0, 0, 0, 0.075);
  }

  tbody {
    border-collapse: collapse;

    tr {
      &:hover {
        background-color: rgba(0, 0, 0, 0.075);
      }

      td {
        border: 1px solid #dee2e6;
        font-weight: $fw-bolder;
      }
    }
  }

  .table-content {
    width: 100%;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 3fr;
    z-index: 1;
    gap: 0px;
    margin-top: 4px;
    background-color: $White;

    .blank-row {
      height: 7px;
    }

    .highlighted {
      background-color: rgba(0, 0, 0, 0.075);
    }

    .table-content-left {
      max-height: calc(100vh - 255px);
      overflow: scroll;
      overflow-x: hidden;
      padding: 2px 1px;
      background-color: $White;

      &::-webkit-scrollbar {
        width: 0px !important;
      }

      table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 18px;

        thead {
          position: sticky;
          top: 0px;
          background-color: $White;
          z-index: 12;

          tr {
            z-index: 1;
            background-color: $White;

            th {
              padding-top: 22px;
              padding-bottom: 18px;
              min-width: 92px;
              outline: 1px solid $primary-color;
              position: relative;
              background-color: $table-header-background;
            }
          }
        }

        tbody {
          tr {
            td {
              border: 1px solid #add8e6;
            }
          }
        }
      }
    }

    .table-content-right {
      max-height: calc(100vh - 240px);
      overflow: scroll;
      overflow-x: scroll;
      width: 100%;
      scrollbar-color: #a8a8a8 #f1f1f1;
      text-transform: uppercase;
      padding: 2px 1px 0px 4px;
      background-color: white;

      .table-data {
        display: flex;
        gap: 40px;

        table {
          width: 100%;
          height: max-content;
          border-collapse: collapse;
          margin-bottom: 18px;

          thead {
            width: 100%;
            position: sticky;
            top: 0;
            z-index: 12;

            tr {
              background-color: white;
              th {
                min-width: 96px;
                margin: auto;
                color: $Black;
                outline: 1px solid $primary-color;
                background-color: $table-header-background;
                position: relative;
              }

              .positive-label {
                color: $green;
              }

              .negative-label {
                color: $red;
              }
            }
          }

          tbody {
            width: 100%;

            tr {
              td {
                border: 1px solid #add8e6;
                color: $Black;
              }

              .positive-label {
                color: $green;
              }

              .negative-label {
                color: $red;
              }
            }
          }
        }
      }
    }
  }
}
