@import 'theme.scss';

.certified-reports-wrapper {
  .header-container-wrapper {
    background-color: $White;
    position: sticky;
    top: 0px;
    padding-bottom: 4px;
    z-index: 12;
    .header-container {
      display: grid;
      grid-template-columns: 1fr 3fr 1fr;
      background-color: $primary-color;
      padding: 4px 4px;
      border-radius: 4px;

      .content-title {
        font-weight: $font-bold;
        color: $White;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .market-selector {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        text-transform: uppercase;
        gap: 4px;
        color: $White;
        font-weight: $font-bold;
        .arrow-toggle {
          display: flex;
          flex-direction: column;
          .arrow-up {
            font-size: $x-small;
            font-weight: $font-bold;
          }
          .arrow-down {
            font-size: $x-small;
            font-weight: $font-bold;
          }
        }
      }
      .swap-content-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        gap: 4px;
        color: $White;
        font-weight: $font-bold;
        text-transform: uppercase;
        .swap-button {
          display: flex;
          flex-direction: column;
          .upper-arrow {
            font-size: $x-small;
            font-weight: $font-bold;
          }
          .down-arrow {
            font-size: $x-small;
            font-weight: $font-bold;
          }
        }
      }
    }
  }

  .table-container {
    overflow-x: auto;
    height: calc(100vh - 240px);
    padding: 0px 2px 0px 1px;
    scrollbar-color: #a8a8a8 #f1f1f1;

    table {
      width: 100%;
      text-align: center;
      border-collapse: collapse;

      thead {
        position: sticky;
        top: 1px;
        color: $White;
        z-index: 12;

        tr {
          th {
            padding: 2px;
            background-color: #e9f1f9;
            outline: 1px solid $primary-color;
            color: $Black;
            min-width: 200px;
            white-space: nowrap;
            position: relative;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              min-width: 300px;
              width: 300px;
            }
          }
        }
        .empty-row {
          height: 7px;
        }
      }

      tbody {
        border-collapse: collapse;
        border: 1px solid red;

        tr {
          &:hover {
            background-color: rgba(0, 0, 0, 0.075);
          }

          td {
            border: 1px solid #add8e6;
            font-weight: $fw-bolder;
            white-space: nowrap;
            padding: 0px 14px;
          }
        }

        .positive {
          color: $green;
        }

        .negative {
          color: $red;
        }

        .normal {
          color: $Black;
        }
      }

      .report-table-body {
        filter: blur(3px);
        z-index: 1;
      }
    }
  }
}

@media (max-width: 1024px) {
  .certified-reports-wrapper {
    table {
      thead {
        tr {
          .report-date,
          .certified-stocks,
          .compare-to-pre-day,
          .non-tenderable,
          .suspended {
            font-size: $x-small;
            padding: 6px 0px;
          }
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .certified-reports-wrapper {
    table {
      .report-table-body {
        filter: blur(3px);
        z-index: 1;
      }
    }
  }
}
