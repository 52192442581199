@import 'theme.scss';

.forgot-password-modal {
  display: flex !important;
  justify-content: center;
  width: 100%;

  .modal-body-style {
    .before-success-wrapper {
      display: flex;
      flex-direction: column;

      .forgot-password-input {
        width: 85%;
        display: block;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: $White;
        background-clip: padding-box;
        border: 1px solid $primary-color;
        border-radius: 0.25rem;
        transition:
          border-color 0.15s ease-in-out,
          box-shadow 0.15s ease-in-out;

        &:focus {
          color: unset !important;
          background-color: $White;
          border-color: $primary-color !important;
          outline: 0;
          box-shadow: unset !important;
        }
      }
      .title {
        font-size: 22px;
      }
      .desc {
        color: $gray;
        margin-bottom: 2px;
        font-size: $medium;
      }
      .error-message {
        color: $red;
        font-size: $small;
        margin-top: 2px;
      }
      .success-message {
        color: $green;
      }
    }
  }
}

@media (max-width: $device-small) {
  .forgot-password-modal {
    .modal-body-style {
      .before-success-wrapper {
        .forgot-password-input {
          width: 100%;
        }
        span {
          right: 20px;
        }
      }
    }
  }
}
