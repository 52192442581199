@import 'theme.scss';

.coffeequtes-accordian_for_mobile {
  padding: 4px 7px;

  .accordian_main_heading {
    background-color: $primary-color;
    color: $default-white;
    padding: 2px 6px;
    font-size: 16px;
    display: grid;
    grid-template-columns: 24fr 11fr 11fr 11fr 3fr;

    .contract-name-heading {
      margin-left: 10px;
    }

    .tr_heading {
      display: flex;
      justify-content: center;
    }

    .country-flag-icon {
      height: 20px;
      border: 1px solid rgba(0, 17, 51, 0.15);
      border-radius: 2px;
      margin-top: -2px;
      margin-right: 8px;
    }

    .timings {
      margin-left: 4px;
      font-size: 14px;
    }
  }

  .accordian_sub_heading {
    .tr_sub_heading {
      font-size: 12px;
      $default-white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      justify-content: start;
      display: grid;
      grid-template-columns: 13fr 6fr 6fr 6fr 1fr;
      padding: 1px 3px;
      align-items: center;

      .first_row {
        display: flex;
        justify-content: start;
        font-size: 15px;
      }

      .each_row_heading_name {
        display: flex;
        font-size: 15px;
        justify-content: center;
      }

      .sign-value-wrapper,
      .sign-value-wrapper-robusta {
        display: grid;
        grid-template-columns: 8px 38px;
        gap: 4px;
      }

      .sign-value-wrapper-robusta {
        margin-left: 14px;
      }

      .sign-value-wrapper-robusta div:nth-child(2),
      .sign-value-wrapper div:nth-child(2) {
        text-align: left;
      }
    }
  }

  .accordion-item {
    background-color: $default-white;
    display: flex;
    justify-content: start;
    flex-direction: column;

    button {
      background-color: $default-white;
      border: none;
      border-radius: 0px !important;
      color: $logo-text-color;
      font-size: 16px;
      width: 100%;
      justify-content: start;
    }
  }

  h2 {
    margin-bottom: 0px;
    color: black;
    border: 1px solid #d6d6d6;
    display: flex;
  }

  .accordian_main_body {
    padding: 1px 0px;
    font-size: 15px;

    .accodian_sub_div {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .each_data {
      margin: 4px 2px;
      background-color: #40acf638;
      border-radius: 4px;
      padding: 3px 8px;
      display: grid;
      grid-template-columns: 68px 1fr;

      .net-move-wrapper {
        display: flex;

        .sign-value-wrapper {
          display: grid;
          grid-template-columns: 8px auto;
          gap: 3px;
          text-align: center;
          margin-left: 4px;
        }
      }
    }

    .data_for_date {
      display: flex;
      margin-bottom: 4px;
      justify-content: center;
      background-color: #40acf638;
      border-radius: 4px;
      padding: 3px 8px;
    }
  }

  #highlight_color {
    background-color: #ccc;
  }

  #idle_color {
    button {
      background-color: $default-white;
    }
  }

  .p-accordion-tab {
    margin-bottom: 0px;
  }
  .p-accordion-header-link {
    padding: 1px 6px;
    width: 100%;
    border-radius: 0px;
    text-decoration: none;
    color: $Black;
    font-weight: 400;
    .p-accordion-toggle-icon {
      display: none;
    }
  }
  .p-accordion-content {
    border: 0px;
    padding: 0px;
    color: $Black;
  }
}
