@import 'theme.scss';
.payment-status {
  .cancel {
    margin-top: 130px;
    width: 100%;
    display: flex;
    justify-content: center;
    .cancel-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      .exclamation-circle-icon {
        color: $red;
        margin-top: -3px;
        margin-right: 4px;
      }
    }
    .cancel-payment {
      color: $red;
      font-size: 25px;
    }
  }
}
