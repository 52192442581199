@import 'theme.scss';

.coffeeNewsMainDivForScroll {
  height: calc(100vh - 100px);
  overflow-y: scroll;
}

.coffee_news_feeds {
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  margin-top: 44px;

  &.in-read-more {
    margin-top: 0px;
  }

  #forScroll {
    position: static;
  }

  .feeds_container {
    .news_feeds_card {
      margin-bottom: 10px;
      user-select: none;
      margin-left: clamp(1vh, 10vw, 2rem);
      margin-right: clamp(1vh, 10vw, 2rem);
      margin-left: 25px;
      margin-right: 25px;

      .date_and_time_stamp {
        display: grid;
        justify-content: center;
        grid-template-columns: 1fr auto 1fr;
        color: white;
        align-items: center;
        gap: 10px;

        .date_and_time_stamp_sub_div {
          height: 2px;
          width: 100%;
          background-color: $primary-color;
          border-radius: 5px;
        }

        .date {
          color: #50b3f6;
          color: white;
          background-color: #50b3f6;
          padding: 5px 20px;
          font-size: 15px;
          border-radius: 20px;
          text-align: center;
          font-weight: 600;
          cursor: pointer;
        }
      }

      .date_tag_design {
        display: grid;
        justify-content: center;
        grid-template-columns: 1fr auto 1fr;
        color: white;
        align-items: center;
        gap: 10px;
        margin: 15px 0px 10px 0px;
        position: fixed !important;
        left: 0px;
        top: 126px !important;
        z-index: 1;

        .date_horizontal_line {
          height: 2px;
          width: 100%;
          background-color: transparent;
          border-radius: 5px;
        }

        .date {
          margin-top: 3px;
          color: white;
          background-color: #50b3f6;
          padding: 5px 20px;
          font-size: 15px;
          border-radius: 20px;
          text-align: center;
          font-weight: 600;
        }
      }

      .headerSec2 {
        display: flex;
        gap: 10px;

        .search_for_big_screen {
          display: flex;

          .p-inputgroup {
            width: 100%;
            border: 1px solid $primary-color;
            border-radius: 5px;
          }

          .p-inputgroup .p-input-icon-right {
            position: relative;
            width: 100%;
          }

          .p-inputgroup .p-input-icon-right .pi-search {
            position: absolute;
            right: 10px;
            top: 65%;
            transform: translateY(-50%);
            font-size: $x-large;
            color: $primary-color;
            margin-right: 10px;
            cursor: pointer;
          }
          .p-inputgroup .p-input-icon-right .p-input-clear-icon {
            position: absolute;
            right: 5px;
            top: 65%;
            transform: translateY(-50%);
            font-size: $medium;
            color: $primary-color;
            margin-right: 50px;
            cursor: pointer;
          }

          .p-inputtext {
            margin: auto;
            width: 70%;
            height: 41px;
            box-shadow: none;
            margin-right: 40px;
            border: none;
          }

          .p-inputtext:enabled:focus {
            box-shadow: none !important;
          }
        }
      }

      .language_and_search {
        display: grid;
        grid-template-columns: 100% 0px;
        gap: 5px;
        border-radius: 4px;
        .loading-dropdown {
          height: 43px;
          border: 1px solid $primary-color;
          border-radius: 4px;
          display: flex;
          align-items: center;
          padding-left: 20px;
          font-size: 17px;
          width: 170px;
        }

        .language_div {
          height: 38px;
          border-radius: 3px;
          display: flex;
          justify-content: center;
          align-items: center;

          .flag_image {
            margin-left: 10px;
            width: 29px;
            border-radius: 2px;
            border: 1px solid rgba(0, 17, 51, 0.15);
          }
        }

        .country_select_dropdown {
          width: 135px;
          height: 38px;
          display: flex;
          align-items: center;

          .language_dropdown {
            width: 100%;
            height: 30px;
            padding: 0;
            display: flex;
            align-items: center;
          }

          .dropdown_flag_image {
            height: 16px;
            width: 22px;
            border-radius: 2px;
            object-fit: cover;
            border: 1px solid rgba(0, 17, 51, 0.15);
          }
        }

        .small_screen_search_div {
          display: none !important;
        }

        .big_screen_language_dropdown {
          display: block;
          background-color: white;
          display: flex;
          align-items: center;
          border-radius: 3px;

          fieldset {
            border: none !important;
          }
        }

        .small_screen_language_dropdown {
          display: none;
        }

        .buy_subscription_div {
          display: flex;
          gap: 6px;
          height: 43px;

          .buy_subscription_sub_div {
            display: none !important;
          }
        }
      }

      .coffee_news_title {
        display: block;
        margin: 7px;
        font-weight: '';
        font-size: 25px;
      }

      .tabs_for_news_feed_screen {
        width: 378px;
        background-color: white;
        margin-top: 72px;

        .tabs_nav_link {
          margin: auto;
          display: flex;
          justify-content: space-between;
          margin-top: 25px;
          padding: 0px 8px 0px 8px;
          width: 330px;

          .tabs_nav_link_sub_div {
            width: 50%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $primary-color;
            color: $default-white;
            padding: 5px;
            border-radius: 4px 0px 0px 4px;
            cursor: pointer;

            .dailyFeed-icon {
              color: $default-white;
              margin: 0px 5px;
              font-size: 17px;
              margin-top: -3px;
            }
          }

          .tab_daily_feed {
            width: 50%;
            text-align: center;
            border: 1px solid $primary-color;
            color: $primary-color;
            background-color: $default-white;
            padding: 5px;
            border-radius: 0px 4px 4px 0px;
            cursor: pointer;

            .filled_heart {
              color: $red;
              width: 20px;
              margin: 0px 3px;
              margin-top: -3px;
            }
          }

          .tab_daily_feed.guest-large-view {
            display: block;
          }

          .tab_daily_feed.guest-mobile-view {
            display: none;
          }
        }

        .non_active_nav_link {
          margin: auto;
          display: flex;
          justify-content: space-between;
          margin-top: 25px;
          padding: 0px 8px 0px 8px;
          width: 330px;

          .non_active_link_sub_div {
            width: 50%;
            text-align: center;
            color: $primary-color;
            padding: 5px;
            border: 1px solid $primary-color;
            background-color: $default-white;
            border-radius: 4px 0px 0px 4px;
            cursor: pointer;
          }

          .non_active_my_favourite_div {
            width: 50%;
            text-align: center;
            background-color: $primary-color;
            color: $default-white;
            padding: 5px;
            border-radius: 0px 4px 4px 0px;
            cursor: pointer;

            .non_active_filled_heart {
              color: $red;
              width: 20px;
              margin: 0px 3px;
              margin-top: -3px;
            }
          }
          .non-active-dailyFeed-icon {
            color: $primary-color;
            margin: 0px 5px;
            font-size: 17px;
            margin-top: -3px;
          }
        }
      }

      .noDataFoundImg {
        width: 500px;
      }

      .sticky_date {
        padding: 10px;
        border-radius: 10px;
        margin: 8px;
        box-shadow: 0px 0px 5px gray;
        gap: 10px;
        height: 100%;
        display: grid;
        grid-template-columns: 360px 1fr;

        .image_div {
          background-color: #ebe8e8;
          box-shadow: 0px 0px 5px gray;
          border-radius: 5px;
          overflow: hidden;
          height: 245px;

          .video_display {
            width: 100%;
            height: 231px;
            padding: 7px;
          }

          .data_for_mobile_screen {
            display: none;
            justify-content: space-between;
            font-size: 15px;
          }

          .single_image_div {
            box-sizing: border-box;
            position: absolute;
            perspective: 1000px;
            position: relative;
            display: flex;
            margin: auto;
            justify-content: center;
            width: 100%;

            .single_image_sub_div {
              width: 100%;
            }

            .single_image {
              height: 245px;
            }

            .single__image {
              width: 100%;
              object-fit: contain;
              padding: 8px;
              border-radius: 10px;
            }
          }

          .sliding_image_div {
            width: 100%;

            .sliding_image_sub_div {
              box-sizing: border-box;
              position: absolute;
              perspective: 1000px;
              position: relative;
              display: flex;
              margin: auto;
              justify-content: center;

              .container-carosalForAll {
                position: absolute;
                float: right;
                width: 220px;
                transform-style: preserve-3d;
                animation: rotar 25s infinite linear;
                animation-play-state: running;
                padding-top: 35px;

                img {
                  width: 100%;
                  height: 140px;
                  object-fit: cover;
                  overflow: hidden;
                  position: absolute;
                  background-color: white;
                  padding: 4px;
                  border-radius: 6px;
                }
              }

              .carousel_for_five_image div:nth-child(1) {
                transform: rotateY(0deg) translateZ(165px) rotate(0deg);
              }

              .carousel_for_five_image div:nth-child(2) {
                transform: rotateY(72deg) translateZ(165px);
              }

              .carousel_for_five_image div:nth-child(3) {
                transform: rotateY(144deg) translateZ(165px);
              }

              .carousel_for_five_image div:nth-child(4) {
                transform: rotateY(216deg) translateZ(165px);
              }

              .carousel_for_five_image div:nth-child(5) {
                transform: rotateY(288deg) translateZ(165px);
              }

              .carousel_for_four_image div:nth-child(1) {
                transform: rotateY(0deg) translateZ(145px) rotate(0deg);
              }

              .carousel_for_four_image div:nth-child(2) {
                transform: rotateY(90deg) translateZ(145px);
              }

              .carousel_for_four_image div:nth-child(3) {
                transform: rotateY(180deg) translateZ(145px);
              }

              .carousel_for_four_image div:nth-child(4) {
                transform: rotateY(270deg) translateZ(145px);
              }

              .carousel_for_three_images div:nth-child(1) {
                transform: rotateY(0deg) translateZ(145px) rotate(0deg);
              }

              .carousel_for_three_images div:nth-child(2) {
                transform: rotateY(120deg) translateZ(145px);
              }

              .carousel_for_three_images div:nth-child(3) {
                transform: rotateY(240deg) translateZ(145px);
              }

              .carousel_for_two_images div:nth-child(1) {
                transform: rotateY(0deg) translateZ(145px) rotate(0deg);
              }

              .carousel_for_two_images div:nth-child(2) {
                transform: rotateY(180deg) translateZ(145px);
              }
            }
          }
        }

        .subSecForNews {
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 4px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          width: 100%;

          .newsHeading {
            font-size: 22px;
            color: #50b3f6;
          }

          .newsHtml {
            a {
              word-wrap: break-word;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p,
            span {
              font-size: 16px;
              margin-bottom: 2px !important;
            }

            br {
              display: none;
            }
          }

          .newsDetails {
            display: flex;
            justify-content: space-between;

            .sub_labeling_main_div {
              display: flex;
              align-items: center;
              gap: 5px;

              .sub_labeling {
                font-size: 13px;
                display: none;

                .eachLabeling {
                  font-size: 12px;
                  padding: 2px 8px;
                  margin: 1px 2px;
                }
              }

              .read_more_button_with_lock {
                font-size: 13px;
                display: flex;
                gap: 3px;

                .read_more_button_div {
                  width: 24px;
                  background-color: $primary-color;
                  color: $default-white;
                  text-align: center;
                  border-radius: 5px;
                  align-items: center;
                  justify-content: center;
                  display: flex;
                  cursor: pointer;

                  .pi-lock {
                    font-size: 0.9rem;
                  }
                }
              }
            }
            .details {
              display: flex;
              gap: 5px;
              margin: 3px;
              color: gray;
              font-size: 14px;
              align-items: center;

              .icon-container {
                color: $primary-color;
                margin: -3px 5px 0px 0px;
                font-size: 15px;
              }
              .account-icon {
                font-size: $small;
                border-radius: 50%;
                margin-right: 5px;
                color: $primary-color;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 17px;
                height: 17px;
              }

              .calendar-icon-container {
                display: flex;
                align-items: center;
                width: 8.7vw;
              }

              .time-icon-container {
                display: flex;
                align-items: center;
                width: 7vw;
              }

              .visibility-icon-container {
                display: flex;
                align-items: center;
                width: 4.5vw;
              }

              .user-account-icon-container {
                display: flex;
                align-items: center;
                width: 8vw;
              }

              .published_for_div {
                width: 17vw;
                align-items: center;
                gap: 5px;
                display: flex;

                .published_for_sub_div {
                  color: $gray;
                  border: solid 1px $primary-color;
                  border-radius: 5px;
                  display: flex;
                  align-items: center;
                  padding: 1px;
                  text-align: center;
                }
              }
            }
          }
        }
      }

      .fixed_coffee_news_feed {
        width: 100%;
        top: 90px;
        position: sticky;
        background-color: white;
        padding: 2px 30px;
      }

      .mainCards {
        display: flex;

        .details {
          display: flex;
        }

        :root {
          --x1: 0deg;
          --x2: 24deg;
          --x3: 48deg;
          --x4: 72deg;
          --x5: 96deg;
          --x6: 120deg;
          --spacing1: 450px;
          --image-width: 90%;
          --image-height: 120px;
        }

        .maindiv {
          margin: 200px;
        }

        .cardfooterstyle {
          height: 30px;
        }

        .carosal-stop {
          animation: rotar 0s infinite linear;
        }

        @keyframes rotar {
          from {
            transform: rotateY(0deg);
          }

          to {
            transform: rotateY(360deg);
          }
        }
      }

      .rotate-img {
        border: 3px solid #f4f7f8;
        padding: 10px;
        text-align: center;

        .img-fluid {
          vertical-align: middle;
          border-style: none;
          box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
          padding: 5px;
          border: 1px solid #dbdbdb;
          width: 100% !important;
          max-height: 200px !important;
        }
      }

      .video-frame {
        max-width: 400px;
        max-height: 230px;
        border-radius: 3px;
        padding: 10px;
        margin: auto;
        justify-content: center;
      }

      .tabcolor {
        background-color: #ebe8e8 !important;
        color: #61676cfc;
        font-size: 20px;
        font-weight: 600;
        border-radius: 3px 30px 0px 0px !important;
        border-bottom: solid 2px #50b3f6;
      }

      .tabcolor1 {
        background-color: #ebe8e8 !important;
        color: #61676cfc;
        font-size: 20px;
        font-weight: 600;
        border-radius: 30px 3px 0px 0px !important;
        border-bottom: solid 2px #50b3f6;
      }

      .bottom_button {
        display: none;
      }

      .news_media_div {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .static_image {
          width: 96%;
          height: 210px;
          margin: 8px;
          border-radius: 2px;
        }
      }

      .lazy_loading_div {
        display: flex;
        justify-content: center;
        font-size: 24px;
        color: $Black;
        padding: 20px 0px 35px 0px;
      }

      .full_Stop {
        color: $default-white;
        height: 45px;
      }
    }

    .all-data-fetched {
      width: fit-content;
      text-align: center;
      margin: auto;
      border: 1px solid $primary-color;
      background: $primary-color;
      color: $default-white;
      padding: 6px 16px;
      font-size: 18px;
      border-radius: 24px;
      margin-bottom: 36px;
    }

    .no-data-found {
      width: fit-content;
      text-align: center;
      margin: auto;
      border: 1px solid $primary-color;
      background: $primary-color;
      color: $default-white;
      padding: 8px 16px;
      font-size: 18px;
      border-radius: 24px;
      margin-bottom: 36px;
    }
  }

  .cls-1 {
    fill: red;
    fill-rule: evenodd;
  }
}

.search_on_page_load {
  width: 390px;
  margin: auto;

  .no_data_found_image {
    width: 100%;
    padding: 10px;
  }
}

.cursor_pointer {
  cursor: pointer;
}

//Media Queries
@media (max-width: 767.98px) {
  .coffee_news_feeds {
    #forScroll {
      position: fixed;
    }

    .fixed_coffee_news_feed.hidden {
      animation: slide-down-animation-news 0.2s ease-out;
      animation-fill-mode: forwards;
    }

    @keyframes slide-down-animation-news {
      0% {
        transform: translateY(0);
      }

      100% {
        transform: translateY(-100%);
      }
    }

    .fixed_coffee_news_feed.visible {
      animation: down-animation-news 0.2s ease-out;
      animation-fill-mode: forwards;
    }

    @keyframes down-animation-news {
      0% {
        transform: translateY(-100%);
      }

      100% {
        transform: translateY(0);
      }
    }

    .feeds_container {
      max-width: 100%;
      padding-top: 54px;

      &.in-read-more {
        margin-top: 0px;
      }

      .news_feeds_card {
        margin: 5px;

        .tabs_for_news_feed_screen {
          width: 100% !important;
          margin-top: 0;

          .tabs_nav_link {
            width: 100%;
            .tabs_nav_link_sub_div.guest-user-background {
              background-color: $brown-primary-color;
            }

            .tab_daily_feed.guest-mobile-view {
              display: block;
              border: 1px solid $brown-primary-color;
              color: $brown-primary-color;
            }

            .tab_daily_feed.guest-large-view {
              display: none;
            }
          }
          .non_active_nav_link {
            width: 100%;
          }
        }

        .date_tag_design {
          top: 136px !important;
        }

        .date_tag_for_big_screen {
          display: grid;
          justify-content: center;
          grid-template-columns: 1fr auto 1fr;
          color: $default-white;
          align-items: center;
          gap: 10px;
          margin: 15px 0px 10px 0px;
          position: fixed !important;
          left: 0px;
          top: 92px !important;
          z-index: 1;

          .date_tag_horizontal_line {
            height: 2px;
            width: 100%;
            color: $default-white;
            opacity: 0.1;
            border-radius: 5px;
          }

          .date {
            color: white;
            background-color: #50b3f6;
            padding: 5px 20px;
            font-size: 15px;
            border-radius: 20px;
            text-align: center;
            font-weight: 600;
          }
        }

        .stickyLanguageDropDown {
          position: sticky;
          top: 50px;
          background-color: white;
          z-index: 1;
        }

        .headerSec2 {
          width: 100%;

          .search_for_big_screen {
            display: none;
          }
        }

        .language_and_search {
          grid-template-columns: 50% auto;
          width: 100%;
          border: 0px;
          border-radius: 0px;
          margin: 8px 0;
          gap: 8px;
          padding: 0 8px;
          .loading-dropdown {
            width: 100%;
          }
          .guest-loading {
            border: 1px solid $brown-primary-color;
          }

          .buy_subscription_div {
            .buy_subscription_sub_div {
              display: block !important;
              background-color: #50b3f6;
              border-radius: 4px;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              width: 100%;

              .buy_subscription_button {
                text-align: center;
                align-items: center;
                justify-content: center;
                display: flex;
                text-decoration: none;
                height: 100%;
                font-size: 16px;
                width: 100%;
                color: white;
              }
            }

            .buy_subscription_sub_div.guest-user-background {
              background-color: $brown-primary-color;
              height: 42px;
            }
          }

          .small_screen_search_div {
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 50px;
            background-color: #34adff36;
            border-radius: 4px;

            .mobile-screen-search-icon {
              color: $primary-color;
            }
          }
        }

        .fixed_coffee_news_feed {
          width: 100%;
          padding: 0px 5px;

          .coffee_news_title {
            display: none;
          }

          .big_screen_language_dropdown {
            display: none !important;
          }

          .small_screen_language_dropdown {
            display: flex !important;
            border: 1px solid #50b3f6;
            border-radius: 4px;
            cursor: pointer;

            .small_screen_language_div {
              display: flex;
              width: 100%;
              align-items: center;

              .small_screen_language_sub_div {
                display: flex;
                width: 100%;

                .small_screen_language {
                  width: 57px;
                  height: 38px;
                  border-radius: 3px;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  .small_screen_flag_image {
                    margin: 0px 7px;
                    width: 35px;
                    border-radius: 2px;
                    border: 1px solid rgba(0, 17, 51, 0.15);
                  }
                }

                .small_screen_language_text_div {
                  width: 100%;
                  height: 38px;
                  border-radius: 3px;
                  display: flex;
                  margin-left: 4px;
                  align-items: center;

                  .small_screen_language_tex {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }
            }

            .dropdown_arrow_icon {
              margin-right: 2px;
              color: gray;
            }

            .dropdown_arrow_icon.guest-user-text-border {
              color: $brown-primary-color;
            }
          }

          .small_screen_language_dropdown.guest-user-text-border {
            border: 1px solid $brown-primary-color;
            color: $brown-primary-color;
          }
        }

        .noDataFoundImg {
          width: 380px;
        }

        .sticky_date {
          display: flex;
          flex-direction: column;

          .image_div {
            min-width: auto;
            background-color: #ebe8e8;
            width: auto !important;
            height: auto;

            .single_image {
              height: 100% !important;
            }

            .data_for_mobile_screen {
              display: grid !important;
              grid-template-columns: auto 90px;

              .calendar-icon-container {
                display: flex;
                align-items: center;
                .calendar-icon {
                  color: $primary-color;
                  font-size: $medium;
                  margin-top: -3px;
                  margin-right: 5px;
                }
              }

              .time-icon-container {
                display: flex;
                align-items: center;

                .time-icon {
                  color: $primary-color;
                  margin-top: -2px;
                  font-size: $medium;
                  margin-right: 5px;
                }
              }

              .user-account-icon-container {
                display: flex;
                overflow: hidden;
                white-space: nowrap;

                .account_icon {
                  font-size: $small;
                  border-radius: 50%;
                  margin-right: 5px;
                  color: $primary-color;
                  padding: 4px;
                  width: 18px;
                  height: 18px;
                  display: flex;
                  align-items: center;
                }
                .author-text {
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              .visibility-icon-container {
                position: '';
                display: flex;
                justify-content: space-between;

                .view-icon-container {
                  display: flex;
                  align-items: center;
                  .visibility-icon {
                    font-size: $medium;
                    color: $primary-color;
                    margin-right: 5px;
                    margin-top: -2px;
                  }
                }
              }
            }

            .video_div {
              width: 100%;
              height: 100%;
              align-items: center;
              display: flex;
              align-items: center;

              .video_display {
                width: 100%;
                height: 210px;
                padding: 7px;
              }
            }

            .sliding_image_div {
              .sliding_image_sub_div {
                height: 210px;

                .container-carosal {
                  padding-top: 50px;
                }
              }
            }
          }

          .subSecForNews {
            .newsDetails {
              display: flex;
              flex-direction: row;
              justify-content: end;

              .details {
                display: none;
                flex-direction: column;
                gap: 4px;
              }

              .sub_labeling_main_div {
                display: flex;
                flex-direction: row;
                justify-content: space-between !important;
                width: 100%;
                margin: 7px 0px 0px 0px;

                .sub_labeling {
                  justify-content: center;
                  font-size: 13px;
                  display: flex !important;
                  color: $gray;
                  border: solid 1px $primary-color;
                  border-radius: 5px;
                  align-items: center;
                  padding: 1px 2px 1px 2px;

                  .eachLabeling {
                    font-size: 11px;
                    padding: 2px 6px;
                    margin: 1px 2px;
                  }
                }

                .read_more_button_with_lock {
                  font-size: 13px;

                  .read_more_button_div {
                    width: 24px;
                  }
                }

                .heart_pulse_logo_for_read_more {
                  display: none !important;
                }
              }
            }
          }
        }

        .bottom_button {
          display: flex;
          justify-content: space-around;
          background: white;
          border: #50b3f6 solid 1px;
          position: fixed;
          bottom: 0px;
          width: 100%;
          left: 0;
          z-index: 200;
          border-radius: 10px;

          .bottom_button_sub_div {
            display: flex;
            justify-content: center;
            gap: 10px;
            border-right: 1px solid $primary-color;
            width: 15%;
            padding: 10px;
            align-items: center;
            cursor: pointer;

            .bottom_button_icon_div {
              background-color: $default-white;
              border-radius: 4px;
              .home-icon {
                color: $primary-color;
                font-size: $large;
                display: flex;
              }
            }
          }

          .bottom_button_search_bar_div {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            border-left: 1px solid $primary-color;
            width: 85%;
            padding: 10px;
            align-items: center;
            cursor: pointer;

            .search_text {
              color: $primary-color;
            }

            .search_icon_div {
              background-color: $default-white;
              border-radius: 4px;

              .search-icon {
                color: $primary-color;
                font-size: $medium;
              }
            }
          }
        }

        .bottomButtonScroll.slide-down {
          bottom: 0px;
          animation: slide-down-animation-forBackBtn 0.5s ease-out;
          animation-fill-mode: forwards;
        }

        .bottomButtonScroll.down {
          bottom: 1px;
          animation: down-animation-forBackBtn 0.3s ease-out;
          animation-fill-mode: forwards;
        }

        @keyframes slide-down-animation-forBackBtn {
          0% {
            transform: translateY(0);
          }

          100% {
            transform: translateY(100%);
          }
        }

        @keyframes down-animation-forBackBtn {
          0% {
            transform: translateY(100%);
          }

          100% {
            transform: translateY(0);
          }
        }
      }

      .all-data-fetched,
      .no-data-found {
        font-size: 16px;
        margin-bottom: 16px;
      }
    }
  }

  .search_on_page_load {
    width: 350px;
    margin: auto;

    .no_data_found_image {
      width: 100%;
      padding: 10px;
    }
  }
}

.heartLogoBlob {
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2.5s infinite;
}

.zoomAnimation {
  border-radius: 50%;
  margin: 10px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.83);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.83);
  }
}

.skeleton_for_full_screen {
  padding: 0px 9px;
  gap: 15px;
  display: grid;
  grid-template-columns: 360px 1fr;
  margin-bottom: 30px;
  margin: 0px 30px 6px 30px;

  .skeleton_div {
    display: grid;
    grid-template-columns: auto 100px;
    gap: 7px;
  }

  .skeleton_tag {
    width: 100%;
    margin-bottom: 8px;
  }
}

@media (max-width: 767.98px) {
  .skeleton_for_full_screen {
    padding: 0px 9px;
    gap: 10px;
    display: grid;
    grid-template-columns: 40vw auto;
    display: flex;
    flex-direction: column;
    margin: -2px 5px 12px 5px !important;
  }
}

.input {
  padding: 10px;
  width: 120px;
  border: none;
  outline: 1px solid #50b3f6;
  border-radius: 5px;
  font-size: 18px;
  transition: width 0.3s;
  font-family: Consolas, monaco, monospace;
}

.bbbb {
  display: none;
}

.input::placeholder {
  color: blue;
}

.search_popup_for_news {
  position: fixed;
  top: 0px;
  width: 100%;
  background-color: white;
  height: 100vh;
  z-index: 102;

  .app-bar {
    background-color: $primary-color;
    box-shadow: 0px 0px 0px $default-white;
  }

  .toolbar-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .p-toolbar {
    padding: 16px 10px;
    border-radius: 0;
  }

  .coffee-news-feed-heading {
    color: $default-white;
    font-weight: $font-medium;
    font-size: $x-large;
    margin: 0px 25px;
  }

  .arrow-icon {
    color: $default-white;
  }

  .input_field {
    padding: 5px 8px;
    .search-icon {
      color: $primary-color;
      font-size: $medium;
    }

    .mobileView-close-icon {
      color: $primary-color;
    }

    .p-inputgroup {
      width: 100%;
      border: 1px solid $primary-color;
      border-radius: 5px;
    }

    .p-inputgroup .p-input-icon-right {
      position: relative;
      width: 100%;
    }

    .p-inputgroup .p-input-icon-right .pi-search {
      position: absolute;
      right: 10px;
      top: 65%;
      transform: translateY(-50%);
      font-size: $medium;
      color: $primary-color;
      margin-right: 15px;
      cursor: pointer;
    }
    .p-inputgroup .p-input-icon-right .p-input-clear-icon {
      position: absolute;
      right: 5px;
      top: 65%;
      transform: translateY(-50%);
      font-size: $medium;
      color: $primary-color;
      margin-right: 50px;
      cursor: pointer;
    }

    .p-inputtext {
      margin: auto;
      width: 80%;
      height: 58px;
      box-shadow: none;
      border: none;
    }
    .p-inputtext:enabled:focus {
      box-shadow: none !important;
    }
  }

  .divider-style {
    padding: 0px !important;
    margin: 0px !important;
  }

  .search_trending_data {
    height: 85%;
    overflow: scroll;

    .search_trending_data_sub_div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
    }

    .search_trending_data_full_stop {
      color: $default-white;
      background-color: $default-white;
      height: 40px;
      padding: 30px;
    }
    .search-forward-arrow {
      color: $primary-color;
      cursor: pointer;
    }
  }

  .search_trending_data_error {
    color: $red;
    text-align: center;
    margin-left: 0%;
    font-size: 110%;
  }
}

.language_modal {
  .language_modal_title {
    font-size: 20px;
  }

  .language_modal_close_button {
    float: end;
    cursor: pointer;
  }

  .language_modal_body {
    overflow: hidden;
    overflow-y: scroll;
    height: 85vh;

    .language_div {
      display: flex;
      font-size: 20px;
      width: 100%;
      margin: 15px 10px;
      align-items: center;
      gap: 5px;

      .language_sub_div {
        display: flex;
        align-items: center;

        .language_modal_flag_image {
          height: 25px;
          margin: 0px 6px;
          border-radius: 2px;
          border: 1px solid rgba(0, 17, 51, 0.15);
        }
      }
    }
  }
}

.unfavourite_modal {
  .unfavourite_modal_header {
    background-color: $primary-color;

    .unfavourite_modal_header_logo {
      width: 200px;
      margin: auto;
    }
  }

  .unfavourite_modal_body {
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    .unfavourite_modal_body_text {
      font-size: 22px;
      color: $Black;
      padding: 50px 0px;
      color: #343a40e6;
    }
  }

  .unfavourite_modal_footer {
    display: flex;
    gap: 7px;

    .unfavourite_modal_yes_button {
      padding: 5px 20px;
      border: 1px solid $primary-color;
      background-color: $primary-color;
    }

    .unfavourite_modal_no_button {
      padding: 5px 15px;
      border: 1px solid $primary-color;
      background-color: $default-white;
      color: $primary-color;
    }
  }
}

.search_news_not_found {
  display: flex;
  justify-content: center;

  .search_news_not_found_sub_Div {
    cursor: pointer;
    display: flex;
    justify-content: center;
    color: $default-white;
    padding: 3px 12px;
    background-color: $primary-color;
    border-radius: 20px;
    width: 120px;
    letter-spacing: 2px;
  }
}

@media (max-width: 1022px) {
  .coffee_news_feeds .feeds_container .news_feeds_card .sticky_date .subSecForNews .newsDetails {
    display: block;

    .details {
      flex-wrap: wrap;
      align-items: flex-start;

      .calendar-icon-container {
        width: 13.7vw !important;
      }
      .visibility-icon-container {
        width: 7.5vw !important;
      }
      .time-icon-container {
        width: 11vw !important;
      }

      .user-account-icon-container {
        width: 4vw;

        .news-content {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .published_for_div {
        .published_for_sub_div {
          display: none;
        }
      }
    }

    .sub_labeling_main_div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .sub_labeling {
        display: block;
        font-size: 13px;
        color: $gray;
        border: solid 1px $primary-color;
        border-radius: 5px;
        padding: 1px 2px;
      }

      .heart_pulse_logo_for_read_more {
        margin-left: auto;
      }
    }
  }
}

@media (max-width: 1024px) {
  .coffee_news_feeds .feeds_container .news_feeds_card .sticky_date .subSecForNews .newsDetails {
    .details {
      .calendar-icon-container {
        width: 9.7vw;
      }
      .visibility-icon-container {
        width: 5.5vw;
      }
      .time-icon-container {
        width: 8vw;
      }

      .user-account-icon-container {
        width: 4vw;
        .news-content {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .published_for_div {
        width: 0vw;
      }
    }
  }
}
