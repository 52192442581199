@import 'theme.scss';

.coffeequotes_accordian_component {
  cursor: pointer;
}

.terminalDetailsModalPopup {
  width: 100% !important;
  user-select: none;

  .accordian-create-account-info-modal-popup {
    position: fixed;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 14;
  }

  .modal-body {
    padding: $padding-modal;
  }
  .terminal_details_modal_header {
    padding: $padding-modal;

    .terminal_details_modal_title {
      color: $primary-color;
      text-align: left;
      width: 100%;
      font-size: $xx-large;
      font-weight: $font-bold;
    }

    .terminal_details_modal_close_button {
      float: end;
      cursor: pointer;
      margin-top: 5px;
      color: $gray;
      font-size: $xx-large;
    }
    .close-icon {
      font-size: $xx-large;
      color: $gray;
      margin-top: 5px;
      cursor: pointer;
    }
  }

  .terminal_detail_table {
    thead tr {
      background-color: $primary-color;
      color: $White;
      text-align: center;
    }

    tbody tr {
      background-color: $White;
    }

    tbody td {
      text-align: center;
    }

    .table_td {
      font-weight: $font-semibold;
    }

    table {
      width: 100%;
      border: 1px solid $primary-color;
    }
  }

  .terminal_detail_body {
    display: grid;
    height: 65vh;
    max-height: 65vh;
    min-height: 400px;
    grid-template-columns: 60px auto 60px;
    align-items: start;
    margin-top: 10px;

    table {
      width: 100%;
      text-align: center;
      thead {
        border-collapse: collapse;
        top: 0px;
        tr {
          background-color: $primary-color;
          color: $White;
          border: 1px solid $White;
          th {
            outline: 1px solid $White;
            padding: 2px 0px;
          }
        }
      }
      tbody {
        border-collapse: collapse;
        tr {
          &:hover {
            background-color: rgba(0, 0, 0, 0.075);
          }
          td {
            border: 1px solid $light-grey-border !important;
          }
        }
      }
    }
    .slide_1 {
      display: grid;
      grid-template-columns: 1fr;
      gap: 10px;
      align-items: center;
      margin-top: -2px;
      .top-section {
        display: grid;
        grid-template-columns: 20.7% 3fr 25%;
        align-items: center;
        .contract_high_low {
          width: 100%;
          border: 2px solid $primary-color;

          .contract_header {
            background-color: $primary-color;
            color: $White;
            text-align: center;
            margin-bottom: 8px;
            font-weight: $font-semibold;
          }
          .contract_header_tr {
            height: 3px;
          }

          .contract_body {
            text-align: center;
            border: 1px solid $primary-color;
            background-color: $White;
            font-size: 13px;
            font-weight: $font-semibold;
            .weekHigh {
              width: 42%;
              color: $green;
            }
            .weekLow {
              width: 12%;
              color: $red;
            }
            .weekHighValue {
              font-size: 17px;
              color: $green;
            }
            .weekLowValue {
              font-size: 17px;
              color: $red;
            }

            td {
              border: 2px solid $primary-color !important;
              width: 25%;
            }
          }
        }
      }

      .terminal_heading {
        display: flex;
        justify-content: center;
        font-size: 19px;
        margin: 5px 0px;
        font-weight: $font-semibold;
        color: $primary-color;
      }

      .terminal_table {
        border: 1px solid $primary-color;
        border-top: 0px;
        width: 100%;
        overflow: hidden;

        .positive_green {
          color: $green;
        }

        .negative_red {
          color: $red;
        }

        .terminal_table_head {
          display: grid;
          grid-template-columns: 5fr 3fr 3fr 3fr 3fr 3fr 3fr 6fr 3fr 6fr 17px;
          background-color: $primary-color;
          color: $White;

          div {
            padding: 3px 0px;
            display: flex;
            justify-content: center;
            border-right: 1px solid $White;
            border-top: 1px solid $White;
            font-weight: $font-semibold;
          }
        }

        .today_terminal_table_head {
          display: grid;
          grid-template-columns: 5fr 3fr 3fr 3fr 3fr 3fr 3fr 6fr 3fr 6fr 17px;
          background-color: $White;
          margin-top: 4px;
          font-weight: $font-semibold;
          &:hover {
            background-color: rgba(0, 0, 0, 0.075);
          }

          .today_termianal_div {
            font-weight: $font-semibold;
          }

          div {
            padding: 3px 0px;
            display: flex;
            justify-content: center;
            border: 1px solid $primary-color;
          }
        }

        .terminal_table_main_body {
          overflow: scroll;
          overflow-x: hidden !important;
          height: 41vh;

          .terminal_table_body {
            display: grid;
            grid-template-columns: 5fr 3fr 3fr 3fr 3fr 3fr 3fr 6fr 3fr 6fr;
            &:hover {
              background-color: rgba(0, 0, 0, 0.075);
            }

            .date-highlight {
              background: #d6d3d3;
              font-weight: $font-semibold;
            }

            .high-week {
              background: #d6d3d3;
              color: $green;
              font-weight: $font-semibold;
            }

            .low-week {
              background: #d6d3d3;
              color: $red;
              font-weight: $font-semibold;
            }

            div {
              border: 1px solid $primary-color;
              display: flex;
              justify-content: center;
              padding: 3px 0px;
            }
          }

          .positive_green {
            color: $green;
          }

          .negative_red {
            color: $red;
          }
        }

        .terminal_table_main_body::-webkit-scrollbar {
          width: auto;
        }
      }
    }

    .slide_2 {
      display: grid;
      grid-template-columns: 4fr 5fr;
      gap: 15px;
      height: fit-content;
      align-items: center;
      height: 100%;

      .terminal_difference_history_wrapper {
        width: 100%;
        max-height: 62vh;
        overflow-x: auto;
        border-bottom: 1px solid $light-grey-border;
        table {
          thead {
            position: sticky;
            top: -1px;
          }
        }
      }

      .difference_table {
        border-collapse: collapse;
        height: fit-content;
        thead {
          tr {
            th {
              color: $White;
            }
          }
        }

        td,
        th {
          text-align: center;
        }

        .positive_green {
          color: $green;
          font-weight: $font-semibold;
          border: 1px solid $primary-color !important;
        }

        .negative_red {
          color: $red;
          font-weight: $font-semibold;
          border: 1px solid $primary-color !important;
        }

        .idle_color {
          border: 1px solid $primary-color !important;
        }

        .difference_table_head {
          color: $White;
          text-align: center;
          background-color: $primary-color;
          margin-bottom: 8px;
          font-weight: $font-semibold;
          .last_column {
            border-right: 1px solid $White !important;
            border-left: 1px solid $White !important;
            width: 30%;
          }
        }

        .table_tr {
          height: 2px;
        }

        .difference_table_body {
          .differential_column {
            font-size: $large;
            border: 1px solid $primary-color !important;
          }
          .hoverable-row-terminal-difference {
            background-color: rgba(0, 0, 0, 0.075);
          }

          .difference_table_tr {
            margin-bottom: 5px;
          }

          .difference_table_td {
            font-weight: $font-normal;
          }

          .difference_table_tr_height {
            height: 6px;
          }

          .empty-divider {
            background-color: $primary-color;
            td {
              height: 1px;
              background-color: $primary-color;
              border: 1px solid $primary-color !important;
            }
          }
          .no-data-available {
            text-align: center;
            .message {
              padding: 20px 30px;
            }
          }
        }
      }
    }

    .slide_3 {
      display: grid;
      grid-template-columns: 3fr 4fr;
      gap: 10px;
      align-items: center;
      .arbritage_data_wrapper {
        tbody {
          font-weight: $font-semibold;
        }
      }
      .arbritage_history_data_wrapper {
        width: 100%;
        max-height: 62vh;
        overflow-x: auto;
        border-bottom: 1px solid $light-grey-border;

        table {
          thead {
            position: sticky;
            top: -1px;
          }
        }
      }
      .reference-value {
        font-size: 12px;
        font-weight: $font-normal;
        margin-left: 5px;
      }
    }

    .candlestick-slide-wrapper {
      .candlestick-slide-52weekHighLow-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        align-items: center;
        .contract_high_low {
          width: 230px;
          border: 2px solid $primary-color;

          .contract_header {
            background-color: $primary-color;
            color: $White;
            text-align: center;
            margin-bottom: 8px;
            font-weight: $font-semibold;
          }
          .contract_header_tr {
            height: 3px;
          }

          .contract_body {
            text-align: center;
            border: 1px solid $primary-color;
            background-color: $White;
            font-size: 16px;
            font-weight: $font-semibold;
            .weekHigh {
              color: $green;
            }
            .weekLow {
              color: $red;
            }
            .weekHighValue {
              color: $green;
            }
            .weekLowValue {
              color: $red;
            }

            td {
              border: 2px solid $primary-color !important;
              width: 25%;
            }
          }
        }
        .contract-name {
          font-size: 18px;
          font-weight: 600;
        }
        .auth-screen-button {
          border: 1px solid $White;
          font-size: 16px;
          padding: 8px 15px;
          border-radius: 4px;
          color: $White;
          cursor: pointer;
          margin: auto;
          text-align: center;
          width: 210px;
          display: inline-block;
          font-weight: 600;
          background: linear-gradient(-45deg, #e73c7e, #23a6d5);
        }
      }
    }

    .arrows {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      cursor: pointer;
      height: 100%;

      .angle-double-icon {
        animation: angle-double-icon-keyframe 1.5s infinite;
        cursor: pointer;
        color: $primary-color;
        font-size: $xx-large;
      }

      @keyframes angle-double-icon-keyframe {
        0% {
          margin-left: 20px;
        }

        50% {
          margin-left: 0px;
        }

        100% {
          margin-left: 20px;
        }
      }
    }
  }

  .modal-dialog {
    width: 80vw !important;
    max-width: 100vw !important;
    min-width: 80vw !important;
  }
}

@media (max-width: 1360px) {
  .terminalDetailsModalPopup {
    font-size: 13px;

    .terminal_detail_body {
      grid-template-columns: 50px 1fr 50px;
      .candlestick-slide-wrapper {
        .candlestick-slide-52weekHighLow-wrapper {
          .contract_high_low {
            width: 160px;

            .contract_body {
              font-size: 12px;
            }
          }
          .contract-name {
            font-size: 15px;
          }
          .auth-screen-button {
            font-size: 14px;
            padding: 8px 10px;
            width: 185px;
          }
        }
      }
    }

    .difference_table_body {
      .differential_column {
        font-size: $medium !important;
      }
    }
  }
}

@media (max-width: 1024px) {
  .terminalDetailsModalPopup {
    .modal-content {
      width: 120% !important;
      left: -10% !important;
    }
  }
}
