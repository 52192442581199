@import 'theme.scss';

textarea {
  resize: none !important;
  outline: unset !important;
}

.global_differentials_screens {
  margin-top: 90px;
  .p-dialog-content {
    overflow-y: hidden;
  }

  .global_differentials_headers {
    position: fixed;
    background-color: $White;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 12px 15px 2px 15px;
    align-items: center;
    z-index: 2;
    .title {
      font-size: 25px;
      font-weight: 600;
    }

    .dropdown {
      display: grid;
      align-items: center;
      font-size: 18px;
      gap: 5px;
      grid-template-columns: auto 1fr;
      font-weight: 600;

      span {
        width: 100%;
        border-radius: 3px;
      }
    }
  }

  .global_differentials_body {
    padding-top: 48px;
    padding-bottom: 25px;
    user-select: none;

    .update-header {
      padding: 2px;
      width: fit-content;
      margin: auto;
      font-size: 15px;
    }

    .differential_table {
      padding: 0px 15px;

      .table_data {
        margin: 15px 0px;
        .year-mobile-view {
          display: none !important;
          background-color: $primary-color;
          color: white;
          font-weight: 600;
          border-radius: 4px;
          border-right: 1px solid #dee2e6;
          border-left: 1px solid #dee2e6;
          padding: 5px 14px;
          margin-bottom: 5px;
          align-items: center;
          display: flex;
          justify-content: space-between;

          .flag_and_cropyear {
            display: flex;
            gap: 20px;
            align-items: center;
            width: 45%;

            .crop_year_flag {
              font-size: 20px;
              display: flex;
              align-items: center;
              gap: 3px;

              img {
                width: 40px;
                border-radius: 3px;
                border: 1px solid rgba(0, 17, 51, 0.15);
                height: 28px;
                object-fit: cover;
                margin-right: 3px;
              }
            }
          }

          .year-date-wrapper {
            width: 55%;
            display: flex;
            justify-content: space-between;

            .updated_on {
              margin-left: -110px;
            }
          }
        }

        .year-web-view {
          background-color: $primary-color;
          color: white;
          font-weight: 600;
          border-radius: 0px;
          border-right: 1px solid #dee2e6;
          border-left: 1px solid #dee2e6;
          padding: 2px 12px;
          margin-bottom: 5px;
          align-items: center;
          display: flex;
          justify-content: space-between;
          position: sticky;
          top: 146px;
          z-index: 1;

          .flag_and_cropyear {
            width: 100%;
            justify-content: space-between;
            align-items: center;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            .forex-type-and-value {
              text-align: end;
            }
            .crop_year_flag {
              font-size: 20px;
              display: flex;
              align-items: center;
              gap: 3px;
              width: 100%;

              .country-name-and-flag {
                display: flex;
                justify-content: start;
                align-items: center;
                width: 10%;
              }
              img {
                width: 40px;
                border-radius: 3px;
                border: 1px solid rgba(0, 17, 51, 0.15);
                height: 28px;
                object-fit: cover;
                margin-right: 3px;
              }

              .cropyear {
                font-size: 16px;
                text-align: center;
                width: 90%;
              }
            }
          }
        }
      }

      .each_table {
        width: 100%;
        margin-bottom: 20px;

        .differential_table_header {
          .blue-border {
            border: 1px solid $primary-color !important;
            background-color: rgb(233 241 249);
          }
          .quality-th-cell {
            width: 28vw;
          }

          .screen-th-cell {
            width: 8vw;
          }
          .serial-column {
            width: 3%;
          }
          .differetial-between {
            width: 18vw;
          }
          .td {
            width: 9vw;
          }

          .delivery_port {
            width: 30vw;
          }

          tr,
          th,
          td {
            border: 1px solid lightblue;
            text-align: center;
            color: $Black;
            padding: 4px 0px;
          }
          th {
            padding: 2px 0px;
          }
          tr {
            background-color: $default-white;
            &:hover {
              background-color: rgba(0, 0, 0, 0.075) !important;
            }
          }
        }

        .differential_table_body {
          border: 1px solid $primary-color;

          tr,
          th,
          td {
            border: 1px solid lightblue;
            text-align: center;
            padding: 4px 0px;
          }
          tr:hover {
            background-color: rgba(0, 0, 0, 0.075);
            .fob-cell {
              background-color: $White;
            }
          }

          .range-starts {
            width: 10vw;
            font-weight: 600;
          }
          .range-ends {
            width: 10vw;
            font-weight: 600;
          }

          .currency-and-value {
            display: grid;
            grid-template-columns: 1fr 1.2fr;
            gap: 1px;
            .symbol {
              text-align: end;
              margin-right: 2px;
              &.cent-symbol {
                margin-top: -1px;
              }
            }
            .value {
              text-align: start;
            }
          }

          .quality-group-name-td {
            cursor: pointer;
            text-decoration: underline;
            width: 25vw;
            div {
              display: grid;
              grid-template-columns: 1fr;
              align-items: center;
              position: relative;
              p {
                width: 250px;
                margin: 0px auto;
                text-wrap: wrap;
              }
            }
            &:hover {
              color: blue;
              .popup-icon {
                opacity: 1;
              }
            }

            .popup-icon {
              height: 16px;
              width: 16px;
              opacity: 0.5;
              position: absolute;
              right: 22px;
            }
          }
        }
      }
    }

    .no-data-found {
      width: 390px;
      margin: auto;

      .no-data-found-image {
        width: 100%;
        padding: 10px;
      }
    }
    .all-data-fetched {
      width: fit-content;
      text-align: center;
      margin: auto;
      border: 1px solid $primary-color;
      background: $primary-color;
      color: $default-white;
      padding: 6px 16px;
      font-size: 18px;
      border-radius: 24px;
      margin-bottom: 36px;
    }
  }
  .differential-create-account-info-modal-popup {
    position: fixed;
    width: 100%;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 14;
  }
  .create-account-info-message .info {
    color: $platinum-default-color;
  }

  .guest-create-account-info-message .info {
    color: $primary-color;
  }
  .p-toast .p-toast-message .p-toast-icon-close {
    background-color: transparent;
    color: #ff5757;
  }
}

@media (min-width: 820px) and (max-width: 1366px) {
  .global_differentials_screens {
    .flag_and_cropyear {
      .crop_year_flag {
        .country-name-and-flag {
          width: 30% !important;

          img {
            width: 30px !important;
          }
        }
        span {
          margin-right: 15px !important;
          font-size: 15px;
        }
        .cropyear {
          width: 40% !important;
          text-wrap: balance;
          margin: 0px 10px;
          font-size: 15px !important;
        }
      }
    }
  }
}

@media (max-width: 968px) {
  .global_differentials_screens {
    .flag_and_cropyear {
      .crop_year_flag {
        .country-name-and-flag {
          width: 30% !important;

          img {
            width: 30px !important;
          }
        }
        span {
          margin-right: 15px !important;
          font-size: 15px;
        }
        .cropyear {
          width: 60% !important;
          text-wrap: balance;
          margin: 0px 20px;
          font-size: 15px !important;
        }
      }
    }
    .differential_table_body {
      .quality-group-name-td {
        .quality-div {
          justify-content: center;
          text-align: center;
          .quality-name {
            max-width: 160px;
          }
          .popup-icon {
            right: 10px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .global_differentials_screens {
    margin-top: 50px;
    display: flex;
    flex-direction: column;

    .p-toast {
      right: 0px !important;
      padding: 20px !important;
      max-width: -webkit-fill-available;
    }

    .global_differentials_headers {
      display: flex;
      justify-content: flex-end;
      padding: 12px 10px 10px 10px;

      .title {
        display: none;
      }

      .dropdown {
        font-size: 16px;
      }
    }

    .global_differentials_body {
      .differential_table {
        padding: 0px 10px;
        .table_data {
          .year-mobile-view {
            display: block !important;
            flex-direction: column;
            font-weight: 500;
            position: sticky;
            top: 115px;
            z-index: 1;

            .flag_and_cropyear {
              font-size: 16px;
              width: 100%;
              display: flex;
              justify-content: space-between;
              padding: 2px 0;

              .crop_year_flag {
                font-size: 16px;
              }
            }

            .year-date-wrapper {
              width: 100%;
              display: flex;
              justify-content: space-between;
              font-size: 14px;
            }
          }

          .year-web-view {
            display: none !important;
          }

          .each_table {
            display: none;
          }
        }
      }
    }

    .differentials_entry_section {
      margin-bottom: 3rem;

      .form-control {
        width: inherit !important;
      }

      .differential_btns {
        text-align: start;

        .btn {
          width: 100px;
          margin: 0px 10px 20px 0px;
        }
      }
    }
  }
}
