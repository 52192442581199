@import 'theme.scss';

.notification_news_container {
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  margin-top: 90px;
  padding: 2px 5px;
  z-index: 2;
  user-select: none;
  position: fixed;
  top: 0px;
  width: 100vw;
  background-color: $default-white;

  .notification_news {
    margin: auto;
    justify-content: space-around;
    display: grid;
    grid-template-columns: 2fr 7fr;
    height: 100%;
    gap: 10px;

    span,
    a {
      word-wrap: break-word;
    }

    .notification_news_image_container {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .sticky_buttons {
        position: -webkit-sticky;
        position: sticky;
        top: 90px;
        background-color: $default-white;
        padding: 4px 0px;
        display: flex;
        gap: 3px;
        z-index: 2;
        width: 100%;

        .back_button {
          width: 50%;
          .soft-action {
            display: grid;
            grid-template-columns: auto auto;
            justify-content: flex-start;
            gap: 7px;
            .go-back-arrow-icon {
              background-color: $primary-color;
              color: $default-white;
              border-radius: 5px;
              padding: 4px;
            }
          }
        }

        .share_button {
          width: 50%;
          .hard-action {
            display: grid;
            grid-template-columns: auto auto;
            justify-content: flex-start;
            gap: 7px;
          }
          .share-icon {
            font-size: $x-large;
          }
        }
      }

      .notification_media_assets {
        width: 100%;
        border-radius: 10px;
        padding: 2px;
      }

      .images_div {
        height: calc(100vh - 152px);
        overflow: scroll;
        padding: 10px;
        border-radius: 4px;
        box-shadow: 0px 0px 5px gray;
        cursor: pointer;
      }

      .images_div::-webkit-scrollbar {
        width: 0px;
      }
    }

    .notification_news_content_container::-webkit-scrollbar {
      width: 0px;
    }

    .notification_news_content_container {
      padding: 1px;
      overflow: scroll;
      height: 100vh;

      .sticky_buttons_mobile_size {
        display: none;
      }

      .sticky_banner {
        position: sticky;
        top: 0px;
        width: 99%;
        padding: 3px 10px;
        font-size: 20px;
        z-index: 2;
        background: $default-white;
        border-radius: 10px;
        box-shadow: 0px 0px 5px gray;
        gap: 3px;
        display: flex;
        flex-direction: column;

        .banner_icons {
          display: grid;
          grid-template-columns: auto 90px !important;
          justify-content: space-between;
          font-size: 16px;
          pad: 0px 3px;
          margin-top: 3px;

          .icon-container {
            font-size: 14px;

            .social-feed-icons {
              color: $primary-color;
              width: 20px;
              margin-top: -3px;
            }
            .calendar-clock-icons {
              color: $primary-color;
              width: 20px;
              margin-top: -3px;
              margin-right: 3px;
            }
            .account-icon-container {
              display: flex;
              white-space: nowrap;
            }
            .account-icon {
              color: $primary-color;
              width: 20px;
              margin-top: -4px;
              margin-right: 3px;
            }
            .user-account-icon {
              font-size: $small;
              border-radius: 50%;
              margin-right: 5px;
              color: $primary-color;
              width: 18px;
              height: 18px;
            }
            .author-text {
              overflow: hidden;
              text-overflow: ellipsis;
              margin-top: -3px;
            }
          }

          .visibility_icon_and_like_button {
            display: flex;
            justify-content: space-between;

            .visibility_icon {
              display: flex;
              align-items: center;

              .social-feed-icons {
                color: $primary-color;
                margin-right: 3px;
              }
              .view-icon {
                margin-right: 8px;
                color: $primary-color;
                font-size: $large;
              }
            }

            .button_pointer {
              cursor: pointer;
            }
          }
        }
      }

      .banner_image {
        background-size: cover;
        width: 100%;
        height: auto;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        text-align: center;
        font-size: 25px;
        color: $primary-color;
        font-weight: 600;
      }

      .image_carousel {
        display: none;

        span {
          width: 10px !important;
          height: 10px !important;
        }
      }

      .pdf_view_document_button {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        padding-right: 10px;
      }
    }
  }
}

.content_view {
  margin-top: 10px;
  margin-bottom: 100px;
  width: 99%;
}

.news_html_for_height {
  height: 45px;
  color: $default-white;
}
.content_view::-webkit-scrollbar {
  width: 0px;
}

.heart_logo_blob {
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2.5s infinite;
}

.zoomAnimation {
  border-radius: 50%;
  margin: 10px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.83);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.83);
  }
}

@media (max-width: 767.98px) {
  .notification_news_container {
    margin-top: 50px;
    padding: 0 5px;
    z-index: 201;

    .account-icon-container {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .notification_news {
      margin: auto;
      justify-content: space-around;
      display: grid;
      grid-template-columns: 7fr;
      height: 100%;
      gap: 10px;

      .notification_news_image_container {
        display: none;
      }

      .notification_news_content_container {
        height: 100vh;
        overflow: scroll;
        padding: 1px 5px;

        .scrollBackBtn.slide-down {
          animation: slide-down-animation-back 0.2s ease-out;
          animation-fill-mode: forwards;
        }

        @keyframes slide-down-animation-back {
          0% {
            transform: translateY(0);
          }

          100% {
            transform: translateY(100%);
          }
        }

        .scrollBackBtn.down {
          animation: down-animation-back 0.2s ease-out;
          animation-fill-mode: forwards;
        }

        @keyframes down-animation-back {
          0% {
            transform: translateY(100%);
          }

          100% {
            transform: translateY(0);
          }
        }

        .sticky_buttons_mobile_size {
          background-color: $default-white;
          bottom: -4px;
          display: flex;
          gap: 3px;
          padding: 12px 0;
          position: -webkit-sticky;
          position: fixed;
          width: 95%;
          z-index: 205 !important;

          .back_button {
            width: 50%;
            .soft-action {
              display: grid;
              grid-template-columns: auto auto;
              justify-content: flex-start;
              gap: 7px;
              .go-back-arrow-icon {
                background-color: $primary-color;
                color: $default-white;
                border-radius: 5px;
                padding: 4px;
              }
            }
          }

          .share_button {
            width: 50%;
            .hard-action {
              display: grid;
              grid-template-columns: auto auto;
              justify-content: flex-start;
              gap: 7px;
            }
            .share-icon {
              font-size: $large;
            }
          }
        }

        .sticky_banner {
          top: 0px;
          position: static;
          width: 100% !important;
          margin-top: 10px;
        }

        .image_carousel {
          position: relative;
          z-index: 1;
          padding: 10px 3px 0px;
          display: block;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .pdf_view_document_button {
          margin-top: 10px;
        }
      }
    }
  }
  .content_view {
    margin-top: 0px;
    margin-bottom: 100px;

    img {
      width: 100%;
    }
  }
}
