@import 'theme.scss';

.notification_screen_container {
  margin-top: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;

  .notification_screen {
    width: 850px;
    margin-bottom: 38px;
    border: solid 1px rgb(211, 211, 211);
    border-top: 0;
    border-radius: 3px;

    .no-notification {
      color: $gray;
      padding: 30px 20px;
      margin: auto;
      display: flex;
      justify-content: center;
      font-size: 18px;
    }

    .screen_heading {
      display: flex;
      justify-content: space-between;
      font-size: 28px;
      padding: 10px 20px;
      border-radius: 2px 2px 0px 0px;
      color: $primary-color;
      position: sticky;
      top: 90px;
      background-color: $default-white;
      border-bottom: 1px solid rgb(211, 211, 211);
      border-top: 1px solid rgb(211, 211, 211);
      z-index: 2;
      align-items: flex-end;

      .notification-mark-as-read {
        font-size: 18px;
        cursor: pointer;
        display: flex;
        gap: 12px;
      }
    }

    .notification_message_store {
      .each_message_highlight {
        padding: 10px 20px;
        color: grey;
        border-bottom: solid 1px rgb(211, 211, 211);

        .heading-checkbox-wrapper {
          display: flex;
          align-items: center;
          gap: 1%;

          .message_title {
            cursor: pointer;
            font-size: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        .message_arrived_time {
          cursor: pointer;
          color: gray;
          font-size: 12px;
          width: 96%;
        }

        .p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
          border-color: $primary-color;
        }

        .p-checkbox.p-highlight .p-checkbox-box {
          border-color: $primary-color;
          background: $primary-color;
        }
      }

      .each_message_highlight:hover {
        background-color: #e5e5e5;
      }

      #unread {
        background-color: #f1f1f1;
        color: rgb(46, 46, 46);
        border-left: 4px solid $primary-color;
      }

      #unread:hover {
        background-color: #e5e5e5;
        border-left: 6px solid $primary-color;
      }

      .notification_not_found {
        width: 360px;
        margin: auto;

        .no_image_found {
          width: 100%;
          padding: 10px;
        }
      }
    }

    .custom-spinner-loader {
      text-align: center;
      padding: 8px;

      .loader-spinner {
        border: 4px solid rgba(0, 0, 0, 0.1);
        border-top: 4px solid #50b3f6;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin-keyframe 1s linear infinite;
        margin: 0 auto;
      }

      .labeling {
        color: gray;
        margin-top: 5px;
      }
    }

    @keyframes spin-keyframe {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }

  .mark-as-read-wrapper {
    background-color: $default-white;
    width: 850px;
    position: fixed;
    top: 90px;
    z-index: 3;
    padding: 8px;

    .mark-as-read-button {
      background-color: #c1e3f7;
      color: $primary-color;
      width: 650px;
      padding: 10px;
      height: 44px;
      border-radius: 5px;
      margin: 0px 10px 10px 10px;
      text-align: center;
      cursor: pointer;
      margin: auto;
    }
  }
}

.no-more-notifications-message {
  width: fit-content;
  text-align: center;
  margin: -28px auto;
  border: 1px solid $primary-color;
  background: $primary-color;
  color: $default-white;
  padding: 6px 16px;
  font-size: 18px;
  border-radius: 24px;
  position: relative;
  margin-bottom: 40px;
}

@media (max-width: 767.98px) {
  .notification_screen_container {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .notification_screen {
      width: 95%;
      margin-bottom: 45px;

      .screen_heading {
        font-size: 25px;
        padding: 8px 10px;
        border-radius: 5px 5px 0px 0px;
        color: $primary-color;
        position: sticky;
        top: 45px;
        background-color: white;
        border-bottom: 1px solid rgb(211, 211, 211);
      }

      .no-notification {
        padding: 30px 100px;
      }

      .notification_message_store {
        .each_message_highlight {
          padding: 5px 10px;

          .heading-checkbox-wrapper {
            .message_title {
              font-size: 16px;
              width: 100%;
              display: block;
              white-space: nowrap;
            }
          }

          .message_arrived_time {
            color: gray;
            font-size: 12px;
          }
        }

        .eachMessage:hover {
          background-color: #e5e5e5 !important;
        }

        #unread {
          background-color: #f1f1f1 !important;
          color: rgb(46, 46, 46);
          border-left: 4px solid $primary-color;
        }

        #unread:hover {
          background-color: #e5e5e5 !important;
          border-left: 6px solid $primary-color !important;
        }
      }
    }

    .mark-as-read-button {
      width: 275px;
    }

    .mark-as-read-wrapper {
      background-color: $default-white;
      width: 100%;
      top: 50px;
      z-index: 3;
      padding: 8px;

      .mark-as-read-button {
        width: 70%;
      }
    }
  }

  .no-more-notifications-message {
    width: fit-content;
    text-align: center;
    margin: -28px auto;
    border: 1px solid $primary-color;
    background: $primary-color;
    color: $default-white;
    padding: 6px 16px;
    font-size: 18px;
    border-radius: 24px;
    position: relative;
    margin-bottom: 16px;
  }
}

@media (min-width: $device-small) and (max-width: $device-medium) {
  .notification_screen_container {
    .notification_screen {
      width: 90%;
    }
  }
}
