@import 'theme.scss';

.share-news-feed {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 30px;
  .share-news-feed-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid $light-grey-border;
    border-radius: 10%;
    width: 100px;
    height: 92px;
    .icon-container {
      cursor: pointer;
      .whatsapp-icon {
        font-size: 28px;
        color: #25d366;
      }
      .copy-icon {
        font-size: 28px;
        color: $gray;
      }
    }
    .icons-text {
      font-size: $medium;
      color: $Black;
      margin-top: 10px;
      text-align: center;
      margin-bottom: -5px;
    }
  }
  .copy-tooltip {
    font-size: $medium;
    padding: 5px;
    color: $White;
    border-radius: 3px;
  }
}
.copy-tooltip.p-tooltip {
  position: fixed;
}

@media (max-width: $device-small) {
  .share-news-feed-container {
    grid-template-columns: repeat(3, auto);
  }
}
