@import 'theme.scss';

.footer {
  background: #f2f1f1 !important;
  position: fixed;
  bottom: -1px;
  width: 100%;
  font-size: 14px;
  z-index: 199;
  color: gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 10px;

  &.center-content {
    justify-content: center;
    flex-direction: column;
  }

  .left-side-icons,
  .right-side-icons {
    display: flex;
    gap: 10px;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    min-width: 0;
  }

  .social-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon-container {
    width: 32px;
    height: 32px;
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: all 0.3s ease;
    cursor: pointer;

    svg {
      height: 32px;

      path {
        fill: $White;
        transition: fill 0.3s ease;
      }
    }
  }

  .quora {
    background-color: #b92b27;
    border-color: #b92b27;
  }

  .tumblr {
    background-color: #001935;
    border-color: #001935;
  }

  .telegram {
    background-color: #0088cc;
    border-color: #0088cc;
  }

  .medium {
    background-color: #121212;
    border-color: #121212;
  }

  .pinterest {
    background-color: #bd081c;
    border-color: #bd081c;
  }

  .thread {
    background-color: #121212;
    border-color: #121212;
  }

  .facebook {
    background-color: #1877f2;
    border-color: #1877f2;
  }

  .instagram {
    background: linear-gradient(45deg, #feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5);
    border: none;
  }

  .whatsapp {
    background-color: #25d366;
    border-color: #25d366;
  }

  .youtube {
    background-color: #ff0000;
    border-color: #ff0000;
  }

  .twitter {
    background-color: #000000;
    border-color: #000000;
  }

  .linkedin {
    background-color: #0077b5;
    border-color: #0077b5;
  }

  .text-container {
    text-align: center;
  }

  .terms_and_condition {
    display: inline-flex;
    gap: 3px;
    justify-content: center;
    margin-left: 4px;
  }

  a {
    color: blue;
    text-decoration: underline;
  }

  .version-number {
    position: absolute;
    right: 1px;
    bottom: 0px;
    font-size: 9px;
  }

  &.slide-down {
    animation: slide-down-animation 0.2s ease-out;
    animation-fill-mode: forwards;
  }

  &.down {
    animation: down-animation 0.2s ease-out;
    animation-fill-mode: forwards;
  }
}

@keyframes slide-down-animation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

@keyframes down-animation {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  .footer {
    font-size: 12px;
    bottom: 0px;
    .icon-container {
      width: 28px;
      height: 28px;
    }

    .text-container {
      padding: 0 2px;
    }

    .left-side-icons,
    .right-side-icons {
      gap: 7px;
    }
    .version-number {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .footer {
    font-size: 9px;
    bottom: -1px;
    .text-container {
      width: 100%;
      margin: auto;
    }

    .left-side-icons,
    .right-side-icons {
      display: none;
    }
  }
}
