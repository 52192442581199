.subscription-main-container {
  height: calc(100vh - 180px);
  margin-bottom: 60px;
  padding-top: 25px;
  -webkit-user-select: none;
  user-select: none;

  .p-carousel-prev,
  .p-carousel-next {
    display: none;
  }

  .p-carousel-next:after,
  .p-carousel-prev:after {
    display: none;
  }

  .p-carousel-items-content {
    padding: 0px 90px;
  }
  .p-carousel-item {
    visibility: visible;
  }

  .p-carousel-indicators {
    position: relative;
    display: flex;
    .p-link {
      display: none;
    }
    .p-carousel-indicator {
      width: 8px;
      height: 8px;
      background-color: #ccc;
      border-radius: 50%;
      margin: 0 4px;
      transition: background-color 0.3s;

      &.p-highlight {
        background-color: #007aff;
      }
    }
  }
}
@media (min-width: 1000px) and (max-width: 1199px) {
  .subscription-main-container {
    .p-carousel-items-content {
      padding: 0px 280px;
    }
  }
}
@media (min-width: 767.98px) and (max-width: 1000px) {
  .subscription-main-container {
    .p-carousel-items-content {
      padding: 0px 200px;
    }
  }
}

@media (min-width: 555px) and (max-width: 767.98px) {
  .subscription-main-container {
    padding-top: 50px;
    .p-carousel-items-content {
      padding: 0px 180px;
    }
  }
}
@media (max-width: 555px) {
  .subscription-main-container {
    padding-top: 50px;
    .p-carousel-items-content {
      padding: 0px 15px;
    }
  }
}
