@import 'theme.scss';

.custom-modal {
  width: 450px;
  text-align: center;
  background-color: $White;
  overflow: hidden;

  .p-dialog-footer {
    border-top: 1px solid #e0e0e0;
    padding: $padding-modal;
  }
  .p-button {
    &:focus {
      box-shadow: none;
      outline: none;
    }

    &:active {
      box-shadow:
        0 0 0 2px $White,
        0 0 0 4px $button-shadow-primary-color,
        0 1px 2px 0 $grey-font-color-on-hover;
    }
  }
  .p-dialog-header {
    padding: $padding-modal;
  }

  .p-dialog-content {
    padding: $padding-modal-body;
    min-height: 85px;
  }

  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-title {
      font-size: $xx-large;
      font-weight: $fw-bolder;
      text-align: center;
      flex-grow: 1;
      &.success {
        color: $green;
      }
      &.error {
        color: $red;
      }
      &.confirmation {
        color: $primary-color;
      }
    }

    .header-close-button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 24px;
      .close-button-wrapper {
        display: flex;
      }
      .close-icon {
        font-size: $xx-large;
        color: $gray;
      }
    }
  }

  .modal-buttons-wrapper {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
  }
  .modal-message-wrapper {
    .modal-message-text {
      text-align: center;
      color: $gray;
      font-size: $large;

      &.error-title-message {
        font-size: $xx-large;
        color: $red;
      }
      &.success-title-message {
        font-size: $xx-large;
        color: $green;
      }
      p {
        margin-bottom: 2px;
      }
    }
    .modal-logo {
      text-align: center;
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
    }

    .modal-icon {
      max-width: 50px;
      height: auto;
    }
  }
}

@media (max-width: 767px) {
  .custom-modal {
    width: 90%;

    .header-content {
      .header-close-button {
        .close-button-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          top: -24px;
          right: -36px;
          background: $White;
          border-radius: 50%;
          height: 36px;
          width: 37px;
        }
        .close-icon {
          font-size: $xxx-large;
          background: $White;
          position: fixed;
          border-radius: 50%;
        }
      }
    }
  }
}
