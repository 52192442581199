@import 'theme.scss';

.port-report-content {
  .port-report-header {
    display: grid;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    grid-template-columns: 1fr 3fr 1fr;
    background-color: $primary-color;
    margin-bottom: 4px;
    border-radius: 4px;
    user-select: none;

    .title-cell {
      font-weight: $font-bold;
      font-size: medium;
      color: $White;
      text-transform: uppercase;
      text-align: center;
    }
    .swap-content-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      gap: 4px;
      min-width: 160px;
      font-weight: $font-bold;
      color: $White;
      text-transform: uppercase;

      .swap-button {
        display: flex;
        flex-direction: column;

        .upper-arrow {
          font-size: $x-small;
          font-weight: $font-bold;
        }

        .down-arrow {
          font-size: $x-small;
          font-weight: $font-bold;
        }
      }
    }
  }

  .horizontal-scroll-container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    gap: 40px;
    background-color: $White;
    padding: 1px;
    scrollbar-color: #a8a8a8 #f1f1f1;

    user-select: none;

    .table-wrapper {
      padding-left: 0;
      box-sizing: border-box;
      height: calc(100vh - 261px);
      &.robusta-table-wrapper {
        min-width: 50%;
      }
      &.arabica-table-wrapper {
        min-width: 80%;
      }

      table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;

        thead {
          position: sticky;
          top: 0;
          z-index: 1;

          tr {
            th {
              padding: 2px;
              background-color: #e9f1f9;
              outline: 1px solid $primary-color;
              position: relative;
            }
            &.port-name-wrapper {
              th {
                background-color: $White !important;
                .port-name {
                  border-radius: 4px;
                  color: $Black;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 6px;
                  .flag-icon {
                    border: 1px solid #00113326;
                    height: 18px;
                    width: 24px;
                    border-radius: 3px;
                    object-fit: cover;
                  }
                }
                .port-type,
                .country-type {
                  font-weight: 400;
                  text-transform: uppercase;
                }
              }
            }

            .report-date,
            .certified-stocks,
            .compare-to-pre-day,
            .non-tenderable,
            .suspended {
              padding: 2px;
              width: 13%;
            }

            .country-header {
              background-color: #f3f3f3;
              color: $Black;
            }

            .date-column {
              width: 12%;
              font-size: 14px;
            }

            .warehouse-antwerp,
            .warehouse-houston,
            .warehouse-new-orleans,
            .warehouse-new-york,
            .warehouse-virginia,
            .warehouse-total,
            .warehouse-hamburg-bremen,
            .warehouse-compare-to-previous-day,
            .warehouse-date {
              min-width: 100px;
              padding: 3px 12px;
            }
          }

          .port-name-column {
            width: 10.5%;
            .flag-country-container {
              display: grid;
              grid-template-columns: 30px auto;
              align-items: center;
              justify-content: center;
              padding: 0px 5px;
              img {
                border: 1px solid rgba(0, 17, 51, 0.1490196078);
                height: 18px;
                width: 24px;
                border-radius: 3px;
                object-fit: cover;
              }

              .warehouse-name {
                font-size: 15px;
              }
            }
            .country-name {
              font-size: $small;
            }
          }

          .empty-row {
            height: 5px;
          }
        }

        tbody {
          border-collapse: collapse;

          tr {
            &:hover {
              background-color: rgba(0, 0, 0, 0.075);
            }

            td {
              border: 1px solid #add8e6;
              font-weight: $fw-bolder;
            }
          }

          .positive {
            color: $green;
          }

          .negative {
            color: $red;
          }

          .normal {
            color: $Black;
          }
        }
      }
    }
  }
}
