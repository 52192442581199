@import 'theme.scss';
.termial-difference-candle-chart-wrapper {
  position: relative;
  padding-bottom: 10px;
  height: 52vh;
  #terminalDifferenceCandleChart {
    position: relative;
    border: 1px solid $gray;
    border-radius: 5px;
    overflow: hidden;
    width: 100% !important;
    height: 52vh;
    table tr td:nth-child(2) canvas {
      cursor: crosshair;
    }
  }
  #volumeChart {
    border: 1px solid $gray;
    border-radius: 5px;
    overflow: hidden;
    width: 100% !important;
    margin-bottom: 30px;
  }
  .tooltips {
    box-shadow: 0px 0px 1px $gray;
    position: absolute !important;
    display: none;
    font-size: 12px;
    pointer-events: none;
    z-index: 9999 !important;
    overflow: hidden;
    color: $White;
    background-color: rgba(0, 0, 0, 0.518);
    &.dark-theme-tooltip {
      color: $White;
      background-color: rgba(0, 0, 0, 0.518);
      border: 1px solid $gray;
    }
    &.light-theme-tooltip {
      border: 1px solid $light-gray-color;
      color: $Black;
      background-color: rgb(255, 255, 255);
    }
    .tooltip-content {
      .date-format {
        font-size: 15px;
        text-align: center;
        background-color: $primary-color;
        font-weight: 600;
        font-size: 18px;
        padding: 4px;
        color: $White;
        border: 1px solid $primary-color;
      }
      .tooltip-info {
        .tooltip-item {
          display: flex;
          font-size: 18px;
          border-bottom: 1px solid $light-gray-color;
          padding: 0px 15px;
          .labeling {
            width: 120px;
            text-align: center;
          }
          #value-field {
            width: 70px;
            display: flex;
            justify-content: flex-end;
            font-weight: 600;
          }
          .high-value-green,
          .green-text {
            color: #4caf50;
          }
          .low-value-red,
          .red-text {
            color: #f44336;
          }
        }
        &.tooltip-filter-bg {
          filter: blur(4px);
        }
      }
    }
  }

  .candlestick-body-wrapper {
    position: absolute;
    top: 0px;
    z-index: 9998;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    width: fit-content;
    align-items: start;
    .candlestick-options {
      display: flex;
      align-items: center;
      gap: 5px;

      .date-selector {
        border: 1px solid $light-gray-color;
        padding: 3px;
        font-size: 14px;
        color: $gray;
        flex-direction: row;
        height: max-content;
        gap: 4px;
        border-radius: 3px;
        background-color: $White;
        .active-nav {
          background-color: $primary-color;
          color: $White;
          border-radius: 2px !important;
        }
        button {
          background-color: $White;
          color: $Black;
          min-width: 32px !important;
          &:hover {
            background-color: rgb(236, 236, 236);
            color: $Black;
            border-radius: 2px !important;
          }
        }
      }
      .grid-on-off-container {
        background-color: $White;
        border-radius: 3px;
        border: 1px solid $light-gray-color;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 30px;
        cursor: pointer;
        svg {
          height: 22px;
          color: $red;
          fill: $Black;
          opacity: 0.6;
        }
      }
      .darkmode-toggle-button,
      .fullscreen-toggle {
        border: 1px solid $light-gray-color;
        height: 30px;
        width: 30px;
        border-radius: 3px;
        background-color: $White;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .window-minimize-icon {
          width: 17px;
          height: 17px;
          opacity: 0.6;
        }
        .window-maximize-icon {
          width: 15px;
          height: 15px;
          opacity: 0.6;
        }
      }
      .graph-type-select-option {
        select {
          border-radius: 3px;
          height: 31px;
          cursor: pointer;
          option {
            cursor: pointer;
          }
        }
      }
    }

    .contract-name {
      display: none;
    }
    .week-high-low-wrapper {
      display: flex;
      display: none;
      .contract_high_low {
        width: 230px !important;
        border: 2px solid $primary-color;
        height: auto !important;
        position: relative !important;
        top: auto !important;
        left: auto !important;

        .contract_header {
          background-color: $primary-color;
          color: $White;
          text-align: center;
          margin-bottom: 8px;
          font-weight: $font-semibold;
        }
        .contract_header_tr {
          height: 3px;
        }

        .contract_body {
          text-align: center;
          border: 1px solid $primary-color;
          background-color: $White;
          font-size: 16px;
          font-weight: $font-semibold;
          width: 400px;
          .weekHigh {
            color: $green;
          }
          .weekLow {
            color: $red;
          }
          .weekHighValue {
            color: $green;
          }
          .weekLowValue {
            color: $red;
          }

          td {
            border: 2px solid $primary-color !important;
            width: 50%;
          }
        }
      }
    }
  }
}

.termial-difference-candle-chart-wrapper.fullscreen {
  height: 100vh !important;
  width: 100vh !important;
  position: fixed;
  top: 0px;
  left: 0px;
  #terminalDifferenceCandleChart,
  table {
    position: fixed !important;
    top: 0px;
    left: 0px;
    width: 100vw !important;
    height: 100vh !important;
  }
  .candlestick-body-wrapper {
    width: 100vw !important;
    padding: 5px 5px 5px 5px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .contract-name {
      display: block;
      text-align: center;
      font-size: 20px;
      width: 100%;
      font-weight: 600;
      padding-top: 5px;
      &.dark-theme {
        color: $White;
      }
      &.light-theme {
        color: $Black;
      }
    }
    .week-high-low-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-right: 55px;
      gap: 6px;
    }
  }
}
.create-account-message {
  position: absolute;
  top: 95px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  .message {
    background-color: $White;
    width: 100%;
    margin: auto;
    border: 1px solid $primary-color;
    padding: 5px;
    text-align: center;
    border-radius: 3px;
    font-size: 15px;
    color: $primary-color;
    font-weight: $font-semibold;
  }
}
