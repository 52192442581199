@import 'theme.scss';

.reset-password-modal {
  justify-content: center;

  .modal-body-style {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 22px;
      margin-bottom: 7px;
    }
    .inputWrapper {
      position: relative;
      margin: 5px 0px;

      .reset-password-input {
        width: 85%;
        display: block;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: $White;
        background-clip: padding-box;
        border: 1px solid $primary-color;
        border-radius: 0.25rem;
        transition:
          border-color 0.15s ease-in-out,
          box-shadow 0.15s ease-in-out;

        &:focus {
          color: unset !important;
          background-color: $White;
          border-color: $primary-color !important;
          outline: 0;
          box-shadow: unset !important;
        }
      }
      span {
        position: absolute;
        top: 6px;
        right: 80px;
        cursor: pointer;
      }
    }
    .desc {
      color: $gray;
    }
    .error-message {
      color: $red;
      font-size: $small;
    }
    .success-message {
      color: $green;
    }
    .after-success {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 30px 0px;
      .success-icon {
        width: 50px;
        height: 50px;
        color: $green;
      }
      .password-reset-success-message {
        font-size: 20px;
      }
      .try-out {
        color: $blue;
        text-decoration: underline;
        cursor: pointer;
        width: fit-content;
        text-align: center;
      }
    }
  }
  .modal-footer {
    padding: $padding-modal;
  }
}

@media (max-width: $device-small) {
  .reset-password-modal {
    .modal-body-style {
      .inputWrapper {
        .reset-password-input {
          width: 100%;
        }
        span {
          right: 20px;
        }
      }
    }
  }
}
