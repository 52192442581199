@import 'theme.scss';
.image_preview_component {
  background-color: rgba(43, 43, 43, 0.781);
  height: 100vh;
  width: 100vw;
  z-index: 1101;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;

  .media_wrapper {
    display: grid;
    grid-template-columns: 10vw 80vw 10vw;
    align-items: center;
  }
  div {
    display: flex;
    justify-content: center;
  }
  i {
    color: $default-white;
    font-size: 1.5rem;
    background-color: rgba(180, 180, 180, 0.329);
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    &:hover {
      background-color: rgba(198, 198, 198, 0.49);
    }
  }
  .media-section {
    width: 100%;
    img,
    video {
      width: 50%;
      margin: auto;
    }
  }
}

@media (max-width: 767.98px) {
  .image_preview_component {
    .media-section {
      img,
      video {
        width: 100%;
      }
    }
  }
}
