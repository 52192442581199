@import 'theme.scss';
.coffee-web-app-links {
  display: flex;
  gap: 10px;
  a {
    background-color: black;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    width: 100%;
    align-items: center;
    padding: 6px;
    gap: 6px;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    .icon {
      height: 44px;
      width: 44px;
    }
    .small-text {
      font-size: 12px;
      color: $White;
    }
    .big-text {
      font-size: 17px;
      color: $White;
    }
  }
}

@media (max-width: 767.98px) {
  .coffee-web-app-links {
    a {
      .icon {
        height: 40px;
        width: 40px;
      }
      .small-text {
        font-size: 10px;
      }
      .big-text {
        font-size: 16px;
      }
    }
  }
}
