@import 'theme.scss';

.global-raw-coffee-price-accordian-popup {
  .p-toast-icon-close {
    background-color: transparent;
    color: #ff5757;
  }
}

.global-coffee-price-popup-modal {
  user-select: none;
  .cropyear {
    background-color: $primary-color;
    color: $White;
    font-size: 17px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    padding: 4px 15px;
    border-bottom: 2px solid $White;
    .cropyear_flag {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  .global-raw-coffee-price-preview-table-wrapper {
    max-height: 57vh;
    overflow: auto;
    border-bottom: 1px solid lightgray;
    margin-top: 5px;
    .flag-icon {
      border: 1px solid rgba(0, 17, 51, 0.149);
      border-radius: 3px;
      height: 24px;
      object-fit: cover;
      width: 35px;
    }
    .global-raw-coffee-price-preview-table {
      width: -webkit-fill-available;
      width: -moz-available;
      color: black;
      .global-raw-coffee-price-preview-table-header {
        position: sticky;
        top: 0px;

        .header-previous-data {
          background-color: $White;
          color: $primary-color;
          font-size: 25px;
        }

        tr {
          th {
            outline: 1px solid $White;
            background-color: $primary-color;
            text-align: center;
            padding: 4px 10px;
            color: $White;
          }
        }
      }
      .global-raw-coffee-price-preview-table-body {
        .date-td {
          font-weight: $font-semibold;
        }
        .value-high {
          color: $green;
          font-weight: $font-semibold;
        }

        .value-low {
          color: $red;
          font-weight: $font-semibold;
        }

        .terminal-data-value {
          color: $Black;
          font-weight: $font-semibold;
        }

        tr {
          td {
            border: 1px solid lightblue;
            text-align: center;
            padding: 4px 10px;
            min-width: 100px;
          }
          .no-data-found-td {
            padding: 30px;
          }
          &:hover {
            background-color: rgba(0, 0, 0, 0.075);
          }
        }

        tr:nth-child(1) {
          background-color: rgb(233, 241, 249);
        }
      }
    }
  }
}
