@import 'theme.scss';

.clock-settings-dialog {
  width: 55rem;

  .p-dialog-header {
    margin: 0px;
    padding: $padding-modal;
  }
  .p-divider {
    margin: 5px 0px 10px 0px;
  }
  .p-link:focus-visible {
    box-shadow: none;
  }

  .p-dialog-content {
    padding: 0 0.1rem 2rem 1.5rem;
  }
  .dialog-header-container {
    display: flex;
    justify-content: space-between;
    text-align: left;

    .clock-heading {
      color: $primary-color;
      font-size: $xx-large;
      font-weight: $font-bold;
    }
    .p-dialog-header-icon:focus-visible {
      box-shadow: none;
    }
    .close-icon {
      font-size: $xx-large;
      color: $gray;
      margin-top: 5px;
      cursor: pointer;
    }
  }

  .clock-type-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .clock-type-title {
      font-size: $medium;
      font-weight: $fw-bolder;
      color: $gray;
    }
    .select-button-wrapper {
      .p-button:focus {
        box-shadow: none;
        padding: 0.375rem 0.75rem;
      }
      .p-selectbutton .p-button.p-highlight {
        background: $primary-color;
        border-color: $primary-color;
        padding: 0.375rem 0.75rem;
        color: $White;
      }
      .p-selectbutton .p-button {
        padding: 0.375rem 0.75rem;
        border-color: $primary-color;
        color: $primary-color;
      }
    }

    .time-format-toggle {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      .time-format-label {
        font-size: $medium;
        font-weight: $fw-bolder;
        color: #374151;
      }
      .time-format-select-button {
        .p-button:focus {
          box-shadow: none;
          padding: 0.375rem 0.75rem;
        }
        .p-selectbutton .p-button.p-highlight {
          background: $primary-color;
          border-color: $primary-color;
          padding: 0.375rem 0.75rem;
          color: $White;
        }
        .p-selectbutton .p-button {
          padding: 0.375rem 0.75rem;
          border-color: $primary-color;
          color: $primary-color;
        }
      }
    }
  }
  .my-favorite-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 16px;
    .country-pick-list-title {
      font-size: $medium;
      font-weight: $fw-bolder;
      color: $gray;
    }
    .error-message {
      color: $red;
      font-size: $small;
    }
    .country-pick-list-container {
      .p-button:focus {
        box-shadow: none;
      }
      .p-picklist-list-wrapper {
        min-width: 300px;
      }

      .country-picker-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .country-picker-content {
          display: flex;
          align-items: center;

          .country-picker-flag-container {
            margin-right: 15px;

            .country-picker-flag {
              width: 32px;
            }
          }
        }
        .drag-and-drop-container {
          position: absolute;
          top: 0;
          right: 0;
          .drag-and-drop-icon {
            width: 18px;
            height: 18px;
            color: $gray;
            margin: 3px;
          }
        }
      }
      .country-picking-button {
        background-color: $primary-color;
        border: 1px solid $primary-color;
        &:hover {
          background-color: $primary-color;
          border: 1px solid $primary-color;
        }
        &:focus {
          box-shadow: none;
        }
      }
      .p-picklist-buttons {
        gap: 12px;
      }
      .p-picklist .p-picklist-list {
        height: 16rem;
      }
      .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
        color: $primary-color;
        background-color: #c8e9fa;
      }
      .p-inputtext:enabled:hover {
        border-color: $primary-color;
      }
      .p-picklist-header {
        font-weight: $font-extra-light;
        padding: 0.6rem;
        color: $gray;
      }
    }
  }
  .dialog-button-container {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  .p-dialog-header-icon.p-dialog-header-close.p-link {
    background-color: $White;
    color: $gray;
    width: $x-large;
  }
  .p-dialog-footer {
    padding: $padding-modal;
  }

  .p-dialog .p-dialog-content {
    padding: 0 1.5rem 0rem 1.5rem;
  }
  .divider-style {
    margin-bottom: 18px;
  }
}

@media (max-width: 1280px) {
  .clock-settings-dialog {
    .p-dialog-content {
      padding: 0 1.5rem 2rem;
    }
    .my-favorite-wrapper {
      .country-pick-list-container {
        .p-picklist {
          flex-direction: column !important;
        }
        .p-picklist .p-picklist-list {
          height: 17rem;
        }
        .p-picklist-transfer-buttons {
          flex-direction: row;
          gap: 0px;
        }
        .p-picklist-buttons .p-button {
          margin-right: 0.5rem !important;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .clock-settings-dialog {
    width: 90%;

    .dialog-header-container {
      .clock-heading {
        font-size: $x-large;
        font-weight: $font-semibold;
        margin-bottom: 0.2px;
      }
      .close-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -16px;
        right: -10px;
        background: $White;
        border-radius: 50%;
        height: 30px;
        width: 30px;
      }
      .close-icon {
        margin-top: 0px !important;
        font-size: $xxx-large !important;
      }
    }
    .divider-header {
      display: none;
    }
    .p-dialog-content {
      padding: 0 1.5rem 2rem;
    }

    .my-favorite-wrapper {
      .country-pick-list-container {
        .p-picklist {
          flex-direction: column !important;
        }
        .p-picklist .p-picklist-list {
          height: 17rem;
        }
        .p-picklist-transfer-buttons {
          flex-direction: row;
          gap: 0px;
        }
        .p-picklist-buttons .p-button {
          margin-right: 0.5rem !important;
        }
      }
    }
    .clock-type-wrapper {
      margin-top: 6px;
    }
  }
}

@media (max-width: 280px) {
  .clock-settings-dialog {
    .p-dialog-content {
      padding: 0 1.5rem 2rem;
    }
    .my-favorite-wrapper {
      .country-pick-list-container {
        .p-picklist {
          flex-direction: column !important;
        }
        .p-picklist .p-picklist-list {
          height: 17rem;
        }
        .p-picklist-transfer-buttons {
          flex-direction: row;
          gap: 0px;
        }
        .p-picklist-buttons .p-button {
          margin-right: 0.5rem !important;
        }
        .p-inputtext {
          font-size: $x-small;
        }
      }
    }
  }
}
